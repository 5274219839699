/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ReportsProfileRequest = {
    properties: {
        productId: {
            properties: {
            },
            isRequired: true,
        },
        source: {
            type: 'string',
            isRequired: true,
        },
        reportId: {
            type: 'number',
            isRequired: true,
        },
    },
};