import {
	RatePlanDefaultIndexResponse,
	RatePlanDefaultProfileResponse,
	RatePlanDefaultCreateRequest,
	RatePlanDefaultUpdateRequest,
	RatePlanDefaultProfileUrlRequest
} from 'RtModels';
import { RtCommonApiRoutes } from 'RtExports/routes';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class RatePlanDefaultResource extends HttpResource<
	RatePlanDefaultIndexResponse,
	RatePlanDefaultProfileResponse
> {
	constructor() {
		super(RtCommonApiRoutes.RatePlanDefaults);
	}

	public create(
		ratePlanId: number,
		ratePlanDefaultCreateRequest: RatePlanDefaultCreateRequest
	) {
		const body = JSON.stringify(ratePlanDefaultCreateRequest);

		return this.fetchWithRoute<RatePlanDefaultProfileResponse>(
			RtCommonApiRoutes.RatePlanDefaults.Create,
			{
				body,
				urlParams: { ratePlanId }
			}
		);
	}

	public update(
		ratePlanId: number,
		ratePlanDefaultId: number,
		ratePlanDefaultUpdateRequest: RatePlanDefaultUpdateRequest
	) {
		const urlParams: RatePlanDefaultProfileUrlRequest = {
			ratePlanDefaultId,
			ratePlanId
		};

		const body = JSON.stringify(ratePlanDefaultUpdateRequest);

		return this.fetchWithRoute<RatePlanDefaultProfileResponse>(
			RtCommonApiRoutes.RatePlanDefaults.Update,
			{
				body,
				urlParams: urlParams
			}
		);
	}
}
