import { FileProcessorType } from 'RtModels';
import { ConvertRateSheetRouter } from 'RtUi/app/rtVue/ConvertRateSheet/ConvertRateSheet.router';
import { ConvertRateSheetUpload } from 'RtUi/app/rtVue/ConvertRateSheet/lib/forms/ConvertRateSheetUpload';
import { LrnLookupGrid } from 'RtUi/app/rtVue/LrnLookup/lib/grids/LrnLookupGrid';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';

export const ConvertRateSheetIndexContainer = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Results: { header: 'Results', isDefault: true },
		Upload: { header: 'Upload', isAction: true }
	});

	return (
		<TabbedLayout
			router={ConvertRateSheetRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab header={tabs.Results.header}>
				<LrnLookupGrid
					pageName="rateSheet-convertRateSheet"
					router={ConvertRateSheetRouter}
					resourceParams={{
						fileProcessTypeId: FileProcessorType.LataOcnConversion
					}}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Upload}>
				<ConvertRateSheetUpload
					onCancel={() => {
						setActiveTab(tabs.Results.header);
					}}
					onSuccess={() => {
						setActiveTab(tabs.Results.header);
					}}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

ConvertRateSheetIndexContainer.displayName = 'ConvertRateSheetIndexContainer';

ConvertRateSheetRouter.setIndexRtUiFunctionalComponent(
	ConvertRateSheetIndexContainer,
	{
		groupName: 'Helpful Tools',
		groupId: 'helpfulTools'
	}
);
