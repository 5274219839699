/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $UserFavoriteCreateRequest = {
    properties: {
        userFavoriteTypeId: {
            type: 'RtxUserFavoriteType',
            isRequired: true,
        },
        favoriteId: {
            type: 'string',
            isRequired: true,
        },
        isFavorite: {
            type: 'number',
        },
        isDashboard: {
            type: 'number',
        },
    },
};