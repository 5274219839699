import {
	Autocomplete,
	Box,
	InputAdornment,
	Link,
	Tooltip
} from '@mui/material';
import { Clear, Help } from '@mui/icons-material';
import { has, noop, uniq } from 'lodash-es';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RtxFormContext } from 'RtUi/components/rtx/form/context/FormContext';
import { RtxTextInput } from 'RtUi/components/rtx/inputs/Text/RtxTextInput';
import { RtxChip } from 'RtUi/components/rtx/ui/Chip/RtxChip';

export enum NumberTypes {
	NANP,
	IDDD
}

type InputValue<IsMulti extends boolean = false> = IsMulti extends true
	? string[]
	: string;

interface IRtxEmailInputProps<IsMulti extends boolean = false> {
	value: InputValue<IsMulti> | undefined;
	isMulti?: IsMulti;
	onChange: (value: InputValue<IsMulti>) => void;
	shownLimit?: number;
	className?: string;
	displayMode?: boolean;
	required?: boolean;
	disabled?: boolean;
	label?: string;
}

export const processValue = (value: string) => {
	if (!value) {
		return '';
	}

	return value;
};

export const isValidEmail = (value: string) => {
	const emailRegex =
		/^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

	if (!value) {
		return false;
	}

	if (value.length > 254) {
		return false;
	}

	const valid = emailRegex.test(value);
	if (!valid) {
		return false;
	}

	// Further checking of some things regex can't handle
	const parts = value.split('@');
	if (parts[0].length > 64) {
		return false;
	}

	const domainParts = parts[1].split('.');
	if (
		domainParts.some(function (part) {
			return part.length > 63;
		})
	) {
		return false;
	}

	return true;
};

export const RtxEmailInput = <IsMulti extends boolean = false>({
	value,
	onChange = noop,
	isMulti,
	shownLimit = 20,
	displayMode = false,
	className,
	required,
	disabled,
	label = 'Email Address'
}: IRtxEmailInputProps<IsMulti>) => {
	const [showAll, setShowAll] = useState<boolean>(false);
	const { setError, clearFormErrors, errors } = useContext(RtxFormContext);
	const [customValidityError, setCustomValidityError] = useState<string>();
	const [inputListValue, setInputListValue] = useState<string[]>([]);
	const [inputValue, setInputValue] = useState<string>('');
	const isMultiValue = Boolean(isMulti);

	const labelComponent = useMemo(() => {
		if (displayMode) {
			return label;
		}

		return (
			<Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
				<span>{label}</span>
				<Tooltip title="Enter valid email address(es) and press enter">
					<Help />
				</Tooltip>
			</Box>
		);
	}, [label, displayMode]);

	const validateInputValue = useCallback(
		(value: InputValue<IsMulti>) => {
			if (displayMode) {
				return;
			}

			let newCustomValidityError = '';

			if (has(errors, 'root.emailInput')) {
				clearFormErrors('emailInput');
			}

			const isInvalid = isMulti
				? (value as string[]).filter((v) => !isValidEmail(v)).length
				: !isValidEmail(value as string);

			if (value && isInvalid) {
				newCustomValidityError = 'Invalid email value';
				setError('emailInput', newCustomValidityError);
			}

			setCustomValidityError(newCustomValidityError);
		},
		[errors, isMulti, clearFormErrors, setError, displayMode]
	);

	useEffect(() => {
		if (isMultiValue) {
			const initialValue = (value ?? []) as string[];
			setInputListValue(
				initialValue.filter((n) => n).map((n) => processValue(n))
			);
			validateInputValue(initialValue.filter((n) => n) as InputValue<IsMulti>);
			return;
		}

		const initialValue = (value ?? '') as string;
		setInputValue(processValue(initialValue));
		validateInputValue(initialValue as InputValue<IsMulti>);
	}, [isMultiValue, validateInputValue, value]);

	const onChangeMultiValue = (value: string[]) => {
		const newValue = uniq(value);
		validateInputValue(newValue as InputValue<IsMulti>);
		setInputListValue(newValue);
		onChange(newValue.map((v) => v.replace('', '')) as InputValue<IsMulti>);
	};

	const onPaste = (evt: React.ClipboardEvent<HTMLInputElement>) => {
		const text = evt.clipboardData.getData('text');
		const numbers = text.split(/[\s\n\r,]+/);
		onChangeMultiValue([
			...inputListValue,
			...numbers.map((n) => processValue(n))
		]);
		evt.preventDefault();
	};

	if (isMultiValue) {
		return (
			<Autocomplete
				className="flex-grow-1"
				options={[]}
				disabled={disabled || displayMode}
				sx={{
					'.MuiInputBase-root': {
						'padding': '10px 14px 5px',
						'.MuiInputBase-input': {
							minSize: 10,
							marginTop: '0',
							padding: '0 !important'
						}
					}
				}}
				freeSolo
				value={inputListValue}
				multiple
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					// const newValue = newInputValue.replace(/\D/g, '');
					setInputValue(processValue(newInputValue));
				}}
				renderTags={(value, props) => {
					const useLimit = shownLimit ?? 0;
					const startDisplayIndex = showAll ? 0 : value.length - useLimit;

					return (
						<>
							{useLimit && value.length > useLimit && (
								<>
									{startDisplayIndex > 0 && (
										<Link
											component="button"
											variant="body2"
											className="Cpr-Value-Display-Link"
											onClick={() => {
												setShowAll(true);
											}}
										>
											<strong>
												({shownLimit} of {value.length}) Show All&nbsp;
											</strong>
										</Link>
									)}
									{startDisplayIndex === 0 && (
										<Link
											component="button"
											className="Cpr-Value-Display-Link"
											variant="body2"
											onClick={() => {
												setShowAll(false);
											}}
										>
											<strong>Show Less&nbsp;</strong>
										</Link>
									)}
								</>
							)}
							{value.map((option, index) =>
								index < startDisplayIndex ? undefined : (
									<RtxChip
										label={option}
										{...props({ index })}
										key={index}
										deleteIcon={<Clear />}
										className={!isValidEmail(option) ? 'invalid' : ''}
									/>
								)
							)}
						</>
					);
				}}
				onChange={(event: any, newValue: string[]) => {
					onChangeMultiValue(newValue);
				}}
				renderInput={(params) => (
					<RtxTextInput
						{...params}
						label={labelComponent}
						className={className}
						displayMode={displayMode}
						disabled={disabled}
						error={displayMode ? undefined : Boolean(customValidityError)}
						helperText={displayMode ? undefined : customValidityError}
						onPaste={onPaste}
					/>
				)}
			/>
		);
	}

	return (
		<RtxTextInput
			label={labelComponent}
			value={displayMode ?? ''}
			className={className}
			required={required}
			onChange={(value) => {
				validateInputValue(value as InputValue<IsMulti>);
				setInputValue(processValue(value));
				onChange(value as InputValue<IsMulti>);
			}}
			InputProps={{
				startAdornment: displayMode && isMulti && inputValue && (
					<InputAdornment position="start">
						<RtxChip label={inputValue} sx={{ color: '#00000061' }} />
					</InputAdornment>
				)
			}}
			displayMode={displayMode}
			disabled={disabled}
			error={displayMode ? undefined : Boolean(customValidityError)}
			helperText={displayMode ? undefined : customValidityError}
		/>
	);
};

RtxEmailInput.displayName = 'RtxEmailInput';
