import {
	IRouterBreadcrumb,
	RtUiRouter
} from 'RtUi/components/containers/lib/RtUiRouter';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';

export abstract class AccountManagementRouter<
	IndexType,
	ProfileType,
	TabType extends IProfileApplicationContainerTabs
> extends RtUiRouter<IndexType, ProfileType, TabType> {
	public static readonly SectionKey = 'accountManagement';

	constructor(
		name: string,
		containerKey: string,
		propertyKey: IndexType extends void ? undefined : keyof IndexType,
		profileTabs: TabType
	) {
		super(
			AccountManagementRouter.SectionKey,
			name,
			containerKey,
			propertyKey,
			profileTabs
		);
	}

	public async getBreadcrumbs(
		currentPath: string,
		profile?: ProfileType
	): Promise<IRouterBreadcrumb[]> {
		const breadcrumbs = await super.getBreadcrumbs(currentPath, profile);

		return [this.getSectionBreadcrumb(), ...breadcrumbs];
	}

	protected getSectionBreadcrumb() {
		const sectionBreadcrumb: IRouterBreadcrumb = {
			name: 'Account Management',
			path: '',
			isActive: true
		};

		return sectionBreadcrumb;
	}
}
