import {
	ConnectionIndexResponse,
	GuardianConnectionSubscriptionIndexResponse
} from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DirectionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DirectionDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { UserNameDataGridColumn } from 'RtUi/components/data/DataGrid/columns/UserNameDataGridColumn';

export const getConnectionsColumns = (): Array<
	DataGridColumn<ConnectionIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'connectionId'
	}),
	DefaultDataGridColumn({
		accessorKey: 'switchName',
		header: 'Switch Name'
	}),
	DefaultDataGridColumn({
		header: 'File Stream',
		accessorKey: 'fileStreamName'
	}),
	IsActiveDataGridColumn({
		header: 'FS Active',
		accessorKey: 'fileStreamIsActive'
	}),
	DefaultDataGridColumn({
		accessorKey: 'trunkGroupId',
		header: 'Trunk Group Id'
	}),
	DefaultDataGridColumn({
		accessorKey: 'trunkGroupLabel',
		header: 'Trunk Group Label'
	}),
	DefaultDataGridColumn({
		accessorKey: 'gatewayAddress',
		header: 'Gateway Address'
	}),
	DefaultDataGridColumn({
		accessorKey: 'peerAddress',
		header: 'Peer Address'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	DefaultDataGridColumn({
		accessorKey: 'externalId',
		header: 'External Id'
	}),
	DefaultDataGridColumn({
		accessorKey: 'summary',
		header: 'Summary',
		isHidden: true
	}),
	TimeStampDataGridColumn({
		accessorKey: 'createdTs',
		header: 'Created',
		isHidden: true
	}),
	TimeStampDataGridColumn({
		accessorKey: 'updatedTs',
		header: 'Updated',
		isHidden: true
	}),
	UserNameDataGridColumn({
		accessorKey: 'updatedBy',
		header: 'Updated By',
		isHidden: true
	})
];

export const getConnectionSubscriptionsColumns = (): Array<
	DataGridColumn<GuardianConnectionSubscriptionIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'guardianConnectionId',
		header: 'Connection ID'
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'subscriptionId',
		header: 'Subscription'
	}),
	DefaultDataGridColumn({
		accessorKey: 'callType',
		header: 'Call Type'
	}),
	DirectionDataGridColumn({
		accessorKey: 'directionId',
		header: 'Direction'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'startTs',
		header: 'Start'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'endTs',
		header: 'End'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isActive',
		header: 'Active'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'createdTs',
		header: 'Created'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'updatedTs',
		header: 'Updated'
	}),
	UserNameDataGridColumn({
		accessorKey: 'updatedBy',
		header: 'Updated By'
	})
];
