import * as React from 'react';
import { PortsIndexResponseData, Reports } from 'RtModels';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import { RtVueReportContainer } from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import { PortTotalsLineChart } from 'RtUi/app/rtVue/PortsView/lib/charts/PortTotalLineChart';
import { PortDataGrid } from 'RtUi/app/rtVue/PortsView/lib/grids/PortDataGrid';
import { PortViewRouter } from 'RtUi/app/rtVue/PortsView/PortView.router';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

@PortViewRouter.getIndexRtUiController({
	groupName: 'Network Reports',
	groupId: 'VueNetworkReports',
	groupOrderPriority: 1
})
export class PortViewIndexContainer extends RtVueReportContainer<PortsIndexResponseData> {
	public state = this.getBaseState();

	protected reportId = Reports.Ports;
	protected router = PortViewRouter;

	protected async getResource(): Promise<HttpResource<PortsIndexResponseData>> {
		const {
			timeRange,
			filters,
			partition,
			preset,
			dataSums,
			additionalColumns
		} = this.state;
		const vueHttp = new RtVueHttp();
		const {
			data: { data },
			totalCount
		} = await vueHttp.getPortsView(
			timeRange,
			filters,
			dataSums,
			preset,
			partition,
			additionalColumns?.map((column) => column.value)
		);
		const portResource = new ArrayResource<PortsIndexResponseData>(
			'callDate',
			data
		);

		portResource.setTotalCount(totalCount);

		return portResource;
	}

	protected renderReports(): React.ReactNode {
		return (
			<>
				<PortTotalsLineChart resource={this.state.resource} />
				<PortDataGrid
					isLoading={this.state.loadingReport}
					defaultSorting={this.state.defaultSorting}
					onSortingChange={this.onSortedChange}
					resource={this.state.resource}
				/>
			</>
		);
	}
}
