/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $UserFavoriteIndexResponse = {
    properties: {
        userFavoriteId: {
            type: 'number',
            isRequired: true,
        },
        userFavoriteTypeId: {
            type: 'RtxUserFavoriteType',
            isRequired: true,
        },
        favoriteId: {
            type: 'string',
            isRequired: true,
        },
    },
};