import * as React from 'react';
import { Form } from 'react-bootstrap';
import { ControlGroup, IControlGroupTooltip } from '../../ui/ControlGroup';
import {
	FormControl,
	IFormControlProps,
	IFormControlState
} from '../FormControl';

export interface IInputFormControlProps extends IFormControlProps<string> {
	type?: 'text' | 'number' | 'tel' | 'file' | 'email' | 'password' | 'time';
	className?: string;
	disableAutoFormat?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	label: string;
	pattern?: string;
	style?: React.CSSProperties;
	customValidityError?: string;
	formText?: string;
	formTextDanger?: string;
	onFilesChange?: (files: FileList | null, fileSizeInKb: number | null) => void;
	initialValue?: string;
	onFocus?: () => void;
	onBlur?: () => void;
	placeholder?: string;
	autoFocus?: boolean;
	useControlGroup?: boolean;
	maxLength?: number;
	minLength?: number;
	bsSize?: 'lg' | 'sm';
	removeNonNumericCharacters?: boolean;
	max?: number;
	min?: number;
	step?: number;
	accept?: string;
	toUpperCase?: boolean;
	autoHeight?: boolean;
	linkTo?: string;
	controlGroupClassName?: string;
	tooltip?: IControlGroupTooltip;
	displayEmptyValue?: React.ReactNode;
}

interface IInputFormControlState extends IFormControlState {}

export class InputFormControl extends FormControl<
	string,
	false,
	IInputFormControlProps,
	IInputFormControlState
> {
	public resource = undefined;
	public state: IInputFormControlState = {
		formLabel: ''
	};

	public inputElementRef = React.createRef<HTMLInputElement>();

	public componentDidMount() {
		super.componentDidMount();
		this.updateCustomValidityError();

		const {
			value,
			initialValue: defaultValue,
			onChange,
			autoFocus = false
		} = this.props;

		if (!value && defaultValue && onChange) {
			onChange(defaultValue);
		}

		if (autoFocus) {
			this.focus();
		}
	}

	public componentDidUpdate() {
		this.updateCustomValidityError();
	}

	public focus() {
		this.inputElementRef.current?.focus();
	}

	public updateCustomValidityError() {
		const { customValidityError = '' } = this.props;

		this.inputElementRef.current?.setCustomValidity(customValidityError);
	}

	public removeNonNumericCharacters(str: string) {
		return str.replace(/[^0-9]/g, '');
	}

	public render() {
		const {
			type = 'text',
			onChange = () => ({}),
			onFilesChange = () => ({}),
			required,
			className,
			name,
			displayMode,
			disabled = false,
			disableAutoFormat = false,
			readOnly,
			tooltip
		} = this.props;
		const { formLabel } = this.state;
		let { value = '' } = this.props;

		// Hide password in displayMode
		if (type === 'password' && displayMode && value) {
			value = '********';
		}

		const onComponentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			const { files } = e.target;
			let { value } = e.target;
			let fileSizeInKb: number | null = null;

			if (files && files.length > 0) {
				const firstFile = Array.from(files)[0];
				const fileSizeInBytes = firstFile.size;
				fileSizeInKb = fileSizeInBytes / 1000;
			}

			if (this.props.removeNonNumericCharacters) {
				value = this.removeNonNumericCharacters(value);
			}

			if (this.props.toUpperCase) {
				value = value.toUpperCase();
			}

			onFilesChange(files, fileSizeInKb);
			if (this.props.accept) {
				if (new RegExp(this.props.accept).test(value)) {
					return onChange(value);
				}
			} else {
				onChange(value);
			}
		};

		const inputElem = (
			<>
				<Form.Control
					type={type}
					style={this.props.style}
					ref={this.inputElementRef}
					disabled={disabled}
					className={className}
					value={value}
					size={this.props.bsSize}
					readOnly={readOnly}
					name={name}
					accept={this.props.accept}
					maxLength={this.props.maxLength}
					minLength={this.props.minLength}
					max={this.props.max}
					min={this.props.min}
					step={this.props.step}
					onFocus={this.props.onFocus}
					onBlur={this.props.onBlur}
					pattern={this.props.pattern}
					placeholder={this.props.placeholder}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						onComponentChange(e)
					}
					required={required}
				/>
				{this.props.formText && (
					<small className="form-text text-muted">{this.props.formText}</small>
				)}
				{this.props.formTextDanger && (
					<small className="form-text text-danger">
						{this.props.formTextDanger}
					</small>
				)}
			</>
		);

		const errorText = this.getCurrentFormErrors()?.join(', ');

		if (!disableAutoFormat) {
			if (type === 'number' && value) {
				const valNum = Number(value);

				if (!isNaN(valNum)) {
					value = valNum.toLocaleString();
				}
			}
		}

		return (
			<ControlGroup
				className={this.props.controlGroupClassName}
				label={formLabel}
				errorText={errorText}
				value={value}
				linkTo={this.props.linkTo}
				autoHeight={this.props.autoHeight}
				hideLabel={this.props.hideLabel || this.props.useControlGroup === false}
				hideFormGroup={this.props.useControlGroup === false}
				displayMode={displayMode}
				sublabel={this.props.subLabel}
				required={this.props.required}
				displayEmptyValue={this.props.displayEmptyValue}
				tooltip={tooltip}
			>
				{inputElem}
			</ControlGroup>
		);
	}
}
