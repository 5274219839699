/* eslint-disable @typescript-eslint/type-annotation-spacing */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
	DataFilter,
	DataFilterOperator,
	DataSources,
	GuardianUserQueryIndexResponse,
	MonthlyTrendingIndexResponse,
	Reports,
	TimeFilterDetailKeys,
	TimeRangePresets
} from 'RtModels';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';

import { MonthlyTrendingRouter } from 'RtUi/app/rtVue/MonthlyTrending/MonthlyTrending.router';
import { MonthlyTrendingReportsComponent } from 'RtUi/app/rtVue/MonthlyTrending/components/Reports';
import {
	fetchMonthlyTrendReports,
	useGetAllMonthlyTrendReports,
	useGetMonthlyTrendReports
} from 'RtUi/app/rtVue/MonthlyTrending/service';
import { VueSavedQuerySelect } from 'RtUi/app/rtVue/common/lib/components/VueSavedQuerySelect';
import { VueSelectAdditionalColumns } from 'RtUi/app/rtVue/common/lib/components/VueSelectAdditionalColumns';
import {
	getMonthlyTrendColumnsAloc,
	getMonthlyTrendColumnsAsr,
	getMonthlyTrendColumnsCost,
	getMonthlyTrendColumnsCpm,
	getMonthlyTrendColumnsDno,
	getMonthlyTrendColumnsMou
} from 'RtUi/components/data/DataGrid/configurations/rtVue/monthlyTrend';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import { isEmpty } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useMutation } from 'react-query';

import {
	generateFirstRow,
	generateSecondRow
} from 'RtUi/app/rtVue/MonthlyTrending/components/ExportColumns';
import { monthNameMap } from 'RtUi/app/rtVue/MonthlyTrending/utils';
import writeXlsxFile from 'write-excel-file';
import { endOfDay, startOfDay } from 'date-fns';

export const MonthlyTrendingIndexContainer = () => {
	const [cdrType, setCdrType] = useState('customer');
	const [reportingType, setReportingType] = useState(0);
	const [additionalColumns, setAdditionalColumns]: any = useState([]);
	const [reportData, setReportData] = useState<MonthlyTrendingIndexResponse[]>(
		[]
	);
	const [userQuery, setUserQuery] = useState<any>(null);
	const [isLoadingExcelExport, setIsLoadingExcelExport] =
		useState<boolean>(false);

	const [isLoadingMegaExcelExport, setIsLoadingMegaExcelExport] =
		useState<boolean>(false);

	const { refetch: fetchAllReportData } = useGetAllMonthlyTrendReports(
		cdrType,
		additionalColumns
	);

	const [tabs, activeTab, setActiveTab] = useTabs({
		mou: { header: 'MOU', isDefault: true },
		cost: { header: 'Cost' },
		cpm: { header: 'CPM' },
		revenue: { header: 'Revenue' },
		margin: { header: 'Margin' },
		rpm: { header: 'RPM' },
		mpm: { header: 'MPM' }
	});

	const [offnetTabs, activeOffnetTabs] = useTabs({
		mou: { header: 'MOU', isDefault: true },
		cost: { header: 'Cost' },
		cpm: { header: 'CPM' }
	});

	const [networkTabs, activeNetworkTabs, setNetworkActiveTabs] = useTabs({
		asr: { header: 'ASR', isDefault: true },
		aloc: { header: 'Aloc' },
		pdd: { header: 'PDD' },
		roboscore: { header: 'Roboscore' },
		countDno: { header: 'Count Dno' },
		uniqueAnis: { header: 'Unique Anis' }
	});

	const [filters, setFilters] = useState([
		{
			key: 'dataSource',
			operator: DataFilterOperator.In,
			operands: [{ dataSource: DataSources.Text, value: cdrType }]
		},
		{
			key: 'reportType',
			operator: DataFilterOperator.In,
			operands: [
				{
					dataSource: DataSources.Text,
					value:
						activeTab?.header ||
						activeOffnetTabs?.header ||
						activeNetworkTabs?.header
				}
			]
		}
	]);

	const [graphType, setGraphType] = useState<any>({
		label: 'Line Graph',
		value: 'line'
	});

	const exportAllDataToExcel = async () => {
		setIsLoadingExcelExport(true);
		try {
			const response = await fetchAllReportData();

			const { data: allData } = response;

			await Promise.all(
				Object.values(allData).map(async (val: any) => {
					return val.map((v: any) => {
						Object.assign(v, {
							[`${monthNameMap.get('month0Value')}`]: v.month0Value
						});
						Object.assign(v, {
							[`${monthNameMap.get('month1Value')}`]: v.month1Value
						});
						Object.assign(v, {
							[`${monthNameMap.get('month2Value')}`]: v.month2Value
						});
						Object.assign(v, {
							[`${monthNameMap.get('month3Value')}`]: v.month3Value
						});
						Object.assign(v, {
							[`${monthNameMap.get('month4Value')}`]: v.month4Value
						});
						Object.assign(v, {
							[`${monthNameMap.get('month5Value')}`]: v.month5Value
						});
						Object.assign(v, {
							[`${monthNameMap.get('month6Value')}`]: v.month6Value
						});

						delete v.month0Value;
						delete v.month1Value;
						delete v.month2Value;
						delete v.month3Value;
						delete v.month4Value;
						delete v.month5Value;
						delete v.month6Value;
						delete v.key;
						return v;
					});
				})
			);

			const fileUtils = new FileUtils();

			await fileUtils.saveSpreadsheet({ ...allData }, 'download.xlsx');
		} catch (e) {
		} finally {
			setIsLoadingExcelExport(false);
		}
	};

	const { data: columnData, isLoading: isLoadingConfig } =
		useGetMonthlyTrendReports();

	const { mutateAsync, isLoading } = useMutation(fetchMonthlyTrendReports);

	const mouColumns = useMemo(() => getMonthlyTrendColumnsMou(), []);
	const costColumns = useMemo(() => getMonthlyTrendColumnsCost(), []);
	const cpmColumns = useMemo(() => getMonthlyTrendColumnsCpm(), []);
	const asrColumns = useMemo(() => getMonthlyTrendColumnsAsr(), []);
	const alocColumns = useMemo(() => getMonthlyTrendColumnsAloc(), []);
	const dnoColumns = useMemo(() => getMonthlyTrendColumnsDno(), []);

	const megaExportAllNetworkDataToExcel = async () => {
		const allNetworkTabsObject: any = {};
		setIsLoadingMegaExcelExport(true);

		const tabs = [
			'ASR',
			'Aloc',
			'PDD',
			'Roboscore',
			'Count Dno',
			'Unique Anis'
		];
		try {
			await Promise.all(
				tabs.map(async (tab) => {
					const bodyFilters: DataFilter[] = [
						{
							key: 'dataSource',
							operator: DataFilterOperator.In,
							operands: [{ dataSource: DataSources.Text, value: cdrType }]
						},
						{
							key: 'reportType',
							operator: DataFilterOperator.In,
							operands: [{ dataSource: DataSources.Text, value: tab }]
						}
					];

					const data = await mutateAsync({
						timeRange: {
							timeObject: {
								mode: TimeRangePresets.Public,
								start: startOfDay(new Date()),
								end: endOfDay(new Date())
							},
							detailLevel: {
								key: TimeFilterDetailKeys.Days,
								value: 1
							}
						},
						filters: bodyFilters,
						dataColumns: additionalColumns
							? additionalColumns.map((col: any) => col.value)
							: []
					});

					allNetworkTabsObject[tab] = data;
				})
			);

			const result = [];

			for (let i = 0; i < allNetworkTabsObject.ASR.length; i++) {
				const obj: any = {};
				if (isEmpty(additionalColumns)) {
					obj.account = allNetworkTabsObject.ASR[i].account;
				} else {
					additionalColumns
						.map((col: any) => col.value)
						.forEach((column: any) => {
							obj[column] = allNetworkTabsObject.ASR[i][column];
						});
				}
				for (let month = 0; month < 7; month++) {
					obj[`ASR-${monthNameMap.get(`month${month}Value`)}`] =
						allNetworkTabsObject.ASR[i][`month${month}Value`];
				}
				result.push(obj);
			}

			for (const key in allNetworkTabsObject) {
				if (key !== 'ASR' && allNetworkTabsObject.hasOwnProperty(key)) {
					for (let i = 0; i < allNetworkTabsObject[key].length; i++) {
						for (let month = 0; month < 7; month++) {
							(result as any)[i][
								`${key}-${monthNameMap.get(`month${month}Value`)}`
							] = allNetworkTabsObject[key][i][`month${month}Value`];
						}
					}
				}
			}

			const finalArray: Array<Array<{ value: any }>> = [];

			const months = Array.from(monthNameMap.values());

			result.forEach((obj: any) => {
				const innerArray = [];
				if (additionalColumns.length > 0) {
					additionalColumns
						.map((col: any) => col.value)
						.forEach((column: any) => {
							innerArray.push({ value: obj[column] });
						});
				} else {
					innerArray.push({ value: obj.account });
				}
				months.forEach((month: any) => {
					tabs.forEach((tab) => {
						const key = `${tab}-${month}`;
						if (obj[key] !== undefined) {
							innerArray.push({ value: obj[key] });
						}
					});
				});

				finalArray.push(innerArray);
			});

			const HEADER_ROW = generateFirstRow(additionalColumns, tabs);

			const HEADER_ROW_2 = generateSecondRow(additionalColumns, tabs);

			const correctData = [HEADER_ROW, HEADER_ROW_2, ...finalArray];
			await writeXlsxFile(correctData, {
				fileName: 'networkReports.xlsx'
			} as any);
		} catch (e) {
		} finally {
			setIsLoadingMegaExcelExport(false);
		}
	};

	const megaExportAllDataToExcel = async () => {
		if (reportingType) {
			return megaExportAllNetworkDataToExcel();
		}
		setIsLoadingMegaExcelExport(true);
		const allTabsObject: any = {};

		const tabs =
			cdrType !== 'offnet'
				? ['MOU', 'Revenue', 'Cost', 'Margin', 'RPM', 'CPM', 'MPM']
				: ['MOU', 'Cost', 'CPM'];

		try {
			await Promise.all(
				tabs.map(async (tab) => {
					const bodyFilters: DataFilter[] = [
						{
							key: 'dataSource',
							operator: DataFilterOperator.In,
							operands: [{ dataSource: DataSources.Text, value: cdrType }]
						},
						{
							key: 'reportType',
							operator: DataFilterOperator.In,
							operands: [{ dataSource: DataSources.Text, value: tab }]
						}
					];

					const data = await mutateAsync({
						timeRange: {
							timeObject: {
								mode: TimeRangePresets.Public,
								start: startOfDay(new Date()),
								end: endOfDay(new Date())
							},
							detailLevel: {
								key: TimeFilterDetailKeys.Days,
								value: 1
							}
						},
						filters: bodyFilters,
						dataColumns: additionalColumns
							? additionalColumns.map((col: any) => col.value)
							: []
					});

					allTabsObject[tab] = data;
				})
			);

			const result = [];

			for (let i = 0; i < allTabsObject.MOU.length; i++) {
				const obj: any = {};
				if (isEmpty(additionalColumns)) {
					obj.account = allTabsObject.MOU[i].account;
				} else {
					additionalColumns
						.map((col: any) => col.value)
						.forEach((column: any) => {
							obj[column] = allTabsObject.MOU[i][column];
						});
				}
				for (let month = 0; month < 7; month++) {
					obj[`MOU-${monthNameMap.get(`month${month}Value`)}`] =
						allTabsObject.MOU[i][`month${month}Value`];
				}
				result.push(obj);
			}

			for (const key in allTabsObject) {
				if (key !== 'MOU' && allTabsObject.hasOwnProperty(key)) {
					for (let i = 0; i < allTabsObject[key].length; i++) {
						for (let month = 0; month < 7; month++) {
							(result as any)[i][
								`${key}-${monthNameMap.get(`month${month}Value`)}`
							] = allTabsObject[key][i][`month${month}Value`];
						}
					}
				}
			}

			const finalArray: Array<Array<{ value: any }>> = [];

			const months = Array.from(monthNameMap.values());

			result.forEach((obj: any) => {
				const innerArray = [];
				if (additionalColumns.length > 0) {
					additionalColumns
						.map((col: any) => col.value)
						.forEach((column: any) => {
							innerArray.push({ value: obj[column] });
						});
				} else {
					innerArray.push({ value: obj.account });
				}
				months.forEach((month: any) => {
					tabs.forEach((tab) => {
						const key = `${tab}-${month}`;
						if (obj[key] !== undefined) {
							innerArray.push({ value: obj[key] });
						}
					});
				});

				finalArray.push(innerArray);
			});

			const HEADER_ROW = generateFirstRow(additionalColumns, tabs);

			const HEADER_ROW_2 = generateSecondRow(additionalColumns, tabs);

			const correctData = [HEADER_ROW, HEADER_ROW_2, ...finalArray];
			await writeXlsxFile(correctData, {
				fileName: 'financialReport.xlsx'
			} as any);
		} catch (e) {
		} finally {
			setIsLoadingMegaExcelExport(false);
		}
	};

	const editUrl = (
		correctTab: string,
		correctCdrType: string,
		correctReportingType: number,
		correctColumns?: any[]
	) => {
		const urlObject: any = {
			tab: correctTab,
			cdrType: correctCdrType,
			reportingType: correctReportingType ? 'network' : 'financial'
		};
		if (!isEmpty(correctColumns)) {
			urlObject.correctColumns = JSON.stringify(
				correctColumns?.map((col: any) => col.value)
			);
		}
		const search = new URLSearchParams(urlObject);

		window.history.pushState({ path: 'test' }, '', `?${search}`);
	};

	const fetchReportData = useCallback(
		async (tabValue: string, cdrValue: string, columns: any[]) => {
			const bodyFilters: DataFilter[] = [
				{
					key: 'dataSource',
					operator: DataFilterOperator.In,
					operands: [{ dataSource: DataSources.Text, value: cdrValue }]
				},
				{
					key: 'reportType',
					operator: DataFilterOperator.In,
					operands: [{ dataSource: DataSources.Text, value: tabValue }]
				}
			];
			const data = await mutateAsync({
				timeRange: {
					timeObject: {
						mode: TimeRangePresets.Public,
						start: startOfDay(new Date()),
						end: endOfDay(new Date())
					},
					detailLevel: {
						key: TimeFilterDetailKeys.Days,
						value: 1
					}
				},
				filters: bodyFilters,
				dataColumns: columns ? columns.map((col: any) => col.value) : []
			});

			setReportData(data);
		},
		[mutateAsync]
	);

	useEffect(() => {
		const tab = reportingType ? activeNetworkTabs : activeTab;
		const searchParams = window.location.search;
		if (searchParams) {
			const objectParams = Object.fromEntries(
				new URLSearchParams(window.location.search)
			);
			const { tab, cdrType, reportingType, additionalColumns } = objectParams;

			if (reportingType === 'network') {
				setReportingType(1);
				// setNetworkActiveTabs(tab);
				setCdrType(cdrType);
			} else {
				setReportingType(0);
				// setActiveTab(tab);
				setCdrType(cdrType);
			}
			if (additionalColumns) {
				const formattedColumns = JSON.parse(additionalColumns);
				if (!isEmpty(formattedColumns)) {
					const correctColumnValues = formattedColumns.map((val: any) => {
						return {
							value: val,
							label: columnData?.filter((v) => v.value === val)[0].label
						};
					});
					setAdditionalColumns(correctColumnValues);
				}
			}
			fetchReportData(
				tab,
				cdrType,
				additionalColumns ? JSON.parse(additionalColumns) : ''
			);
		} else {
			fetchReportData(tab.header, cdrType, additionalColumns);
		}
	}, [
		activeNetworkTabs,
		activeTab,
		additionalColumns,
		cdrType,
		columnData,
		fetchReportData,
		reportingType
	]);

	const handleChangeTab = (tab: string) => {
		setActiveTab(tab);
		const filterCopy = [...filters];
		filterCopy[1].operands[0].value = tab;
		setFilters(filterCopy);
		fetchReportData(tab, cdrType, additionalColumns);
		editUrl(tab, cdrType, reportingType, additionalColumns);
	};

	const handleChangeNetworkTab = (tab: string) => {
		setNetworkActiveTabs(tab);
		const filterCopy = [...filters];
		filterCopy[1].operands[0].value = tab;
		setFilters(filterCopy);
		fetchReportData(tab, cdrType, additionalColumns);
		editUrl(tab, cdrType, reportingType, additionalColumns);
	};

	const handleChangeCdrType = (type: string) => {
		setCdrType(type);
		const filterCopy = [...filters];
		filterCopy[0].operands[0].value = type;
		let correctTab = reportingType
			? activeNetworkTabs.header
			: activeTab.header;
		if (type === 'offnet') {
			correctTab = 'MOU';
		}
		setFilters(filterCopy);
		fetchReportData(correctTab, type, additionalColumns);
		editUrl(correctTab, type, reportingType, additionalColumns);
	};

	const handleChangeReportingType = (type: number) => {
		const correctTab = type ? activeNetworkTabs.header : activeTab.header;
		setReportingType(type);
		fetchReportData(correctTab, cdrType, additionalColumns);
		editUrl(correctTab, cdrType, type, additionalColumns);
	};

	const handleSelectUserQuery = (query?: GuardianUserQueryIndexResponse) => {
		if (query && !isEmpty(query)) {
			setUserQuery(query);
			const { queryParameters } = query;
			const { filters, dataColumns } = queryParameters;
			const tabFromQuery = (filters as any)[1].operands[0].value;
			const cdrTypeFromQuery = (filters as any)[0].operands[0].value;
			handleChangeTab(tabFromQuery);
			handleChangeCdrType(cdrTypeFromQuery);
			if (dataColumns && !isEmpty(dataColumns)) {
				const correctColumnValues = dataColumns.map((val) => {
					return {
						value: val,
						label: columnData?.filter((v) => v.value === val)[0].label
					};
				});
				setAdditionalColumns(correctColumnValues);
			}
			editUrl(tabFromQuery, cdrTypeFromQuery, reportingType, dataColumns);
		}
	};

	const handleSetAdditionalColumns = (columns: any) => {
		setAdditionalColumns(columns);
		const correctTab = reportingType
			? activeNetworkTabs.header
			: activeTab.header;
		fetchReportData(correctTab, cdrType, columns);
		editUrl(correctTab, cdrType, reportingType, columns);
	};

	return (
		<StandardLayout router={MonthlyTrendingRouter}>
			<Row>
				<Col xl={6}>
					{' '}
					<Row>
						<span>Choose which grid to see</span>
					</Row>
					<Row>
						<RadioFormControl<string>
							hideLabel={true}
							label=""
							onChange={(cdrType) => {
								handleChangeCdrType(cdrType);
							}}
							value={cdrType}
							options={[
								{ value: 'customer', label: 'Customer' },
								{ value: 'vendor', label: 'Vendor' },
								{ value: 'offnet', label: 'Offnet' }
							]}
						/>
					</Row>
					<div
						style={{
							display: 'flex',
							alignContent: 'center',
							alignItems: 'center'
						}}
					>
						<Row>
							<RadioFormControl<number>
								hideLabel={true}
								label=""
								onChange={(reportingType) => {
									handleChangeReportingType(reportingType);
								}}
								value={reportingType}
								options={[
									{ value: 0, label: 'Financial' },
									{ value: 1, label: 'Network' }
								]}
							/>
						</Row>
						{isLoadingExcelExport ? (
							<i className="fas fa-cog fa-cog fa-spin" />
						) : (
							<Button
								type="button"
								variant="white-alt"
								style={{ marginLeft: '10px', height: '40px' }}
								data-toggle="tooltip"
								data-placement="top"
								title="Export All Data"
								onClick={exportAllDataToExcel}
							>
								<i className="fas fa-file-excel"></i>{' '}
							</Button>
						)}

						{isLoadingMegaExcelExport ? (
							<i
								style={{ marginLeft: '10px' }}
								className="fas fa-cog fa-cog fa-spin"
							/>
						) : (
							<Button
								type="button"
								variant="white-alt"
								style={{ marginLeft: '10px', height: '40px' }}
								data-toggle="tooltip"
								data-placement="top"
								title="Export All Reports"
								onClick={megaExportAllDataToExcel}
							>
								<i className="fa-solid fa-globe"></i>{' '}
							</Button>
						)}
					</div>
				</Col>
				<Col xl={5}>
					<section className="mb-3">
						<Row className="mb-3">
							<Col md={3} className="align-self-center">
								<Form.Label className="label-90">Saved Queries</Form.Label>
							</Col>
							<Col>
								<VueSavedQuerySelect
									timeRange={
										{
											timeObject: {
												mode: 'public',
												start: '2023-08-01T00:00:00.399Z',
												end: '2023-08-31T01:59:59.999Z'
											}
										} as any
									}
									reportId={Reports.MonthlyTrending}
									additionalColumns={additionalColumns}
									filters={filters}
									onChange={(userQuery) => {
										handleSelectUserQuery(userQuery);
									}}
									onSave={() => {}}
									userQuery={userQuery}
								/>
							</Col>
						</Row>

						<Row>
							<Col md={3} className="align-self-center">
								<Form.Label className="d-flex align-items-center">
									Add. Columns
								</Form.Label>
							</Col>
							<Col>
								{!isLoadingConfig && columnData && (
									<VueSelectAdditionalColumns
										isMulti={true}
										selectedAdditionalColumns={additionalColumns}
										allColumns={columnData}
										onChange={handleSetAdditionalColumns}
									/>
								)}
							</Col>
						</Row>
					</section>
				</Col>
			</Row>
			<MonthlyTrendingReportsComponent
				isLoading={isLoading}
				tabs={tabs}
				activeTab={activeTab}
				cdrType={cdrType}
				reportData={reportData}
				reportingType={reportingType}
				handleChangeNetworkTab={handleChangeNetworkTab}
				networkTabs={networkTabs}
				networkActiveTab={activeNetworkTabs}
				handleChangeTab={handleChangeTab}
				mouColumns={mouColumns}
				costColumns={costColumns}
				cpmColumns={cpmColumns}
				asrColumns={asrColumns}
				alocColumns={alocColumns}
				dnoColumns={dnoColumns}
				offnetTabs={offnetTabs}
				activeOffnetTabs={activeOffnetTabs}
				graphType={graphType}
				setGraphType={setGraphType}
			/>
		</StandardLayout>
	);
};

MonthlyTrendingIndexContainer.displayName = 'MonthlyTrendingIndexContainer';

MonthlyTrendingRouter.setIndexRtUiFunctionalComponent(
	MonthlyTrendingIndexContainer,
	{
		groupName: 'Business Reports',
		groupId: 'VueBusinessReports'
	}
);
