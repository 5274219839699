import clsx from 'clsx';
import * as React from 'react';
import { Form } from 'react-bootstrap';
import { RtError } from 'RtUi/utils/errors/RtError';

interface IFormErrorsProps {
	error?: any;
	className?: string;
	title?: React.ReactChild;
	append?: React.ReactChild;
	autoHide?: boolean;
}

export class FormErrors extends React.Component<IFormErrorsProps, {}> {
	public listRef: HTMLElement | null = null;

	public componentDidUpdate() {
		this.scrollToBottom();
	}

	public setListRef(listRef: HTMLElement | null) {
		this.listRef = listRef;

		this.scrollToBottom();
	}

	public scrollToBottom() {
		if (!this.listRef) {
			return;
		}

		this.listRef.scrollTop = this.listRef.scrollHeight;
	}

	public getErrorMessages(error: any): string[] {
		if (typeof error === 'object' && 'props' in error) {
			return [error];
		}

		if (typeof error === 'string') {
			return [error];
		}

		if (error instanceof RtError) {
			return error.messages;
		}

		if ('message' in error) {
			return [error.message];
		}

		return [error.toString()];
	}

	public render() {
		const {
			title = 'The following errors occurred:',
			autoHide = true,
			append
		} = this.props;
		const { error } = this.props;

		if (autoHide && !error) {
			return null;
		}

		return (
			<Form.Group className={clsx(this.props.className, 'mb-3')}>
				<div className="alert alert-danger" role="alert">
					<h6 className="alert-heading fw-bold">{title}</h6>
					<article
						ref={(ref) => this.setListRef(ref)}
						className="list-group list-group-flush"
						style={{
							maxHeight: 200,
							minWidth: 250,
							overflowY: 'auto'
						}}
					>
						<ul className="mb-0">
							{this.getErrorMessages(error).map((err, index) => (
								<li key={index}>{err}</li>
							))}
						</ul>
					</article>
					{append}
				</div>
			</Form.Group>
		);
	}
}
