import {
	Reports,
	TermSummaryIndexResponseData,
	TimeRangePresets
} from 'RtModels';
import { TermSummaryRouter } from 'RtUi/app/rtVue/TermSummaryReport/TermSummaryReport.router';
import { TermSummaryGrid } from 'RtUi/app/rtVue/TermSummaryReport/lib/grids/TermSummaryReportGrid';
import {
	IRtVueContainerState,
	RtVueReportContainer
} from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import * as React from 'react';

@TermSummaryRouter.getIndexRtUiController({
	groupName: 'Business Reports',
	groupId: 'VueBusinessReports'
})
export class TermSummaryIndexContainer extends RtVueReportContainer<TermSummaryIndexResponseData> {
	public state: IRtVueContainerState<TermSummaryIndexResponseData> = {
		...this.getBaseState({ defaultTimeRangePreset: TimeRangePresets.Custom }),
		timeRangePresetsKeys: [TimeRangePresets.Custom],
		hideDetailsOption: true
	};
	protected reportId = Reports.LcoSummary;
	protected router = TermSummaryRouter;

	protected async getResource(): Promise<
		HttpResource<TermSummaryIndexResponseData>
	> {
		const {
			timeRange,
			filters,
			partition,
			preset,
			dataSums,
			additionalColumns
		} = this.state;
		const vueHttp = new RtVueHttp();
		const {
			totalCount,
			data: { data }
		} = await vueHttp.getTermSummaryView(
			timeRange,
			filters,
			dataSums,
			preset,
			partition,
			additionalColumns?.map((column) => column.value)
		);

		const termSummaryResource = new ArrayResource<TermSummaryIndexResponseData>(
			'callDate',
			data.data
		);
		termSummaryResource.setTotalCount(totalCount);

		return termSummaryResource;
	}

	protected renderReports(): React.ReactNode {
		return (
			<TermSummaryGrid
				isLoading={this.state.loadingReport}
				defaultSorting={this.state.defaultSorting}
				onSortingChange={this.onSortedChange}
				resource={this.state.resource}
			/>
		);
	}
}
