import { FormControl, FormLabel } from '@mui/material';
import clsx from 'clsx';
import NumericInput, { NumericInputProps } from 'react-numeric-input';

export type RtxPrecisionInputProps = Omit<
	NumericInputProps,
	'onInput' | 'onChange'
> & {
	onChange?: (value: number | null) => void;
	allowCurrency?: boolean;
	displayMode?: boolean;
	label: string;
};

export const RtxPrecisionInput = ({
	disabled,
	label,
	className,
	value,
	onChange = () => {},
	allowCurrency,
	displayMode,
	...props
}: RtxPrecisionInputProps) => {
	const parseCurrency = (originalValue: string) => {
		const match = /^\$?(\d+(?:\.\d+)*(?:\,\d+)?)\$?$/g.exec(originalValue);
		let value = match && match[1];

		if (!value) {
			return Number(originalValue);
		}

		const validators = [
			/^(?:\d{1,3})(?:\.\d{3}?)+$/g,
			/^\d+,\d+$/g,
			/^(?:\d{1,3}\.?)+,\d+$/g
		];

		if (validators.some((v) => value?.match(v))) {
			value = value.replace('.', '').replace(',', '.');
		}

		return Number(value);
	};

	const handleOnInput = (evt: React.FormEvent<HTMLInputElement>) => {
		if (allowCurrency) {
			onChange(parseCurrency(evt.currentTarget.value));
		}
	};

	return (
		<FormControl
			variant="outlined"
			sx={{ width: '100%' }}
			className={className}
			disabled={displayMode || disabled}
		>
			<FormLabel
				sx={{
					fontSize: '12px',
					position: 'absolute',
					background: 'linear-gradient(to bottom, transparent 50%, #FFF 50%)',
					padding: '0 6px',
					left: '8px',
					top: '-9px'
				}}
				required={props.required}
			>
				{label}
			</FormLabel>
			<NumericInput
				strict
				className={clsx('material-style', {
					disabled: disabled || displayMode
				})}
				style={false}
				{...props}
				onInput={handleOnInput}
				disabled={disabled || displayMode}
				onChange={(newValue) => onChange(newValue)}
				onKeyDown={(evt) => {
					//If a user types in a period, move their cursor to after the period
					//for ease of use
					if (
						evt.code.toLowerCase() !== 'period' ||
						!(evt.target instanceof HTMLInputElement)
					) {
						return;
					}

					evt.preventDefault();
					evt.stopPropagation();

					const currentValue = evt.target.value;
					const dotIndex = currentValue.indexOf('.');

					if (dotIndex >= 0) {
						evt.target.selectionStart = dotIndex + 1;
						evt.target.selectionEnd = dotIndex + 1;
					}
				}}
				value={typeof value === 'number' ? value : 0}
			/>
		</FormControl>
	);
};
