import {
	AccountIndexResponse,
	ScenarioIndexResponse,
	ServiceNumberIndexRequest,
	ServiceNumberProfileResponse,
	SubscriptionIndexResponse
} from 'RtModels';
import { AccountSelect } from 'RtUi/app/AccountManagement/Accounts/lib/controls/AccountSelect';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { ServiceNumberEditor } from 'RtUi/app/rtCommon/ServiceNumbers/lib/forms/ServiceNumberEditor';
import { ServiceNumberPropertyToNameMap } from 'RtUi/app/rtCommon/ServiceNumbers/lib/grids/Constants';
import { ServiceNumberGrid } from 'RtUi/app/rtCommon/ServiceNumbers/lib/grids/ServiceNumberGrid';
import { ScenarioSelect } from 'RtUi/app/rtSip/Scenarios/lib/controls/ScenarioSelect';
import { GlobalNumbersRouter } from 'RtUi/app/rtVue/GlobalNumbers/GlobalNumbersRouter';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { FC, useMemo, useState } from 'react';
import { useMount, useSearchParam } from 'react-use';

export const GlobalNumberIndex: FC<React.PropsWithChildren<unknown>> = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search' },
		Results: { header: 'Results', isDefault: true },
		Create: {
			header: 'Create Global Number',
			isHidden: true
		}
	});
	const [isActive, setIsActive] = useState<number | null>(1);
	const [subscription, setSubscription] = useState<SubscriptionIndexResponse>();
	const [externalAccount, setExternalAccount] = useState<string>();
	const [externalSubAccount, setExternalSubAccount] = useState<string>();
	const [extOrderNumber, setExtOrderNumber] = useState<string>();
	const [scenario, setScenario] = useState<ScenarioIndexResponse>();
	const [serviceNumber, setServiceNumber] = useState<string>();
	const [account, setAccount] = useState<AccountIndexResponse>();
	const [initialServiceNumber, setInitialServiceNumber] =
		useState<ServiceNumberProfileResponse>();
	const create = useSearchParam('create');

	useMount(() => {
		if (create !== null) {
			setInitialServiceNumber(JSON.parse(create));
			setActiveTab(tabs.Create.header);
		}
	});

	const resourceParams = useMemo(() => {
		const params: ServiceNumberIndexRequest = {
			serviceNumber,
			custExtRef1: externalAccount,
			custExtRef2: externalSubAccount,
			custOrderNumber: extOrderNumber
		};

		if (isActive !== null) {
			params.isActive = isActive;
		}

		if (subscription) {
			params.subscriptionId = subscription.subscriptionId;
		}

		if (scenario) {
			params.scenarioId = scenario.scenarioId;
		}

		if (account) {
			params.accountId = account.accountId;
		}

		return params;
	}, [
		isActive,
		serviceNumber,
		externalAccount,
		externalSubAccount,
		extOrderNumber,
		scenario,
		account,
		subscription
	]);

	const searchGlobalNumbers = async (
		event: React.FormEvent<HTMLFormElement>
	) => {
		try {
			event.preventDefault();
			setActiveTab('Results');
		} catch (e) {}
	};

	return (
		<TabbedLayout
			router={GlobalNumbersRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtUiSearchForm
					onSubmit={(evt) => {
						searchGlobalNumbers(evt);
					}}
				>
					<InputFormControl
						label="Service Number"
						onChange={setServiceNumber}
						value={serviceNumber}
					/>
					<AccountSelect showAllOption onChange={setAccount} value={account} />
					<SubscriptionSelect onChange={setSubscription} value={subscription} />
					<ScenarioSelect value={scenario} onChange={setScenario} />
					<InputFormControl
						label={ServiceNumberPropertyToNameMap.get('custExtRef1')!}
						onChange={setExternalAccount}
						value={externalAccount}
					/>
					<InputFormControl
						label={ServiceNumberPropertyToNameMap.get('custExtRef2')!}
						onChange={setExternalSubAccount}
						value={externalSubAccount}
					/>
					<InputFormControl
						label={ServiceNumberPropertyToNameMap.get('custOrderNumber')!}
						onChange={setExtOrderNumber}
						value={extOrderNumber}
					/>
					<IsActiveRadioFormControl onChange={setIsActive} value={isActive} />
				</RtUiSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<ServiceNumberGrid
					resourceParams={resourceParams}
					router={GlobalNumbersRouter}
					pageName="configureRateSheet-globalNumbers"
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Create}>
				<ServiceNumberEditor
					wide
					value={initialServiceNumber}
					createMode
					onSuccess={() => setActiveTab(tabs.Results.header)}
					onCancel={() => setActiveTab(tabs.Results.header)}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

GlobalNumbersRouter.setIndexRtUiFunctionalComponent(GlobalNumberIndex, {
	groupName: 'Configuration'
});
