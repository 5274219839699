import { RoutingRuleCreateRequest, RoutingRuleProfileResponse } from 'RtModels';
import {
	RoutingRuleEditor,
	RoutingRuleValue
} from 'RtUi/app/rtSip/RoutingRules/lib/forms/RoutingRuleEditor';
import { RoutingRuleResource } from 'RtUi/app/rtSip/RoutingRules/lib/resources/RoutingRuleResource';
import { useMemo, useState } from 'react';

interface IRoutingRuleCreateFormProps {
	onCreate: (routingRule: RoutingRuleProfileResponse) => void;
}

export const RoutingRuleCreateForm = ({
	onCreate
}: IRoutingRuleCreateFormProps) => {
	const profile = useMemo<RoutingRuleCreateRequest>(
		() => ({
			label: '',
			notes: '',
			isCascade: 0,
			isLcrAppend: 1,
			matching: [],
			actions: [],
			schedule: []
		}),
		[]
	);
	const [error, setError] = useState<string>();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const onSubmitHandler = async (value: RoutingRuleValue) => {
		setError(undefined);
		setIsSubmitting(true);

		const resource = new RoutingRuleResource();

		try {
			const newProfile = await resource.create(value);
			onCreate(newProfile);
		} catch (e: any) {
			setError(e.message);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<RoutingRuleEditor
			profile={profile}
			onChange={onSubmitHandler}
			error={error}
			isSubmitting={isSubmitting}
		/>
	);
};
