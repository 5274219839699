import { isNil } from 'lodash-es';
import * as moment from 'moment';
import {
	RateLoadIndexRequest,
	RateLoadIndexResponse,
	RatePlanIndexResponse,
	SubscriptionIndexResponse
} from 'RtModels';
import { BrowserHistory } from 'RtUi/app/@RtUi/lib/browser';
import { RateLoadDataGrid } from 'RtUi/app/AccountManagement/RateManagement/libs/grids/RateLoadGrid';
import { RateManagementRouter } from 'RtUi/app/AccountManagement/RateManagement/RateManagement.router';
import { RatePlanSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/RatePlanSelect';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';

interface IInitialResourceParams {
	createdDateTs?: moment.Moment;
	completedDateTs?: moment.Moment;
	ratePlan?: RatePlanIndexResponse;
	subscription?: SubscriptionIndexResponse;
	isCompleted?: number;
}

interface IRateManagementIndexContainerState
	extends ISearchResultsContainerState,
		IInitialResourceParams {}

export class RateManagementIndexContainer extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	IRateManagementIndexContainerState
> {
	public initParams: IInitialResourceParams = {};

	public state: IRateManagementIndexContainerState = {
		...this.initParams,
		activeTab: this.tabs.Results,
		isCompleted: -1,
		resourceParams: {}
	};

	constructor(props: any) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public goToProfile(id: number) {
		const profilePath = RateManagementRouter.getProfileRoute(id);

		this.goToPath(profilePath);
	}

	public getResourceParams() {
		const ratePlanId = this.getSearchParam('ratePlan');
		const subscriptionId = this.getSearchParam('subscription');
		const {
			createdDateTs,
			completedDateTs,
			isCompleted,
			ratePlan,
			subscription
		} = this.state;

		const resourceParams: RateLoadIndexRequest = {};

		if (ratePlanId) {
			resourceParams.ratePlanId = Number(ratePlanId);
		}

		if (subscriptionId) {
			resourceParams.subscriptionId = Number(subscriptionId);
		}

		if (!isNil(createdDateTs)) {
			resourceParams.createdDateTs = createdDateTs.toDate();
		}

		if (!isNil(completedDateTs)) {
			resourceParams.completedDateTs = completedDateTs.toDate();
		}

		if (!isNil(ratePlan)) {
			resourceParams.ratePlanId = ratePlan.ratePlanId;
		}

		if (!isNil(subscription)) {
			resourceParams.subscriptionId = subscription.subscriptionId;
		}

		if (isCompleted !== -1) {
			resourceParams.isComplete = isCompleted;
		}

		return resourceParams;
	}

	public render() {
		return (
			<TabbedLayout<RateLoadIndexResponse>
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
				router={RateManagementRouter}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm onSubmit={(e) => this.submitAndGoToResults(e)}>
						<SubscriptionSelect
							label="SubscriptionId"
							value={this.state.subscription}
							isClearable
							onChange={(
								subscription: SubscriptionIndexResponse | undefined
							) => {
								this.setState({ subscription });
								BrowserHistory.push(
									RateManagementRouter.getIndexRouteWithParams({
										subscription: subscription?.subscriptionId ?? ''
									})
								);
							}}
							initialOptionId={
								Number(this.getSearchParam('subscription')) ?? undefined
							}
						/>
						<RatePlanSelect
							name="ratePlanId"
							onChange={(ratePlan: RatePlanIndexResponse) => {
								this.setState({ ratePlan });
								BrowserHistory.push(
									RateManagementRouter.getIndexRouteWithParams({
										ratePlan: ratePlan?.ratePlanId ?? ''
									})
								);
							}}
							value={this.state.ratePlan}
							initialOptionId={
								Number(this.getSearchParam('ratePlan')) ?? undefined
							}
						/>
						<DatetimeFormControl
							showDateOnly
							label="Created Date"
							onChangeWithIsNow={(createdDateTs) =>
								this.setState({ createdDateTs })
							}
							value={this.state.createdDateTs}
						/>
						<DatetimeFormControl
							showDateOnly
							label="Completed Date"
							onChangeWithIsNow={(completedDateTs) =>
								this.setState({ completedDateTs })
							}
							value={this.state.completedDateTs}
						/>
						<BooleanRadioFormControl
							label="Completed"
							showBothOption
							onChange={(isCompleted) => this.setState({ isCompleted })}
							value={this.state.isCompleted}
						/>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<RateLoadDataGrid resourceParams={this.state.resourceParams} />
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}

RateManagementRouter.setIndexRtUiFunctionalComponent(
	RateManagementIndexContainer
);
