import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import * as React from 'react';
import NavButton from 'RtUi/components/ui/TabbedLayout/NavButton';
import NavButtonArrow from 'RtUi/components/ui/TabbedLayout/NavButtonArrow';
import { TabLayoutTab } from 'RtUi/components/ui/TabbedLayout/TabLayoutContext';

interface INavButtonGroupProps {
	label: string;
	parentTab: TabLayoutTab;
	onClickTabHandler: (tab: TabLayoutTab) => void;
	tabs: TabLayoutTab[];
	isActive: boolean;
	isAction?: boolean;
}

export default function NavButtonGroup({
	label,
	parentTab,
	onClickTabHandler,
	tabs,
	isActive,
	isAction
}: INavButtonGroupProps) {
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);

	const handleMenuItemClick = (tab: TabLayoutTab) => {
		setOpen(false);
		onClickTabHandler(tab);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const availableTabs: TabLayoutTab[] = isAction
		? [...tabs]
		: [parentTab, ...tabs];
	const subLabel =
		label === parentTab.header
			? parentTab.header
			: `${parentTab.header}: ${label}`;

	return (
		<React.Fragment>
			<ButtonGroup
				variant="outlined"
				ref={anchorRef}
				sx={{
					marginRight: '10px',
					borderRadius: 2
				}}
			>
				<NavButton
					label={isAction ? label : subLabel}
					onClickTabHandler={onClickTabHandler}
					tab={parentTab}
					isActive={isActive}
				/>
				<NavButtonArrow
					isActive={isActive}
					isOpen={open}
					handleToggle={handleToggle}
				/>
			</ButtonGroup>
			<Popper
				sx={{ zIndex: 1 }}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu" autoFocusItem>
									{availableTabs
										.filter((tab) => tab.header !== label)
										.map((tab) => (
											<MenuItem
												key={tab.header}
												// selected={index === selectedIndex}
												onClick={() => handleMenuItemClick(tab)}
											>
												{tab.header}
											</MenuItem>
										))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</React.Fragment>
	);
}
