//cSpell:disable
import { DownloadTypes } from 'RtModels';
import { TFileStream } from 'RtUi/app/rtVue/FileStreams/lib/forms/FileStreamEditor';
import {
	AzureAccessMethod,
	FTPTLSConnectMethod,
	IAWSCredentials,
	IAzureConnectionCredentials,
	IAzureCredentials,
	IAzureSasCredentials,
	IBrowserCrendentials,
	IFTPCredentials,
	IFTPCredentialsFieldConfig,
	IFTPTLSCredentials,
	ISFTPCredentials
} from 'RtUi/app/rtVue/FileStreams/lib/types/RemoteCredentials';
import CreatableMultiSelect from 'RtUi/components/form/CreatableMultiSelect';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { PortInputFormControl } from 'RtUi/components/form/PortFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { parseJson } from 'RtUi/utils/json';
import { useEffect, useState } from 'react';

interface IRemoteCredentialsEditorProps {
	downloadRemoteCredentials: string;
	isDownload?: boolean;
	downloadType?: DownloadTypes;
	displayMode?: boolean;
	onChange?: <K extends keyof TFileStream>(
		key: K,
		value: TFileStream[K]
	) => void;
}

export const RemoteCredentialsEditor = ({
	downloadRemoteCredentials,
	downloadType,
	isDownload = false,
	displayMode = false,
	onChange = () => {}
}: IRemoteCredentialsEditorProps): JSX.Element => {
	const [remoteCredentials, setRemoteCredentials] = useState<any>({});

	useEffect(
		() => setRemoteCredentials(parseJson(downloadRemoteCredentials)),
		[downloadRemoteCredentials]
	);

	const onCredentialsChange = <T, K extends keyof T>(key: K, value: T[K]) => {
		setRemoteCredentials((currentState: T) => {
			const newState = { ...currentState };

			newState[key] = value;
			onChange('downloadRemoteCredentials', JSON.stringify(newState));

			return newState;
		});
	};

	const renderFtpInputs = (
		data: IFTPCredentials,
		config?: IFTPCredentialsFieldConfig
	) => {
		return (
			<>
				<InputFormControl
					label="User Name"
					onChange={(value) =>
						onCredentialsChange<IFTPCredentials, 'username'>(
							'username',
							value ?? ''
						)
					}
					displayMode={displayMode}
					value={data.username || ''}
					required={config?.username?.required ?? isDownload}
					autoHeight
					initialValue={config?.username?.defaultValue}
				/>
				<InputFormControl
					label="Password"
					onChange={(value) =>
						onCredentialsChange<IFTPCredentials, 'password'>(
							'password',
							value ?? ''
						)
					}
					type="password"
					displayMode={displayMode}
					value={data.password || ''}
					required={config?.password?.required ?? isDownload}
					autoHeight
					initialValue={config?.password?.defaultValue}
				/>
				<InputFormControl
					label="Host"
					onChange={(value) =>
						onCredentialsChange<IFTPCredentials, 'host'>('host', value ?? '')
					}
					displayMode={displayMode}
					value={data.host || ''}
					required={config?.host?.required ?? isDownload}
					autoHeight
					initialValue={config?.host?.defaultValue}
				/>
				<PortInputFormControl
					label="Port"
					onChange={(value) =>
						onCredentialsChange<IFTPCredentials, 'port'>('port', Number(value))
					}
					displayMode={displayMode}
					value={String(data.port || '')}
					required={config?.port?.required ?? isDownload}
					autoHeight
					initialValue={config?.port?.defaultValue}
				/>
			</>
		);
	};

	if (!downloadType) {
		return <p>Please select a Download Type</p>;
	}

	switch (downloadType) {
		case DownloadTypes.Aws: {
			const data = remoteCredentials as IAWSCredentials;
			const arns = (data.arns || []).map((val) => ({
				label: val,
				value: val
			}));

			return (
				<>
					<InputFormControl
						label="User Name"
						onChange={(value) =>
							onCredentialsChange<IAWSCredentials, 'username'>(
								'username',
								value ?? ''
							)
						}
						required={isDownload}
						displayMode={displayMode}
						value={data.username || ''}
						autoHeight
					/>
					<InputFormControl
						label="Password"
						onChange={(value) =>
							onCredentialsChange<IAWSCredentials, 'password'>(
								'password',
								value ?? ''
							)
						}
						required={isDownload}
						type="password"
						displayMode={displayMode}
						value={data.password || ''}
						autoHeight
					/>
					<CreatableMultiSelect
						label="ARNs"
						disabled={displayMode}
						onChange={(value) => {
							const arnsValue = value.map((val) => val.value);
							onCredentialsChange<IAWSCredentials, 'arns'>('arns', arnsValue);
						}}
						value={arns}
					/>
				</>
			);
		}
		case DownloadTypes.Azure: {
			const data = remoteCredentials as IAzureCredentials;

			return (
				<>
					<RadioFormControl<AzureAccessMethod>
						label="Access Method"
						onChange={(value) => {
							onCredentialsChange<IAzureCredentials, 'accessMethod'>(
								'accessMethod',
								value
							);
						}}
						required={isDownload}
						displayMode={displayMode}
						value={data.accessMethod}
						options={[
							{
								value: AzureAccessMethod.ConnectionString,
								label: 'Connection String'
							},
							{ value: AzureAccessMethod.SasToken, label: 'SasToken' }
						]}
					/>
					{data.accessMethod === AzureAccessMethod.ConnectionString && (
						<InputFormControl
							label="Connection String"
							onChange={(value) =>
								onCredentialsChange<
									IAzureConnectionCredentials,
									'connectionString'
								>('connectionString', value ?? '')
							}
							required={isDownload}
							displayMode={displayMode}
							value={
								(data as IAzureConnectionCredentials).connectionString || ''
							}
							autoHeight
						/>
					)}
					{data.accessMethod === AzureAccessMethod.SasToken && (
						<InputFormControl
							label="Sas Token"
							onChange={(value) =>
								onCredentialsChange<IAzureSasCredentials, 'sasToken'>(
									'sasToken',
									value ?? ''
								)
							}
							required={isDownload}
							displayMode={displayMode}
							value={(data as IAzureSasCredentials).sasToken || ''}
							autoHeight
						/>
					)}
				</>
			);
		}
		case DownloadTypes.Browser: {
			const data = remoteCredentials as IBrowserCrendentials;

			return renderFtpInputs(data, {
				host: {
					required: false
				},
				port: {
					required: false
				}
			});
		}
		case DownloadTypes.Ftp: {
			const data = remoteCredentials as IFTPCredentials;

			return renderFtpInputs(data);
		}
		case DownloadTypes.Ftptls: {
			const data = remoteCredentials as IFTPTLSCredentials;
			return (
				<>
					{renderFtpInputs(data)}
					<RadioFormControl<FTPTLSConnectMethod>
						label="Connect Method"
						onChange={(value) => {
							onCredentialsChange<IFTPTLSCredentials, 'connectMethod'>(
								'connectMethod',
								value
							);
						}}
						required={isDownload}
						value={data.connectMethod}
						options={[
							{ value: FTPTLSConnectMethod.True, label: 'True' },
							{ value: FTPTLSConnectMethod.False, label: 'False' },
							{ value: FTPTLSConnectMethod.Implicit, label: 'Implicit' }
						]}
					/>
				</>
			);
		}
		case DownloadTypes.Sftp: {
			const data = remoteCredentials as ISFTPCredentials;
			return (
				<>
					{renderFtpInputs(data)}
					<InputFormControl
						label="Private Key"
						id="private-key"
						onChange={(value) =>
							onCredentialsChange<ISFTPCredentials, 'privateKey'>(
								'privateKey',
								value ?? ''
							)
						}
						displayMode={displayMode}
						value={data.privateKey || ''}
						autoHeight
					/>
				</>
			);
		}
		default: {
			return <p>Unsupported Download Type</p>;
		}
	}
};
