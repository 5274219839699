import { cloneDeep, groupBy } from 'lodash-es';
import * as React from 'react';
import { Col, Row, Card, ButtonGroup, Form } from 'react-bootstrap';
import {
	MessageEventTypeIndexResponse,
	MessageType,
	NotificationFrequency,
	NotificationPreferenceIndexResponse,
	ProductId
} from 'RtModels';
import { UiNotificationsManager } from 'RtUi/notifications/UiNotificationsManager';
import { MyProfileHttp } from 'RtUi/app/user/MyProfile/lib/Http/MyProfileHttp';
import { FormErrors } from 'RtUi/components/form/FormErrors';
import { CheckboxButton } from 'RtUi/components/ui/CheckboxButton';
import { Loading } from 'RtUi/components/ui/Loading';

interface IMyProfileCommunicationPreferencesFormState {
	error?: any;
	wasUpdated: boolean;
	isLoading: boolean;
	isSubmitting: boolean;
	notificationPreferences: NotificationPreferenceIndexResponse[];
	notificationTypes: MessageEventTypeIndexResponse[];
}

export class MyProfileCommunicationPreferencesForm extends React.Component<
	{},
	IMyProfileCommunicationPreferencesFormState
> {
	public state: IMyProfileCommunicationPreferencesFormState = {
		error: undefined,
		wasUpdated: false,
		isLoading: false,
		isSubmitting: false,
		notificationPreferences: [],
		notificationTypes: []
	};

	private myProfileHttp = new MyProfileHttp();
	private uiNotificationsManager = UiNotificationsManager.getInstance();
	private notificationPreferencesCopy: NotificationPreferenceIndexResponse[] =
		[];
	private notificationsListenerRemoveFn: (() => void) | undefined;

	public componentDidMount() {
		this.initNotificationPreferences();
	}

	public componentWillUnmount() {
		if (this.notificationsListenerRemoveFn) {
			this.notificationsListenerRemoveFn();

			this.notificationsListenerRemoveFn = undefined;
		}
	}

	/**
	 * Initialize component by getting all notifications
	 */
	public async initNotificationPreferences() {
		this.setState({ isLoading: true });

		const notificationPreferences =
			await this.myProfileHttp.getAllNotifications();

		this.notificationPreferencesCopy = cloneDeep(notificationPreferences);
		this.setState({ notificationPreferences });

		const notificationTypes = await this.myProfileHttp.getNotificationTypes();
		this.setState({ notificationTypes });

		this.setState({ isLoading: false });

		this.notificationsListenerRemoveFn =
			this.myProfileHttp.onNotificationsUpdate((notificationPreferences) => {
				this.setState({ notificationPreferences });
			});
	}

	/**
	 * On cancel, reset form to last saved state
	 */
	public onCancel() {
		const notificationPreferences = this.notificationPreferencesCopy;
		this.notificationPreferencesCopy = cloneDeep(notificationPreferences);

		this.setState({ notificationPreferences });
	}

	/**
	 * Get proper name for a Produce
	 * @param product
	 */
	public getProductIdName(product: ProductId) {
		// Product[product] will grab the enum name by default
		let productName = ProductId[product];

		switch (product) {
			case ProductId.RT_800:
				productName = 'RT/800';
				break;
			case ProductId.RT_DID:
				productName = 'RT/DID';
				break;
			case ProductId.RT_SIP:
				productName = 'RT/SIP';
				break;
			case ProductId.RT_LCO:
				productName = 'RT/LCO';
				break;
			case ProductId.RT_ADM:
				productName = 'RT/Adm';
				break;
			case ProductId.RT_VUE:
				productName = 'RT/VUE';
				break;
			case ProductId.RT_CARRIER_CONNECT:
				productName = 'RT/800 Account Connect';
				break;
			default:
				break;
		}

		return productName;
	}

	/**
	 * Get proper name for NotificationFrequency
	 * @param notificationFrequency
	 */
	public getFrequencyName(
		notificationFrequency: NotificationFrequency | undefined
	) {
		// NotificationFrequency[notificationFrequency] will grab the enum name by default
		let notificationFrequencyName = notificationFrequency
			? NotificationFrequency[notificationFrequency]
			: '';

		switch (notificationFrequency) {
			case NotificationFrequency.Daily:
				notificationFrequencyName = 'Daily';
				break;
			case NotificationFrequency.Instant:
				notificationFrequencyName = 'Instant';
				break;
			case NotificationFrequency.Monthly:
				notificationFrequencyName = 'Monthly';
				break;
			case NotificationFrequency.Weekly:
				notificationFrequencyName = 'Weekly';
				break;
			default:
				break;
		}

		return notificationFrequencyName;
	}

	/**
	 * Get proper name for MessageType
	 * @param messageType
	 */
	public getMessageTypeName(messageType: MessageType) {
		let messageTypeName = MessageType[messageType];

		switch (messageType) {
			case MessageType.Email:
				messageTypeName = 'Email';
				break;
			case MessageType.Firebase:
				messageTypeName = 'GUI Header';
				break;
			case MessageType.Sms:
				messageTypeName = 'Text';
				break;
			default:
				break;
		}

		return messageTypeName;
	}

	/**
	 * Toggle a preference's value. Commonly used with checkboxes
	 * @param notificationPreference
	 * @param messageType
	 * @param frequency
	 * @param removeAllFrequencies
	 */
	public async toggleNotificationPreference(
		notificationPreference: NotificationPreferenceIndexResponse,
		messageType: MessageType,
		frequency: NotificationFrequency
	) {
		const { notificationPreferences } = this.state;

		const notificationPreferenceIndex = notificationPreferences.findIndex(
			(np) =>
				np.messageEventTypeId === notificationPreference.messageEventTypeId
		);

		if (notificationPreferenceIndex < 0) {
			return;
		}

		this.setState({ isSubmitting: true });

		const messageTypeNotificationIndex =
			notificationPreference.notifications.findIndex((notification) => {
				return (
					notification.messageTypeId === messageType &&
					notification.userNotificationFrequencyId === frequency
				);
			});

		if (messageTypeNotificationIndex < 0) {
			await this.myProfileHttp.addNotification({
				messageTypeId: messageType,
				messageEventTypeId: notificationPreference.messageEventTypeId,
				userNotificationFrequencyId: frequency,
				customStartTime: null,
				customEndTime: null,
				scheduleId: null
			});
		} else {
			const removingNotification = notificationPreference.notifications
				.splice(messageTypeNotificationIndex, 1)
				.pop();

			if (removingNotification) {
				//Eagerly remove
				this.setState({ notificationPreferences });

				await this.myProfileHttp.deleteNotification({
					userNotificationId: removingNotification.userNotificationId
				});
			}
		}

		this.setState({ isSubmitting: false });
	}

	/**
	 * Given a NotificationPreferenceIndexResponse, render a row representing it's preferences
	 * @param notificationPreference
	 */
	public renderNotificationPreference(
		notificationPreference: NotificationPreferenceIndexResponse
	) {
		const { isSubmitting, notificationTypes } = this.state;
		const eventType = notificationTypes.find(
			(nt) =>
				nt.messageEventTypeId === notificationPreference.messageEventTypeId
		);

		if (!eventType) {
			return <p>Unsupported Event type</p>;
		}

		const possibleEmailFrequencies: NotificationFrequency[] = [
			...(eventType.isInstant ? [NotificationFrequency.Instant] : []),
			...(eventType.isDaily ? [NotificationFrequency.Daily] : []),
			...(eventType.isWeekly ? [NotificationFrequency.Weekly] : []),
			...(eventType.isMonthly ? [NotificationFrequency.Monthly] : [])
		];

		const preferenceHas = (
			messageType: MessageType,
			frequency?: NotificationFrequency
		) => {
			return notificationPreference.notifications.some((notification) => {
				if (notification.messageTypeId !== messageType) {
					return false;
				}

				if (!frequency) {
					return true;
				}

				return notification.userNotificationFrequencyId === frequency;
			});
		};

		return (
			<section className="pt-2 border-top">
				<header>
					<h6>
						<b className="text-muted">{eventType.summary}</b>
					</h6>
				</header>
				<section>
					<article className="d-md-flex">
						{Boolean(possibleEmailFrequencies.length) && (
							<Form.Group className="d-flex mb-3">
								<Form.Label className="mb-0 align-self-center">
									Email:
								</Form.Label>
								<ButtonGroup>
									{possibleEmailFrequencies.map((possibleEmailFrequency) => (
										<CheckboxButton
											key={possibleEmailFrequency}
											name={this.getFrequencyName(possibleEmailFrequency)}
											active={preferenceHas(
												MessageType.Email,
												possibleEmailFrequency
											)}
											onClick={() =>
												this.toggleNotificationPreference(
													notificationPreference,
													MessageType.Email,
													possibleEmailFrequency
												)
											}
											disabled={isSubmitting}
										/>
									))}
								</ButtonGroup>
							</Form.Group>
						)}
						{Boolean(eventType.isPin) && (
							<Form.Group className="d-flex mb-3 ms-auto">
								<Form.Label className="mb-0 align-self-center">
									Header:
								</Form.Label>
								<ButtonGroup>
									<CheckboxButton
										name="Pin"
										active={preferenceHas(MessageType.Firebase)}
										onClick={() =>
											this.toggleNotificationPreference(
												notificationPreference,
												MessageType.Firebase,
												NotificationFrequency.Instant
											)
										}
										disabled={isSubmitting}
									/>
								</ButtonGroup>
							</Form.Group>
						)}
					</article>
				</section>
			</section>
		);
	}

	/**
	 * Given a Product, render all notification types with given Product
	 * @param product
	 */
	public renderNotificationPreferences(product: ProductId) {
		const { notificationPreferences } = this.state;
		const notificationPreferencesByProduct = notificationPreferences.filter(
			(np) => np.productId === product
		);
		const productName = this.getProductIdName(product);

		return (
			<section className="mb-4">
				<header>
					<h5>{productName} Preferences</h5>
				</header>
				{notificationPreferencesByProduct.map(
					(notificationPreferenceByProduct) => (
						<React.Fragment
							key={notificationPreferenceByProduct.messageEventTypeId}
						>
							{this.renderNotificationPreference(
								notificationPreferenceByProduct
							)}
						</React.Fragment>
					)
				)}
			</section>
		);
	}

	public render() {
		const { notificationPreferences, isLoading } = this.state;

		if (isLoading) {
			return <Loading />;
		}

		const notificationPreferencesGroupedByProduct = groupBy(
			notificationPreferences,
			(np) => np.productId
		);
		const availableProducts = Object.keys(
			notificationPreferencesGroupedByProduct
		).map((productStr) => Number(productStr) as ProductId);

		return (
			<Row>
				<Col lg={10} xl={8}>
					<Card body>
						{availableProducts.map((availableProduct) => (
							<React.Fragment key={availableProduct}>
								{this.renderNotificationPreferences(availableProduct)}
							</React.Fragment>
						))}
						<FormErrors error={this.state.error} />
					</Card>
				</Col>
			</Row>
		);
	}
}
