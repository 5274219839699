import { RtUiMenuPriority, RtUiModule } from 'RtUi/app/@RtUi/RtUiDecorators';
import { MyProfileContainer } from './MyProfile/MyProfileContainer';
import { EmailVerifyContainer } from './EmailVerifyContainer';
import { LoginContainer } from './LoginContainer';
import { LogoutContainer } from './LogoutContainer';
import { ResetPasswordContainer } from './ResetPasswordContainer';
import { SendResetPasswordContainer } from 'RtUi/app/user/SendResetPasswordContainer';
import { NewUserContainer } from 'RtUi/app/user/NewUserContainer';

@RtUiModule({
	route: {
		iconClass: 'fa-user',
		name: 'User',
		defaultOpen: true,
		orderPriority: RtUiMenuPriority.RT_BOTTOM
	},
	controllers: [
		MyProfileContainer,
		LoginContainer,
		LogoutContainer,
		NewUserContainer,
		EmailVerifyContainer,
		ResetPasswordContainer,
		SendResetPasswordContainer
	]
})
export class RtUserModule {}
