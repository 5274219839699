import { RtCommonApiRoutes } from 'RtExports/routes';
import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';

class RtReportsRouterClass extends RtUiRouter<void, void, {}> {
	constructor() {
		super('', 'Reports', 'reportDetails', undefined, {});

		this.setPermissionsFromApiRoute(RtCommonApiRoutes.Reports);
	}

	/**
	 * @override
	 */
	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(): string {
		return '';
	}
}

export const RtReportsRouter = new RtReportsRouterClass();
