import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

export interface ProductDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const ProductDataGridColumn = <T = any,>({
	...config
}: ProductDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const colors = [
		'dark',
		'light',
		'secondary',
		'primary',
		'info',
		'danger',
		'warning',
		'success'
	];

	const getColor = (value: string) => {
		if (!colorIndex[value]) {
			colorIndex[value] = colors.pop() || 'primary';
		}

		return colorIndex[value];
	};

	const colorIndex: Record<string, string> = {};

	const renderValue = (value: string) => {
		return (
			<section>
				<div
					role="alert"
					style={{ textAlign: 'center' }}
					className={`w-100 mb-0 p-0 alert alert-${getColor(value)}`}
				>
					<span>{value}</span>
				</div>
			</section>
		);
	};

	return DefaultDataGridColumn({
		...config,
		getValue: ({ cell }) => renderValue(cell.getValue<string>())
	});
};
