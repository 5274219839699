import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

export interface RtVueReportTypeDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const RtVueReportTypeDataGridColumn = <T = any,>({
	...config
}: RtVueReportTypeDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const renderValue = (value: string) => {
		let label = value;
		let theme: string = 'success';

		switch (value) {
			case 'asr':
				label = 'ASR';
				theme = 'success';
				break;
			case 'ports':
				label = 'Ports';
				theme = 'warning';
				break;
			case 'cdrs':
				label = 'CDRs';
				theme = 'danger';
				break;
			case 'tollfreeEnhanced':
				label = 'TF Enhanced';
				theme = 'info';
				break;
			case 'usageAuditByDay':
				label = 'Usage Audit';
				theme = 'primary';
				break;
			case 'financial':
				label = 'Financial';
				theme = 'secondary';
				break;
			case 'cps':
				label = 'CPS';
				theme = 'light';
				break;
			case 'numbers':
				label = 'Numbers';
				theme = 'dark';
				break;
			case 'rejects':
				label = 'Rejects';
				theme = '#ff0000';
				break;
		}

		return (
			<section>
				<div
					role="alert"
					style={{ textAlign: 'center' }}
					className={`w-100 mb-0 p-0 alert alert-${theme}`}
				>
					<span>{label}</span>
				</div>
			</section>
		);
	};

	return DefaultDataGridColumn({
		...config,
		getValue: ({ cell }) => renderValue(cell.getValue<string>())
	});
};
