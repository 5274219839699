import { useQuery } from 'react-query';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	handleGetRequest,
	RequestResponse
} from 'RtUi/utils/http/AxiosHttpRequest';
import {
	RatePlanDefaultIndexRequest,
	RatePlanDefaultIndexResponse
} from 'RtModels';
import { RtCommonApiRoutes } from 'RtExports/routes';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';

const fetchRatePlanDefaults = async (
	params?: RatePlanDefaultIndexRequest
): Promise<RatePlanDefaultIndexResponse[]> => {
	const urlParams = params?.ratePlanId ? { ratePlanId: params.ratePlanId } : {};
	const queryParams = { pageSize: params?.pageSize, page: params?.page };
	return handleGetRequest(RtCommonApiRoutes.RatePlanDefaults.Index, {
		urlParams,
		queryParams
	});
};

export const useGetRatePlanDefaults = <
	IncludeFullResponse extends boolean = false
>(
	queryParams?: RatePlanDefaultIndexRequest,
	includeFullResponse?: IncludeFullResponse
) => {
	return useQuery<RatePlanDefaultIndexResponse[], Error>(
		['getRatePlanDefaults', queryParams, includeFullResponse],
		() => {
			return fetchRatePlanDefaults(queryParams);
		}
	);
};

export const useRatePlanDefaults = <
	IncludeFullResponse extends boolean = false
>(
	queryParams?: RatePlanDefaultIndexRequest,
	includeFullResponse?: IncludeFullResponse
) => {
	return useQuery<
		RequestResponse<RatePlanDefaultIndexResponse[], IncludeFullResponse>,
		Error
	>([`useGetRatePlanDefaults`, queryParams, includeFullResponse], {
		enabled: true
	});
};

export const getRatePlanDefaultColumns = (): Array<
	DataGridColumn<RatePlanDefaultIndexResponse>
> => {
	const columns: Array<DataGridColumn<RatePlanDefaultIndexResponse>> = [
		IdDataGridColumn({
			accessorKey: 'ratePlanDefaultId',
			header: 'Id'
		}),
		TimeStampDataGridColumn({
			accessorKey: 'startTs',
			header: 'Start Date'
		}),
		IdDataGridColumn({
			accessorKey: 'ratePlanId',
			header: 'Rate Plan Id'
		}),
		BooleanDataGridColumn({
			accessorKey: 'isActive',
			header: 'Active'
		}),
		DefaultDataGridColumn({
			accessorKey: 'ratedRegionId',
			header: 'Rated Region'
		}),
		DefaultDataGridColumn({
			accessorKey: 'ratedIso',
			header: 'Rated Country'
		}),
		DefaultDataGridColumn({
			accessorKey: 'ratedExt',
			header: 'Rated Ext'
		}),
		DefaultDataGridColumn({
			accessorKey: 'jurisRegionId',
			header: 'Juris Region'
		}),
		DefaultDataGridColumn({
			accessorKey: 'jurisIso',
			header: 'Juris Country'
		}),
		DefaultDataGridColumn({
			accessorKey: 'jurisExt',
			header: 'Juris Ext'
		})
	];

	return columns;
};
