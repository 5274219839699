import { RtVueApiRoutes } from 'RtExports/routes';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import {
	GuardianUserQueryIndexResponse,
	GuardianUserQueryIndexRequest,
	GuardianUserQueryScheduleCreateRequest,
	GuardianUserQueryScheduleUpdateRequest,
	GuardianUserQueryScheduleProfileResponse,
	ScheduleProfileResponse,
	GuardianUserQueryScheduleUrlRequest
} from 'RtModels';

export class GuardianUserQueryScheduleResource extends HttpResource<
	GuardianUserQueryIndexResponse,
	GuardianUserQueryIndexResponse,
	GuardianUserQueryIndexRequest
> {
	public static IsQueryGlobalOrReferenceGlobal(
		query?: GuardianUserQueryIndexResponse
	) {
		if (typeof query === 'undefined') {
			return false;
		}

		return (
			query.isGlobal === 1 ||
			typeof query.globalGuardianUserQueryId === 'number'
		);
	}

	constructor() {
		super(RtVueApiRoutes.GuardianUserQueries);
	}
	public async deleteSchedule(
		params: GuardianUserQueryScheduleUrlRequest
	): Promise<void> {
		return this.fetchWithRoute<void>(
			RtVueApiRoutes.GuardianUserQueriesSchedule.Delete,
			{
				urlParams: params
			}
		);
	}

	public async updateSchedule(
		params: GuardianUserQueryScheduleUrlRequest,
		schedule: GuardianUserQueryScheduleUpdateRequest
	): Promise<ScheduleProfileResponse> {
		const body = schedule;
		const bodyStr = JSON.stringify(body);

		return this.fetchWithRoute<ScheduleProfileResponse>(
			RtVueApiRoutes.GuardianUserQueriesSchedule.Update,
			{
				urlParams: params,
				body: bodyStr
			}
		);
	}

	public async createSchedule(
		urlParams: GuardianUserQueryScheduleUrlRequest,
		schedule: GuardianUserQueryScheduleCreateRequest
	): Promise<GuardianUserQueryScheduleProfileResponse> {
		let scheduleRecord: GuardianUserQueryScheduleProfileResponse;

		scheduleRecord =
			await this.fetchWithRoute<GuardianUserQueryScheduleProfileResponse>(
				RtVueApiRoutes.GuardianUserQueriesSchedule.Create,
				{
					urlParams: urlParams,
					body: JSON.stringify(schedule)
				}
			);

		if (scheduleRecord.scheduleId) {
			scheduleRecord =
				await this.fetchWithRoute<GuardianUserQueryScheduleProfileResponse>(
					RtVueApiRoutes.GuardianUserQueriesSchedule.Update,
					{
						urlParams: urlParams,
						body: JSON.stringify(schedule)
					}
				);
		}

		return scheduleRecord;
	}
}
