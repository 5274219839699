import { darken, Button } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface INavButtonArrowProps {
	isActive: boolean;
	isOpen: boolean;
	handleToggle: () => void;
}

export default function NavButtonArrow({
	isActive,
	isOpen,
	handleToggle
}: INavButtonArrowProps) {
	const bgColor = isActive ? '#394470' : '#fff';
	const color = isActive ? '#fff' : '#000';

	return (
		<Button
			size="small"
			aria-controls={isOpen ? 'split-button-menu' : undefined}
			aria-expanded={isOpen ? 'true' : undefined}
			aria-haspopup="menu"
			onClick={handleToggle}
			sx={{
				'background': `${bgColor}`,
				'textTransform': 'none',
				'color': `${color}`,
				':hover': {
					background: darken(bgColor, 0.1),
					color: `${color}`
				},
				'borderRadius': 2
			}}
		>
			<ArrowDropDownIcon />
		</Button>
	);
}
