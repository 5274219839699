import { Control, UseFormSetValue, FieldValues } from 'react-hook-form';
import { Integrations, ResourceConfigurationProfileResponse } from 'RtModels';
import {
	AccountIdInputField,
	AccountInputField,
	AccountNumberInputField,
	AllowedRespOrgIdInputField,
	AreaOfServiceInputField,
	BanInputField,
	BillingAccountNumberInputField,
	BillingTelephoneNumberInputField,
	CorpSrpInputField,
	CustomerIdInputField,
	CustomerNumberInputField,
	DirectoryInputField,
	EmailAddressInputField,
	DidCustomerNumberInputField,
	LumenCsnInputField,
	LumenProductIdInputField,
	PayPhoneBlockInputField,
	PeerIdInputField,
	PopInputField,
	PrimaryContactEmailInputField,
	PrimaryContactNameInputField,
	PrimaryContactPhoneInputField,
	RelatedPartyIdInputField,
	RelatedPartyNameInputField,
	ResponseTypeInputField,
	RespOrgIdInputField,
	RouteLabelInputField,
	RouteNameInputField,
	RoutingInputField,
	RoutingLabelInputField,
	RoutingNameInputField,
	SecondaryContactEmailInputField,
	SecondaryContactNameInputField,
	SecondaryContactPhoneInputField,
	ServiceTypeIdInputField,
	SiteIdInputField,
	SmsConfigIdInputField,
	SwitchIdInputField,
	TranslateBtnInputField,
	TrunkGroupIdInputField,
	TrunkGroupInputField,
	TrunkGroupNameInputField,
	TrunkIdInputField,
	VerizonFusfExemptionDropdown,
	VerizonTollFreeTypeDropdown,
	VersionInputField,
	ParentCustomerNumberInputField
} from 'RtUi/app/rtCommon/Resources/lib/forms/ResourceSpecificInputs';
import { CollapsibleCard } from 'RtUi/components/ui/CollapsibleCard';

interface IBuildResourceSpecificFields {
	control: Control;
	integrationId: number;
	setValue: UseFormSetValue<FieldValues>;
	disabled: boolean;
	editMode?: ResourceConfigurationProfileResponse;
	type: string;
}

export const BuildResourceSpecificFieldsArray = (
	props: IBuildResourceSpecificFields
) => {
	const { integrationId, control, setValue, disabled } = props;

	switch (integrationId) {
		case Integrations.Layered:
		case Integrations.Telnyx:
		case Integrations.Twilio:
		case Integrations.VoipInnovations:
		case Integrations.ThreeEightTwo:
		case Integrations.Plivo:
		case Integrations.Endstream:
		case Integrations.MyHealthAngel:
		case Integrations.RouteTrustTagging:
		case Integrations.V1Voip:
		case Integrations.Flowroute:
		case Integrations.TeleApi:
		case Integrations.Vitelity:
		case Integrations.Fractel:
		case Integrations.ThreeThree:
		case Integrations.Skyetel:
		case Integrations.Call48:
		case Integrations.DidWW:
		case Integrations.Avoxi:
			return <></>;

		case Integrations.QuestBlue:
			return (
				<>
					<TrunkGroupInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
				</>
			);

		case Integrations.West:
			return (
				<>
					<TrunkGroupInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
				</>
			);

		case Integrations.Vitcom:
			return (
				<>
					<AccountNumberInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<TrunkIdInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
				</>
			);
		case Integrations.Blitz:
			return (
				<RouteLabelInputField
					disabled={disabled}
					required={true}
					key={Math.random()}
					control={control}
				/>
			);

		case Integrations.Bandwidth:
			return (
				<>
					<AccountInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<SiteIdInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<PeerIdInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
				</>
			);

		case Integrations.BlacklistAlliance:
			return (
				<>
					<ResponseTypeInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<VersionInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
				</>
			);

		case Integrations.Inteliquent:
			switch (props.type) {
				case 'tf':
					return (
						<>
							<RoutingLabelInputField
								disabled={disabled}
								required={true}
								key={Math.random()}
								control={control}
							/>
							<RespOrgIdInputField
								disabled={disabled}
								required={true}
								key={Math.random()}
								control={control}
							/>
							<AllowedRespOrgIdInputField
								disabled={disabled}
								required={true}
								key={Math.random()}
								control={control}
							/>
						</>
					);
				case 'did':
					return (
						<>
							<TrunkGroupInputField
								disabled={disabled}
								required={true}
								key={Math.random()}
								control={control}
							/>
						</>
					);
			}
			break;

		case Integrations.Peerless:
			return (
				<>
					<RouteLabelInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<CustomerIdInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
				</>
			);

		case Integrations.Thinq:
			return (
				<>
					<AccountInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
				</>
			);

		case Integrations.VoipMs:
			return (
				<>
					<RoutingInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					,
					<PopInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
				</>
			);

		case Integrations.Lingo:
			setValue('configuration.switchId', '90');
			setValue('configuration.serviceTypeId', '9');
			return (
				<>
					<AccountNumberInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<SwitchIdInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<DirectoryInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<TrunkGroupIdInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<ServiceTypeIdInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
				</>
			);
		case Integrations.Verizon:
			return (
				<>
					<VerizonTollFreeTypeDropdown
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<VerizonFusfExemptionDropdown
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<AccountNumberInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<RespOrgIdInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<AllowedRespOrgIdInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<TrunkGroupNameInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<BanInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<CorpSrpInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<RelatedPartyIdInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<RelatedPartyNameInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<AreaOfServiceInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<PrimaryContactNameInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<PrimaryContactEmailInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<PrimaryContactPhoneInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<SecondaryContactNameInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<SecondaryContactEmailInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<SecondaryContactPhoneInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
				</>
			);

		case Integrations.LumenApi:
			return (
				<>
					<RespOrgIdInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<AllowedRespOrgIdInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<ParentCustomerNumberInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<EmailAddressInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<CollapsibleCard
						className="ps-0 mt-2"
						header="TollFree Options"
						useCardBody={false}
						headerIcon={'fas fa-fw fa-phone text-success'}
					>
						<BillingAccountNumberInputField
							disabled={disabled}
							required={false}
							key={Math.random()}
							control={control}
						/>
						<CustomerNumberInputField
							disabled={disabled}
							required={false}
							key={Math.random()}
							control={control}
						/>
						<AreaOfServiceInputField
							disabled={disabled}
							required={false}
							key={Math.random()}
							control={control}
						/>
						<BillingTelephoneNumberInputField
							disabled={disabled}
							required={false}
							key={Math.random()}
							control={control}
						/>
						<RouteNameInputField
							disabled={disabled}
							required={false}
							key={Math.random()}
							control={control}
						/>
					</CollapsibleCard>
					<CollapsibleCard
						className="mb-3"
						header="DID Options"
						useCardBody={false}
						headerIcon={'fas fa-fw fa-phone text-success'}
					>
						<LumenProductIdInputField
							disabled={disabled}
							required={false}
							key={Math.random()}
							control={control}
						/>
						<DidCustomerNumberInputField
							disabled={disabled}
							required={false}
							key={Math.random()}
							control={control}
						/>
						<LumenCsnInputField
							disabled={disabled}
							required={false}
							key={Math.random()}
							control={control}
						/>
					</CollapsibleCard>
				</>
			);

		case Integrations.Nuso:
			return (
				<>
					<TrunkIdInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<AccountIdInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<SmsConfigIdInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
				</>
			);

		case Integrations.AniNetworks:
			return (
				<>
					<AccountIdInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<RespOrgIdInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<AllowedRespOrgIdInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<TranslateBtnInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<RoutingNameInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
					<PayPhoneBlockInputField
						disabled={disabled}
						required={true}
						key={Math.random()}
						control={control}
					/>
				</>
			);

		default:
			return <></>;
	}

	return <></>;
};
