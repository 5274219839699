import {
	DataGridColumn,
	RowThemeColor
} from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

export interface PillDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	themeValues: Record<string, RowThemeColor>;
	defaultTheme: RowThemeColor;
}

export const PillDataGridColumn = <T = any,>({
	...config
}: PillDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const renderValue = (value: string) => {
		let theme = config.defaultTheme;
		if (config.themeValues[value]) {
			theme = config.themeValues[value];
		}

		return (
			<section className="d-flex">
				<div
					role="alert"
					style={{ textAlign: 'center' }}
					className={`alert-sm w-100 mb-0 p-0 alert alert-${theme}`}
				>
					<span>{value}</span>
				</div>
			</section>
		);
	};

	return DefaultDataGridColumn({
		...config,
		getValue: ({ cell }) => renderValue(cell.getValue<string>())
	});
};
