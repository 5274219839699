import moment from 'moment';
import { FC, FormEvent, useState } from 'react';
import {
	GuardianAlertScheduleCreateRequest,
	GuardianAlertScheduleProfileResponse
} from 'RtModels';
import { AlertScheduleResource } from 'RtUi/app/rtGuardian/AlertRules/lib/resources/AlertScheduleResource';
import { TimeFormControl } from 'RtUi/app/rtVue/common/lib/components/DateTimeFilterRange/TimeFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { MultipleCheckboxFormControl } from 'RtUi/components/form/MultipleCheckboxFormControl';
import {} from // ITimeZoneResourceOption,
// TimeZoneSelect
'RtUi/components/form/TimeZoneSelect';
import { useRtUiFormEditor } from 'RtUi/components/hooks/useRtUiFormEditor';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';

interface IAlertsScheduleEditorProps {
	onSuccess?: () => void;
	onCancel?: () => void;
	editMode?: GuardianAlertScheduleProfileResponse;
	createMode: boolean;
	guardianAlertId: number;
}

const getDaysOfWeek = (workingCopy: GuardianAlertScheduleCreateRequest) => {
	const daysOfWeek = [];

	if (workingCopy.isSunday) {
		daysOfWeek.push('SU');
	}

	if (workingCopy.isMonday) {
		daysOfWeek.push('M');
	}

	if (workingCopy.isTuesday) {
		daysOfWeek.push('TU');
	}

	if (workingCopy.isWednesday) {
		daysOfWeek.push('W');
	}

	if (workingCopy.isThursday) {
		daysOfWeek.push('TH');
	}

	if (workingCopy.isFriday) {
		daysOfWeek.push('F');
	}

	if (workingCopy.isSaturday) {
		daysOfWeek.push('SA');
	}

	return daysOfWeek;
};

export const AlertsScheduleEditor: FC<
	React.PropsWithChildren<IAlertsScheduleEditorProps>
> = ({
	onSuccess = () => ({}),
	onCancel = () => ({}),
	editMode,
	createMode,
	guardianAlertId
}) => {
	const resource = new AlertScheduleResource();
	const [rtUiFormState, rtUiFormMethods] = useRtUiFormEditor({
		createMode,
		editMode,
		createNew: () => resource.createNewProfile(guardianAlertId)
	});
	const { workingCopy } = rtUiFormState;
	// const [timeZone, setTimeZone] = useState<ITimeZoneResourceOption>();
	const [startTime, setStartTime] = useState<moment.Moment>(
		moment(workingCopy.startTime, 'HH:mm')
	);
	const [endTime, setEndTime] = useState<moment.Moment>(
		moment(workingCopy.endTime, 'HH:mm')
	);

	const [daysOfWeek, setDaysOfWeek] = useState<string[]>(
		getDaysOfWeek(workingCopy)
	);

	const onSubmit = async (evt: FormEvent<HTMLFormElement>) => {
		evt.preventDefault();

		rtUiFormMethods.setError(undefined);
		rtUiFormMethods.setIsSubmitting(true);

		try {
			if (editMode) {
				await resource.update(editMode.guardianAlertScheduleId, workingCopy);
			} else {
				await resource.create(workingCopy);
			}
			rtUiFormMethods.setIsSubmitting(false);
			onSuccess();
		} catch (err) {
			rtUiFormMethods.setIsSubmitting(false);
			rtUiFormMethods.setError(err);
		}
	};

	return (
		<RtUiForm
			{...rtUiFormState}
			displayMode={false}
			onSubmit={onSubmit}
			onCancel={() => {
				rtUiFormMethods.onCancel();
				onCancel();
			}}
		>
			<MultipleCheckboxFormControl
				options={[
					{ value: 'M', label: 'Mon' },
					{ value: 'TU', label: 'Tue' },
					{ value: 'W', label: 'Wed' },
					{ value: 'TH', label: 'Thu' },
					{ value: 'F', label: 'Fri' },
					{ value: 'SA', label: 'Sat' },
					{ value: 'SU', label: 'Sun' }
				]}
				value={daysOfWeek}
				label="Scheduled Run Time"
				onChange={(daysOfWeek) => {
					setDaysOfWeek(daysOfWeek);
					rtUiFormMethods.setWorkingCopy({
						isSunday: Number(daysOfWeek.includes('SU')),
						isMonday: Number(daysOfWeek.includes('M')),
						isTuesday: Number(daysOfWeek.includes('TU')),
						isWednesday: Number(daysOfWeek.includes('W')),
						isThursday: Number(daysOfWeek.includes('TH')),
						isFriday: Number(daysOfWeek.includes('F')),
						isSaturday: Number(daysOfWeek.includes('SU'))
					});
				}}
			/>
			{/* <TimeZoneSelect
				label="Time Zone"
				onChange={(timeZone) => {
					setTimeZone(timeZone);
					rtUiFormMethods.setWorkingCopy({
						timezone: timeZone?.timeZone
					});
				}}
				value={timeZone}
				initialOptionId={workingCopy.timezone ? workingCopy.timezone : 'UTC'}
			/> */}
			<TimeFormControl
				label="Start Time"
				timeValue={startTime}
				onTimeChange={(startTime) => {
					setStartTime(startTime);
					rtUiFormMethods.setWorkingCopy({
						startTime: startTime.utc().format('HH:mm')
					});
				}}
				hideFormGroup={true}
				formControl
			/>
			<TimeFormControl
				label="End Time"
				timeValue={endTime}
				onTimeChange={(endTime) => {
					setEndTime(endTime);
					rtUiFormMethods.setWorkingCopy({
						endTime: endTime.utc().format('HH:mm')
					});
				}}
				hideFormGroup={true}
				formControl
			/>
			<IsActiveRadioFormControl
				label="Is Active"
				onChange={(isActive) => {
					rtUiFormMethods.setWorkingCopy({
						isActive
					});
				}}
				hideBothOption
				value={workingCopy.isActive}
			/>
		</RtUiForm>
	);
};
