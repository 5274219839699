import {
	BillingEntityIndexResponse,
	PartitionEntityIndexResponse,
	PartitionIndexResponse,
	PartitionRespOrgIndexResponse,
	PartitionSipGateway,
	RatePlanIndexResponse,
	UrlPartition
} from 'RtModels';
import { EntityIdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/EntityIdDataGridColumn';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { DeleteDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DeleteDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';

export const getPartitionColumns = (): Array<
	DataGridColumn<PartitionIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'partitionId'
	}),
	DefaultDataGridColumn({
		accessorKey: 'summary',
		header: 'Summary'
	}),
	DefaultDataGridColumn({
		accessorKey: 'prefix',
		header: 'Prefix'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isRt800',
		header: 'Rt/800'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isRtDid',
		header: 'Rt/DID'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isRtSip',
		header: 'Rt/SIP'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isRtLco',
		header: 'Rt/LCO'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isRtVue',
		header: 'Rt/VUE'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isRtCarrierConnect',
		header: 'Rt/CarrierConnect'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isRtLcr',
		header: 'Rt/LCR'
	}),
	IntegerDataGridColumn({
		accessorKey: 'inboundPortLimit',
		header: 'Inbound Port Limit'
	}),
	DefaultDataGridColumn({
		accessorKey: 'prefixLong',
		header: 'Prefix Long'
	}),
	DefaultDataGridColumn({
		accessorKey: 'altCommonName',
		header: 'Alt Name'
	})
];

export const getPartitionUrlColumns = (): Array<
	DataGridColumn<UrlPartition>
> => [
	IdDataGridColumn({
		accessorKey: 'urlPartitionId'
	}),
	DefaultDataGridColumn({
		accessorKey: 'url',
		header: 'URL'
	}),
	IntegerDataGridColumn({
		accessorKey: 'partitionId',
		header: 'Partition ID'
	}),
	DefaultDataGridColumn({
		accessorKey: 'logo',
		header: 'Logo'
	}),
	DefaultDataGridColumn({
		accessorKey: 'siteTitle',
		header: 'Site Title'
	}),
	BooleanDataGridColumn({
		accessorKey: 'allowSiteSearch',
		header: 'Allow Site Search'
	}),
	DefaultDataGridColumn({
		accessorKey: 'loginTitle',
		header: 'Login Title'
	}),
	DefaultDataGridColumn({
		accessorKey: 'forgotPasswordTitle',
		header: 'Forgot Password Title'
	}),
	DefaultDataGridColumn({
		accessorKey: 'loginLogo',
		header: 'Login Logo'
	}),
	BooleanDataGridColumn({
		accessorKey: 'forceSsl',
		header: 'Force SSL'
	})
];

export const getPartitionBillingColumns = (): Array<
	DataGridColumn<BillingEntityIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'billingEntityId'
	}),
	DefaultDataGridColumn({
		accessorKey: 'companyName',
		header: 'Company Name'
	}),
	DefaultDataGridColumn({
		accessorKey: 'summary',
		header: 'Summary'
	}),
	DefaultDataGridColumn({
		accessorKey: 'address',
		header: 'Address'
	}),
	DefaultDataGridColumn({
		accessorKey: 'disputeEmail',
		header: 'Dispute Email'
	}),
	DefaultDataGridColumn({
		accessorKey: 'pdfEmailName',
		header: 'PDF Email Name'
	}),
	DefaultDataGridColumn({
		accessorKey: 'pdfEmailAddress',
		header: 'PDF Email Address'
	})
];

export const getPartitionSipGatewaysColumns = (): Array<
	DataGridColumn<PartitionSipGateway>
> => [
	IdDataGridColumn({
		accessorKey: 'partitionSipGatewayId'
	}),
	IdDataGridColumn({
		accessorKey: 'partitionId',
		header: 'Partition Id'
	}),
	IdDataGridColumn({
		accessorKey: 'serverId',
		header: 'Server Id'
	}),
	IntegerDataGridColumn({
		accessorKey: 'listenPort',
		header: 'Listen Port'
	}),
	DefaultDataGridColumn({
		accessorKey: 'codecs',
		header: 'Codecs'
	}),
	IntegerDataGridColumn({
		accessorKey: 'egressPercent',
		header: 'Egress Percent'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isActive',
		header: 'Is Active'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isOnline',
		header: 'Is Online'
	}),
	IntegerDataGridColumn({
		accessorKey: 'gwMajorVersion',
		header: 'Major Version'
	}),
	IntegerDataGridColumn({
		accessorKey: 'gwMinorVersion',
		header: 'Minor Version'
	}),
	IntegerDataGridColumn({
		accessorKey: 'gwPatchVersion',
		header: 'Patch Version'
	}),
	IntegerDataGridColumn({
		accessorKey: 'inboundPortLimit',
		header: 'Inbound Port Limit'
	}),
	IntegerDataGridColumn({
		accessorKey: 'currentInboundCount',
		header: 'Current Inbound Count'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'currentInboundCountTs',
		header: 'Current Inbound Count'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'createdTs',
		header: 'Created'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'updatedTs',
		header: 'Last Updated'
	})
];

export const getPartitionEntitiesColumns = (
	removeEntity: (row: PartitionEntityIndexResponse) => void
): Array<DataGridColumn<PartitionEntityIndexResponse>> => [
	DeleteDataGridColumn({
		onClick: (row) => removeEntity(row)
	}),
	IdDataGridColumn({
		accessorKey: 'partitionEntityId'
	}),
	EntityIdDataGridColumn({
		accessorKey: 'entityId'
	})
];

export const getPartitionRespOrgsColumns = (
	removeEntity: (row: PartitionRespOrgIndexResponse) => void
): Array<DataGridColumn<PartitionRespOrgIndexResponse>> => [
	DeleteDataGridColumn({
		onClick: (row) => removeEntity(row)
	}),
	IdDataGridColumn({
		accessorKey: 'partitionRespOrgId'
	}),
	DefaultDataGridColumn({
		accessorKey: 'respOrgId',
		header: 'RespOrg Id'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isDefault',
		header: 'Default'
	})
];

export const getPartitionRatePlanColumns = (): Array<
	DataGridColumn<RatePlanIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'ratePlanId'
	}),
	DefaultDataGridColumn({
		accessorKey: 'ratePlan',
		header: 'Rate Plan'
	}),
	DefaultDataGridColumn({
		accessorKey: 'summary',
		header: 'Summary'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isActive',
		header: 'Active'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isShared',
		header: 'Shared'
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'subscriptionId',
		header: 'Subscription'
	}),
	IntegerDataGridColumn({
		accessorKey: 'rateKeyDeleteDays',
		header: 'Delete Days'
	}),
	IntegerDataGridColumn({
		accessorKey: 'rateKeyMatchIncreaseDays',
		header: 'Match Increase Days'
	}),
	IntegerDataGridColumn({
		accessorKey: 'rateKeyExpandIncreaseDays',
		header: 'Expand Increase Days'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'lastRateLoadTs',
		header: 'Last Rate Load'
	})
];
