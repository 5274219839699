export enum RtxRegion {
	NanpUs48 = '0',
	NanpCanada = '2',
	NanpUsAlaska = '3',
	NanpCaribbean = '4',
	NanpUsHawaii = '6',
	Nanp9YY = '9',
	NanpNonGeographic = 'B',
	NanpPacific = 'C',
	NanpUnknown = 'X'
}
