import {
	MessagesSummaryIndexResponse,
	Reports,
	TimeFilterDetailKeys,
	TimeRangePresets
} from 'RtModels';
import { MessagesSummaryRouter } from 'RtUi/app/rtVue/MessagesSummary/MessagesSummary.router';
import { MessagesSummaryChart } from 'RtUi/app/rtVue/MessagesSummary/lib/charts/MessagesSummaryChart';
import { MessagesSummaryGrid } from 'RtUi/app/rtVue/MessagesSummary/lib/grids/MessagesSummaryGrid';
import { TimeFilterDetailLevelsHours } from 'RtUi/app/rtVue/common/lib/components/DateTimeFilterRange/utilities';
import { RtVueReportContainer } from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

@MessagesSummaryRouter.getIndexRtUiController({
	groupName: 'Messaging',
	groupId: 'VueMessaging'
})
export class MessagesSummaryIndexContainer extends RtVueReportContainer<MessagesSummaryIndexResponse> {
	public state = {
		...this.getBaseState({
			defaultTimeRangePreset: TimeRangePresets.Week
		}),
		timeRangePresetsKeys: [TimeRangePresets.Week, TimeRangePresets.Custom],
		timeRangeDetailsLevels: TimeFilterDetailLevelsHours.filter(
			(time) => time.key === TimeFilterDetailKeys.Months
		),
		dataSums: []
	};

	protected reportId = Reports.MessagesSummary;
	protected router = MessagesSummaryRouter;

	protected async getResource(): Promise<
		HttpResource<MessagesSummaryIndexResponse>
	> {
		const {
			timeRange,
			filters,
			partition,
			preset,
			dataSums,
			additionalColumns,
			havingFilter
		} = this.state;

		const vueHttp = new RtVueHttp();
		const { data, totalCount } = await vueHttp.getMessagesSummaryView(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			additionalColumns?.map((column) => column.value),
			havingFilter
		);
		const resource = new ArrayResource<MessagesSummaryIndexResponse>(
			'callDate',
			data
		);
		resource.setTotalCount(totalCount);

		return resource;
	}

	protected renderReports(): React.ReactNode {
		return (
			<>
				<MessagesSummaryChart resource={this.state.resource} />
				<MessagesSummaryGrid
					isLoading={this.state.loadingReport}
					callDateFormat={this.getCallDateFormat()}
					defaultSorting={this.state.defaultSorting}
					onSortingChange={this.onSortedChange}
					resource={this.state.resource}
				/>
			</>
		);
	}
}
