import { ProductId } from 'RtModels';

export const ProductIdToNameMap = new Map<ProductId, string>([
	[ProductId.RT_800, 'RT/800'],
	[ProductId.RT_CARRIER_CONNECT, 'RT/800 Carrier Connect'],
	[ProductId.RT_LCO, 'RT/800 Least Cost Origination'],
	[ProductId.RT_SIP, 'RT/SIP'],
	[ProductId.RT_DID, 'RT/DID'],
	[ProductId.RT_VUE, 'RT/VUE'],
	[ProductId.RT_ADM, 'RT/Adm'],
	[ProductId.RT_COMMON, 'RT/Common']
]);
