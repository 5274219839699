import { Permissions } from 'RtExports/routes';
import { RtxReportId } from 'RtModels';

export enum ReportRouteGroup {
	NetworkReports = 'Network Reports'
}

interface IReportBaseConfig {
	reportId: RtxReportId;
	reportName: string;
	reportGroupName: ReportRouteGroup;
	reportSubGroupName?: string;
}

interface IRequiredReportConfig extends IReportBaseConfig {
	isReport: true;
}

interface IOptionalReportConfig extends Partial<IReportBaseConfig> {
	isReport?: false;
}

export type IReportRouteConfig = IRequiredReportConfig | IOptionalReportConfig;

export type IPermissionRoute = IReportRouteConfig & {
	name: string;
	path: string;
	description?: string;
	permissions?: Permissions[];
	hideMdAndAbove?: boolean;
	isHidden?: true;
	groupName?: string;
	orderPriority?: number;
	groupId?: string;
	groupOrderPriority?: number;
};

export interface IRouteMenuBase {
	iconClass: string;
	name: string;
	singleMode?: boolean;
	defaultOpen?: boolean;
	orderPriority: number;
	doNotExpandChildren?: boolean;
}

export interface IRouteMenu extends IRouteMenuBase {
	routes: IPermissionRoute[];
}
