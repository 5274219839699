import { ReportsIndexResponse } from 'RtModels';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';
import { ProductDataGridColumn } from 'RtUi/components/data/DataGrid/columns/ProductDataGridColumn';
import { RtVueReportTypeDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RtVueReportTypeDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';

export const getReportsColumns = (): Array<
	DataGridColumn<ReportsIndexResponse>
> => {
	return [
		LabelDataGridColumn({
			accessorKey: 'label'
		}),
		ProductDataGridColumn({
			accessorKey: 'product',
			header: 'Product',
			isHidden: true
		}),
		RtVueReportTypeDataGridColumn({
			accessorKey: 'reportType',
			header: 'Type'
		}),
		LabelDataGridColumn({
			accessorKey: 'description',
			header: 'Summary'
		}),
		DefaultDataGridColumn({
			accessorKey: 'source',
			header: 'Source'
		}),
		DefaultDataGridColumn({
			accessorKey: 'user',
			header: 'User',
			size: 140
		}),
		TimeStampDataGridColumn({
			accessorKey: 'createdTs',
			header: 'Created'
		})
	];
};
