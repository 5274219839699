import { RatePlanRouter } from 'RtUi/app/AccountManagement/RatePlan/RatePlan.router';
import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { RatePlanIndexRequest, SubscriptionIndexResponse } from 'RtModels';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { RatePlanDataGrid } from 'RtUi/app/AccountManagement/RatePlan/lib/grid/RatePlanGrid';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { isNil } from 'lodash-es';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { RtCommonApiRoutes } from 'RtExports/routes';
import { PartitionRatePlanEditor } from 'RtUi/app/rtAdmin/Partitions/lib/forms/PartitionRatePlanEditor';
import { RtxUiPageSizes } from 'RtUi/RtxUiConstants';

interface IRatePlanIndexContainerState
	extends ISearchResultsContainerState<RatePlanIndexRequest> {
	summary?: string;
	ratePlan?: string;
	isShared?: number;
	isActive?: number;
	subscription?: SubscriptionIndexResponse;
}

export class RatePlanIndexContainer extends SearchResultsContainer<
	{},
	{},
	IRatePlanIndexContainerState,
	RatePlanIndexRequest
> {
	public initParams = { isShared: -1, isActive: 1 };

	public state: IRatePlanIndexContainerState = {
		...this.initParams,
		activeTab: this.tabs.Results,
		resourceParams: {}
	};

	constructor(props: {}) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams() {
		const { ratePlan, summary, isShared, isActive, subscription } = this.state;
		const params: RatePlanIndexRequest = {};

		if (!isNil(ratePlan)) {
			params.ratePlan = ratePlan;
		}

		if (!isNil(summary)) {
			params.summary = summary;
		}

		if (isShared !== -1) {
			params.isShared = isShared;
		}

		if (isActive !== -1) {
			params.isActive = isActive;
		}

		if (!isNil(subscription)) {
			params.subscriptionId = subscription.subscriptionId;
		}

		return params;
	}

	public render() {
		return (
			<TabbedLayout
				activeTab={this.state.activeTab}
				router={RatePlanRouter}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm onSubmit={(e) => this.submitAndGoToResults(e)}>
						<InputFormControl
							label="Rate plan"
							onChange={(ratePlan) => this.setState({ ratePlan })}
							value={this.state.ratePlan}
						/>
						<InputFormControl
							label="Summary"
							onChange={(summary) => this.setState({ summary })}
							value={this.state.summary}
						/>
						<BooleanRadioFormControl
							label="Active"
							showBothOption
							onChange={(isActive) => this.setState({ isActive })}
							value={this.state.isActive}
						/>
						<BooleanRadioFormControl
							label="Shared"
							showBothOption
							onChange={(isShared) => this.setState({ isShared })}
							value={this.state.isShared}
						/>
						<SubscriptionSelect
							label="Subscription"
							value={this.state.subscription}
							isClearable
							onChange={(subscription) =>
								this.setState({ subscription: subscription ?? undefined })
							}
						/>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<RatePlanDataGrid
						resourceParams={{
							...this.state.resourceParams,
							pageSize: RtxUiPageSizes.SoftAll
						}}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab
					rtRoute={RtCommonApiRoutes.RatePlan.Create}
					header="Create Rate Plan"
					isAction
				>
					<PartitionRatePlanEditor
						createMode
						narrow={true}
						onUpdate={(ratePlan) => {
							const profileRoute = RatePlanRouter.getProfileRoute(ratePlan);

							this.goToPath(profileRoute);
						}}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}

RatePlanRouter.setIndexRtUiFunctionalComponent(RatePlanIndexContainer);
