import { AlertHistoryGrid } from 'RtUi/app/rtGuardian/AlertHistory/lib/grids/AlertHistoryGrid';
import { AlertRulesRouter } from 'RtUi/app/rtGuardian/AlertRules/AlertRules.router';
import { AlertRulesProfileSchedulesCustomHeader } from 'RtUi/app/rtGuardian/AlertRules/lib/components/AlertRulesProfileSchedulesCustomHeader';
import { AlertRulesEditor } from 'RtUi/app/rtGuardian/AlertRules/lib/forms/AlertRulesEditor';
import { AlertScheduleGrid } from 'RtUi/app/rtGuardian/AlertRules/lib/grids/AlertScheduleGrid';
import { AlertsRecipientsGrid } from 'RtUi/app/rtGuardian/AlertRules/lib/grids/AlertsRecipientsGrid';
import { useGetGuardianAlertRule } from 'RtUi/app/rtGuardian/AlertRules/lib/services';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';

export const AlertRulesProfileContainer = (): JSX.Element => {
	const [tabs, activeTab, setActiveTab] = useTabs(
		AlertRulesRouter.getProfileTabs()
	);
	const id = useIdParam();
	const {
		data: profile,
		isFetching: isLoading,
		refetch
	} = useGetGuardianAlertRule({ guardianAlertId: Number(id) });

	if (isLoading) {
		return <Loading />;
	}

	if (!profile) {
		return <p>Not found!</p>;
	}

	return (
		<TabbedLayout
			activeTab={activeTab.header}
			onTabChange={(activeTab) => setActiveTab(activeTab)}
			router={AlertRulesRouter}
			profile={profile}
		>
			<TabbedLayoutTab {...tabs.Profile}>
				<AlertRulesEditor editMode={profile} onUpdate={() => refetch()} />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.History}>
				<AlertHistoryGrid
					pageName="rtGuardian-alertRuleHistory"
					resourceParams={{
						guardianAlertHistoryId: profile.guardianAlertId
					}}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Recipients}>
				<AlertsRecipientsGrid
					pageName="rtGuardian-alertRuleRecipients"
					resourceParams={{
						guardianAlertId: profile.guardianAlertId
					}}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab
				{...tabs.Schedules}
				customHeader={
					<AlertRulesProfileSchedulesCustomHeader
						scheduleQty={profile?.schedules.length}
					/>
				}
			>
				<AlertScheduleGrid
					pageName="rtGuardian-alertRuleSchedule"
					resourceParams={{
						guardianAlertId: profile.guardianAlertId
					}}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

AlertRulesProfileContainer.displayName = 'AlertRulesProfileContainer';
AlertRulesRouter.setProfileRtUiFunctionalComponent(AlertRulesProfileContainer);
