import { RoutingRuleCreateRequest, RoutingRuleProfileResponse } from 'RtModels';
import { RoutingRulesActionEditor } from 'RtUi/app/rtSip/RoutingRules/lib/forms/RoutingRuleActionsEditor';
import { RoutingRuleMatchingEditor } from 'RtUi/app/rtSip/RoutingRules/lib/forms/RoutingRuleMatchingEditor';
import { RoutingRuleProfileEditor } from 'RtUi/app/rtSip/RoutingRules/lib/forms/RoutingRuleProfileEditor';
import { RoutingRuleSchedulesDataGrid } from 'RtUi/app/rtSip/RoutingRules/lib/grids/RoutingRuleSchedulesDataGrid';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { noop } from 'lodash-es';
import { createContext, useState } from 'react';
import { Accordion } from 'react-bootstrap';

export enum RoutingRuleEditorStep {
	PROFILE = 'Profile',
	MATCHING = 'Matching',
	ACTIONS = 'Actions',
	SCHEDULES = 'Schedules'
}

export type RoutingRuleValue =
	| RoutingRuleProfileResponse
	| RoutingRuleCreateRequest;

interface IRoutingRuleEditorProps {
	profile: RoutingRuleValue;
	error?: string | JSX.Element;
	isSubmitting?: boolean;
	onChange: (value: RoutingRuleValue) => void;
	onCancel?: () => void;
	step?: RoutingRuleEditorStep;
	displayMode?: boolean;
}

interface IRoutingRuleContext {
	value: RoutingRuleValue;
	onChange: <K extends keyof RoutingRuleValue>(
		key: K,
		value: RoutingRuleValue[K]
	) => void;
}

export const RoutingRuleContext = createContext<IRoutingRuleContext>({
	value: {
		label: '',
		notes: '',
		isCascade: 0,
		isLcrAppend: 0,
		matching: [],
		actions: [],
		schedule: []
	},
	onChange: noop
});

export const RoutingRuleEditor = ({
	profile,
	onChange,
	onCancel,
	isSubmitting = false,
	displayMode = false,
	error
}: IRoutingRuleEditorProps): JSX.Element => {
	const [value, setValue] = useState<RoutingRuleValue>(profile);

	const onChangeHandler = <K extends keyof RoutingRuleValue>(
		key: K,
		value: RoutingRuleValue[K]
	) => {
		setValue((currentState) => {
			const newValue = { ...currentState };

			newValue[key] = value;

			return newValue;
		});
	};

	const getEntriesLabel = (length: number) => {
		if (!length) {
			return '';
		}

		return `(${length} entries)`;
	};

	const isSubmitDisabled = (value: RoutingRuleValue) => {
		let errQty = 0;
		errQty += value.matching.length === 0 ? 1 : 0;
		errQty += value.matching.some(
			(m) => m.pattern === '' || m.pattern === 'undefined'
		)
			? 1
			: 0;
		errQty += value.actions.length === 0 ? 1 : 0;
		errQty += value.actions.some((a) =>
			a.subscriptions.some((s) => s.subscriptionId === undefined)
		)
			? 1
			: 0;

		return errQty > 0;
	};

	return (
		<RoutingRuleContext.Provider value={{ value, onChange: onChangeHandler }}>
			<RtUiForm
				onCancel={onCancel}
				onSubmit={(evt) => {
					evt.preventDefault();
					onChange(value);
				}}
				isSubmitDisabled={isSubmitDisabled(value)}
				isSubmitting={isSubmitting}
				error={error}
			>
				<Accordion
					alwaysOpen
					defaultActiveKey={[
						RoutingRuleEditorStep.PROFILE,
						RoutingRuleEditorStep.MATCHING,
						RoutingRuleEditorStep.ACTIONS
					]}
				>
					<Accordion.Item eventKey={RoutingRuleEditorStep.PROFILE}>
						<Accordion.Button as="b" className="p-2">
							{RoutingRuleEditorStep.PROFILE}
						</Accordion.Button>
						<Accordion.Collapse eventKey={RoutingRuleEditorStep.PROFILE}>
							<RoutingRuleProfileEditor displayMode={displayMode} />
						</Accordion.Collapse>
					</Accordion.Item>
					<Accordion.Item eventKey={RoutingRuleEditorStep.MATCHING}>
						<Accordion.Button as="b" className="p-2">
							{RoutingRuleEditorStep.MATCHING}{' '}
							{getEntriesLabel(value.matching.length)}
						</Accordion.Button>
						<Accordion.Collapse eventKey={RoutingRuleEditorStep.MATCHING}>
							<RoutingRuleMatchingEditor displayMode={displayMode} />
						</Accordion.Collapse>
					</Accordion.Item>
					<Accordion.Item eventKey={RoutingRuleEditorStep.ACTIONS}>
						<Accordion.Button as="b" className="p-2">
							{RoutingRuleEditorStep.ACTIONS}{' '}
							{getEntriesLabel(value.actions.length)}
						</Accordion.Button>
						<Accordion.Collapse eventKey={RoutingRuleEditorStep.ACTIONS}>
							<RoutingRulesActionEditor
								displayMode={displayMode}
								isCascade={value.isCascade === 1}
							/>
						</Accordion.Collapse>
					</Accordion.Item>
					<Accordion.Item eventKey={RoutingRuleEditorStep.SCHEDULES}>
						<Accordion.Button as="b" className="p-2">
							{RoutingRuleEditorStep.SCHEDULES}{' '}
							{getEntriesLabel(value.schedule.length)}
						</Accordion.Button>
						<Accordion.Collapse eventKey={RoutingRuleEditorStep.SCHEDULES}>
							<RoutingRuleSchedulesDataGrid />
						</Accordion.Collapse>
					</Accordion.Item>
				</Accordion>
			</RtUiForm>
		</RoutingRuleContext.Provider>
	);
};
