/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ScheduleIndexResponse = {
    properties: {
        scheduleId: {
            type: 'number',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        isPaused: {
            type: 'number',
            isRequired: true,
        },
        scheduleTypeId: {
            type: 'ScheduleType',
            isRequired: true,
        },
        scheduleStartDate: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        scheduleEndDate: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        repeatIntervalQty: {
            type: 'number',
            isRequired: true,
        },
        repeatIntervalTypeId: {
            type: 'ScheduleRepeatIntervalType',
            isRequired: true,
        },
        skipIntervals: {
            type: 'number',
            isRequired: true,
        },
        nextRunTime: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        parameters: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        recipients: {
            properties: {
            },
            isRequired: true,
            isNullable: true,
        },
        expirationDays: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
    },
};