import { uniqBy } from 'lodash-es';
import React from 'react';
import { Nav } from 'react-bootstrap';
import NavButton from 'RtUi/components/ui/TabbedLayout/NavButton';
import NavButtonGroup from 'RtUi/components/ui/TabbedLayout/NavButtonGroup';
import {
	TabLayoutContext,
	TabLayoutTab
} from 'RtUi/components/ui/TabbedLayout/TabLayoutContext';
import { UserActions } from 'RtUi/state/actions/user';

export interface ITabs {
	actionsHeader?: string;
	onTabChange: (activeTab: string) => void;
}

function getAvailableTabs(tabs: TabLayoutTab[], canManageFn?: () => boolean) {
	return tabs.filter((tab) => {
		if (tab.rtRoute) {
			if (!UserActions.has(...tab.rtRoute.permissions)) {
				return false;
			}
		} else if (tab.permissions) {
			if (!UserActions.has(...tab.permissions)) {
				return false;
			}
		}

		if (tab.requireCanManage) {
			return canManageFn ? canManageFn() : false;
		}

		return true;
	});
}

export const Tabs: React.FC<ITabs> = ({ onTabChange }) => {
	const {
		tabs,
		canManageFn,
		activeTabHeader: activeTab
	} = React.useContext(TabLayoutContext);

	const availableTabs = uniqBy(getAvailableTabs(tabs, canManageFn), 'header');
	const actions = availableTabs.filter((tab) => tab.isAction);
	const toggleTab = (activeTabParam: string) => {
		const sameTab = activeTab === activeTabParam;

		if (!sameTab) {
			onTabChange(activeTabParam);
		}
	};

	const onClickTabHandler = async (tab: TabLayoutTab) => {
		if (tab.customOnClick) {
			return tab.customOnClick(() => toggleTab(tab.header));
		}

		toggleTab(tab.header);
	};

	let activeGroup = '';

	for (const tab of availableTabs) {
		if (tab.isParent || tab.parentLabel) {
			if (activeTab === tab.header) {
				activeGroup = (tab.isParent ? tab.header : tab.parentLabel) || '';
			}
		}
		if (tab.isAction && activeTab === tab.header) {
			activeGroup = 'Actions';
		}
	}

	const renderGroup = (tab: TabLayoutTab) => {
		return (
			<NavButtonGroup
				label={tab.header === activeGroup ? activeTab : tab.header}
				parentTab={tab}
				onClickTabHandler={onClickTabHandler}
				tabs={availableTabs.filter((child) => child.parentLabel === tab.header)}
				isActive={tab.header === activeGroup}
			/>
		);
	};

	const renderNavButton = (tab: TabLayoutTab) => {
		return (
			<div style={{ marginRight: '10px' }}>
				<NavButton
					tab={tab}
					label={tab.customHeader ? tab.customHeader : tab.header}
					onClickTabHandler={onClickTabHandler}
					isActive={activeTab === tab.header}
				/>
			</div>
		);
	};

	const renderActions = () => {
		const actionTab: TabLayoutTab = {
			header: 'Actions',
			isAction: true,
			isParent: true
		};
		const label =
			activeGroup === 'Actions' ? `Actions: ${activeTab}` : 'Actions';
		return (
			<NavButtonGroup
				label={label}
				parentTab={actionTab}
				onClickTabHandler={onClickTabHandler}
				tabs={availableTabs.filter((tab) => tab.isAction)}
				isActive={activeGroup === 'Actions'}
				isAction={true}
			/>
		);
	};

	return (
		<Nav className="my-4">
			{availableTabs
				.filter((tab) => !tab.isAction)
				.map((tab) => {
					if (tab.isParent) {
						return renderGroup(tab);
					} else {
						if (!tab.parentLabel) {
							return renderNavButton(tab);
						}
					}
				})}
			{actions.length > 0 && renderActions()}
		</Nav>
	);
};
