import { darken, Button } from '@mui/material';
import { TabLayoutTab } from 'RtUi/components/ui/TabbedLayout/TabLayoutContext';

interface INavButtonProps {
	label:
		| string
		| React.ReactElement<any, string | React.JSXElementConstructor<any>>;
	onClickTabHandler: (tab: TabLayoutTab) => void;
	tab: TabLayoutTab;
	isActive: boolean;
}

export default function NavButton({
	label,
	onClickTabHandler,
	tab,
	isActive
}: INavButtonProps) {
	const handleClick = () => {
		onClickTabHandler(tab);
	};

	const bgColor = isActive ? '#394470' : '#fff';
	const color = isActive ? '#fff' : '#000';

	return (
		<Button
			onClick={handleClick}
			variant="outlined"
			sx={{
				'background': `${bgColor}`,
				'textTransform': 'none',
				'color': `${color}`,
				':hover': {
					background: darken(bgColor, 0.1),
					color: `${color}`
				},
				'borderRadius': 2
			}}
		>
			{label}
		</Button>
	);
}
