import * as React from 'react';
import {
	AsrIndexResponseData,
	Reports,
	ReportAggregates,
	AsrIndexResponse,
	GlobalUserQueryIdentifiers
} from 'RtModels';
import { AsrViewRouter } from 'RtUi/app/rtVue/AsrView/AsrView.router';
import { AsrTotalsLineChart } from 'RtUi/app/rtVue/AsrView/lib/charts/AsrTotalsLineChart';
import { AsrDataGrid } from 'RtUi/app/rtVue/AsrView/lib/grids/AsrDataGrid';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import {
	RtVueReportContainer,
	IRtVueContainerState
} from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { CdrSearchViewRouter } from 'RtUi/app/rtVue/CdrSearchView/CdrSearchView.router';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';

@AsrViewRouter.getIndexRtUiController({
	groupName: 'Network Reports',
	groupId: 'VueNetworkReports',
	groupOrderPriority: 1
})
export class AsrViewIndexContainer extends RtVueReportContainer<AsrIndexResponseData> {
	public state: IRtVueContainerState<AsrIndexResponseData> = {
		...this.getBaseState(),
		dataSums: [ReportAggregates.Asr]
	};
	protected reportId = Reports.Asr;
	protected router = AsrViewRouter;
	protected columnsToSkipInDiff: string[] = ['callDate', 'ingressReleaseCode'];
	protected idRegex = new RegExp(/^.*Id$/);

	protected async getResource(): Promise<HttpResource<AsrIndexResponseData>> {
		const vueHttp = new RtVueHttp();
		const response = await vueHttp.getAsrView(
			this.state.timeRange,
			this.state.filters,
			this.state.compareToDate,
			this.state.dataSums,
			this.state.preset,
			this.state.partition,
			this.state.additionalColumns?.map((column) => column.value)
		);

		const asrResource = new ArrayResource<AsrIndexResponseData>(
			'callDate',
			this.combineComparedData(response.data)
		);
		asrResource.setTotalCount(response.totalCount);

		return asrResource;
	}

	protected onDrilldown = (
		row: AsrIndexResponseData,
		key: keyof AsrIndexResponseData
	) => {
		let path: string | undefined;

		switch (key) {
			case 'ingressTrunkGroup':
				path = CdrSearchViewRouter.getDrillDownPath({
					date: row.callDate,
					ingressTrunkGroupId: row.ingressTrunkGroup
				});
				break;
			case 'egressTrunkGroup':
				path = CdrSearchViewRouter.getDrillDownPath({
					date: row.callDate,
					egressTrunkGroupId: row.egressTrunkGroup
				});
				break;
			case 'roboScore':
				path = CdrSearchViewRouter.getDrillDownPath({
					date: row.callDate,
					ingressTrunkGroupId: row.ingressTrunkGroup,
					userQueryIdent: GlobalUserQueryIdentifiers.RoboScore
				});
				break;
		}

		if (path) {
			ApplicationContainer.openNewTab(path);
		}
	};

	private combineComparedData(response: AsrIndexResponse) {
		if (!response.compareTo) {
			return response.data;
		}
		const data: any[] = [];
		for (let i = 0; i < response.data.length; i++) {
			const record: any = response.data[i];
			for (const prop in record) {
				if ({}.hasOwnProperty.call(response.compareTo.data[i], prop)) {
					record[`${prop}2`] =
						response.compareTo.data[i][prop as keyof AsrIndexResponseData];
					const skipColumn = this.columnsToSkipInDiff.includes(prop);
					const notIdField = !this.idRegex.test(prop);

					if (!skipColumn && notIdField) {
						record[`${prop}Diff`] =
							response.compareTo.diff[i][prop as keyof AsrIndexResponseData];
					}
				}
			}
			data.push(record);
		}
		return data;
	}

	private onCompareToDateUpdateHandler(compareDoDate: Date | undefined) {
		this.updateCompareToDate(compareDoDate, true);
	}

	protected renderReports(): React.ReactNode {
		return (
			<>
				<AsrTotalsLineChart
					resource={this.state.resource}
					// isCompareToDate
					onCompareToDateUpdate={this.onCompareToDateUpdateHandler}
				/>
				<AsrDataGrid
					isLoading={this.state.loadingReport}
					defaultSorting={this.state.defaultSorting}
					onSortingChange={this.onSortedChange}
					resource={this.state.resource}
					callDateFormat={this.getCallDateFormat()}
					onDrilldown={this.onDrilldown}
				/>
			</>
		);
	}
}
