import { PublishedWithChanges } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	RoutingGroupTemplateIndexRequest,
	RoutingGroupTemplateIndexResponse
} from 'RtModels';
import { RoutingGroupResource } from 'RtUi/app/rt800/RoutingGroups/lib/resources/RoutingGroupResource';
import { useGetRoutingGroupTemplates } from 'RtUi/app/rt800/RoutingGroups/lib/services';
import { PermissionGate } from 'RtUi/components/auth/PermissionGate';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getRoutingGroupTemplatesColumns } from 'RtUi/components/data/DataGrid/configurations/rt800/routingGroupTemplates';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { RoutingGroupRouter } from '../../RoutingGroup.router';
import { useMemo } from 'react';

interface IRoutingGroupTemplatesGridProps {
	resourceParams?: RoutingGroupTemplateIndexRequest;
}

export const RoutingGroupTemplatesGrid = ({
	resourceParams
}: IRoutingGroupTemplatesGridProps) => {
	const navigate = useNavigate();
	const { data, isFetching: isLoading } =
		useGetRoutingGroupTemplates(resourceParams);

	const columns = useMemo(() => getRoutingGroupTemplatesColumns(), []);

	const handleActivateTemplate = async (
		row: RoutingGroupTemplateIndexResponse
	) => {
		const contentElement = (
			<div>
				<Alert variant="info" className="mb-0 alert-sm">
					<i className="fas fa-fw fa-info-circle" />
					<span>
						&nbsp;&nbsp;Routing can only be changed once every 15 minutes.
					</span>
				</Alert>
				<br />
				<div>
					<span className="lead">
						<span>You are about to activate template:&nbsp;</span>
						<span className="text-muted">{row.templateName}</span>
					</span>
				</div>
				<br />
				<div>
					<span className="lead">
						<span>Would you like to continue?</span>
					</span>
				</div>
			</div>
		);
		const confirm = await Confirmation.create(contentElement, {
			confirmationBtnText: 'Yes, Activate',
			confirmationBtnColor: 'danger'
		});

		if (!confirm) {
			return;
		}

		const routingGroupResource = new RoutingGroupResource();

		routingGroupResource
			.activate(row.routingGroupId, row.routingGroupTemplateId)
			.then((task) => navigate(`/rt800/tasks/${task.taskId}`))
			.catch((activateErrors: string[]) => {
				alert(`Error activating template: ${activateErrors.join(', ')}`);
			});
	};

	const inlineActions = (row: RoutingGroupTemplateIndexResponse) => (
		<Box sx={{ display: 'flex', justifyContent: 'left' }}>
			{Boolean(row.canActivate) && (
				<Tooltip title="Activate Template" arrow placement="right">
					<IconButton
						type="button"
						color="success"
						size="small"
						onClick={(e) => {
							handleActivateTemplate(row);
							e.stopPropagation();
						}}
						sx={{
							padding: 0
						}}
					>
						<PublishedWithChanges />
					</IconButton>
				</Tooltip>
			)}
		</Box>
	);

	return (
		<PermissionGate
			permissions={Rt800ApiRoutes.RoutingGroups.Index.permissions}
		>
			<DataGrid<RoutingGroupTemplateIndexResponse>
				router={RoutingGroupRouter}
				data={data}
				columns={columns}
				totalRows={data && data.length}
				loading={isLoading}
				inlineActions={inlineActions}
				positionActionsColumn="first"
				pageName={'rt800_RoutingGroupTemplates'}
			/>
		</PermissionGate>
	);
};
