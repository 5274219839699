import { Col, Row } from 'react-bootstrap';
import {
	GuardianReconciliationMappingIndexRequest,
	GuardianReconciliationMappingIndexResponse,
	GuardianReconciliationMappingProfileResponse
} from 'RtModels';
import { GuardianReconciliationMappingEditor } from 'RtUi/app/rtVue/GuardianReconciliationMapping/lib/forms/GuardianReconciliationMappingEditor';
import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { GuardianReconciliationMappingsRouter } from './GuardianReconciliationMapping.router';
import { GuardianReconciliationMappingGrid } from './lib/grids/GuardianReconciliationMappingGrid';

interface IInitialResourceParams {
	index: GuardianReconciliationMappingIndexResponse | undefined;
	isActive?: number | null;
	isManaged: number | null;
}

interface IGuardianReconciliationMappingIndexContainerState
	extends ISearchResultsContainerState<GuardianReconciliationMappingIndexRequest>,
		IInitialResourceParams {}

@GuardianReconciliationMappingsRouter.getReconciliationIndexRtUiController()
export class GuardianReconciliationMappingIndexContainer extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	IGuardianReconciliationMappingIndexContainerState
> {
	public initParams: IInitialResourceParams = {
		index: undefined,
		isActive: 1,
		isManaged: 1
	};

	public state: IGuardianReconciliationMappingIndexContainerState = {
		...this.initParams,
		activeTab: this.tabs.Results,
		resourceParams: {}
	};

	constructor(props: any) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams() {
		const { isActive } = this.state;

		const resourceParams: GuardianReconciliationMappingIndexRequest = {
			isActive: isActive ?? undefined
		};

		return resourceParams;
	}

	public render() {
		return (
			<TabbedLayout
				router={GuardianReconciliationMappingsRouter}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm onSubmit={(e) => this.submitAndGoToResults(e)}>
						<IsActiveRadioFormControl
							label="Status"
							onChange={(isActive) => this.setState({ isActive })}
							value={this.state.isActive}
						/>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<GuardianReconciliationMappingGrid
						resourceParams={this.state.resourceParams}
						autoFocusFilter
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab header="Create Mapping" isAction>
					<Row>
						<Col lg={6}>
							<GuardianReconciliationMappingEditor
								createMode
								onUpdate={this.goToProfile}
							/>
						</Col>
					</Row>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}

	private goToProfile = (
		profile: GuardianReconciliationMappingProfileResponse
	) => {
		const profilePath = GuardianReconciliationMappingsRouter.getProfileRoute(
			profile.guardianReconciliationMappingId
		);

		this.goToPath(profilePath);
	};
}
