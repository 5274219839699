import { RoutingRuleScheduleResponse } from 'RtModels';
import { RoutingRuleContext } from 'RtUi/app/rtSip/RoutingRules/lib/forms/RoutingRuleEditor';
import { RoutingRulesSchedulesEditor } from 'RtUi/app/rtSip/RoutingRules/lib/forms/RoutingRuleSchedulesEditor';
import DataGrid from 'RtUi/components/data/DataGrid';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { DateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DeleteDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DeleteDataGridColumn';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { Aside } from 'RtUi/components/ui/Aside';
import moment from 'moment';
import { useContext, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface IRoutingRuleSchedulesDataGridProps {
	displayMode?: boolean;
}

export const RoutingRuleSchedulesDataGrid =
	({}: IRoutingRuleSchedulesDataGridProps): JSX.Element => {
		const {
			value: { schedule },
			onChange
		} = useContext(RoutingRuleContext);
		const [value, setValue] = useState<RoutingRuleScheduleResponse[]>(schedule);
		const [editingScheduleIndex, setEditingScheduleIndex] = useState<number>();

		const [editingSchedule, setEditingSchedule] =
			useState<RoutingRuleScheduleResponse>();

		const removeScheduleHandler = async (
			schedule: RoutingRuleScheduleResponse
		) => {
			const confirm = await Confirmation.createDelete(schedule.tz);

			if (!confirm) {
				return;
			}

			setValue((currentState) => {
				const newValue = [...currentState];

				const index = value.findIndex((v) => v === schedule);
				newValue.splice(index, 1);

				onChange('schedule', newValue);

				return newValue;
			});
		};

		const editScheduleHandler = (schedule?: RoutingRuleScheduleResponse) => {
			if (schedule) {
				const index = value.findIndex((v) => v === schedule);
				setEditingScheduleIndex(index);
				setEditingSchedule(schedule);
				return;
			}

			setEditingSchedule({
				tz: '',
				frTs: new Date(),
				toTs: new Date(),
				frSec: 0,
				toSec: 0,
				isSun: 0,
				isMon: 0,
				isTue: 0,
				isWed: 0,
				isThu: 0,
				isFri: 0,
				isSat: 0
			});
			setEditingScheduleIndex(undefined);
		};

		const onChangeHandler = (schedule: RoutingRuleScheduleResponse) => {
			setValue((currentState) => {
				const newValue = [...currentState];

				if (editingScheduleIndex === undefined) {
					newValue.push(schedule);
				} else {
					newValue.splice(editingScheduleIndex, 1, schedule);
				}

				onChange('schedule', newValue);
				setEditingSchedule(undefined);

				return newValue;
			});
		};

		const onCancelHandler = () => {
			setEditingSchedule(undefined);
			setEditingScheduleIndex(undefined);
		};

		return (
			<>
				<Aside isOpen={Boolean(editingSchedule)} className="overflow-visible">
					<Aside.Header header="Edit Schedule" onClose={onCancelHandler} />
					<Aside.Body>
						<RoutingRulesSchedulesEditor
							schedule={editingSchedule as RoutingRuleScheduleResponse}
							onChange={onChangeHandler}
							onCancel={onCancelHandler}
						/>
					</Aside.Body>
				</Aside>
				<DataGrid<RoutingRuleScheduleResponse>
					pageName="rtSip_routingRuleSchedule"
					data={value}
					disableExport
					headerAction={() => {
						return (
							<OverlayTrigger
								overlay={(props) => (
									<Tooltip id={`addScheduleBtn-tooltip`} {...props}>
										Create Schedule
									</Tooltip>
								)}
							>
								{({ ref, ...triggerHandler }) => (
									<Button
										ref={ref}
										{...triggerHandler}
										onClick={() => editScheduleHandler()}
										variant="primary"
										id="addScheduleBtn"
									>
										<i className="fas fa-fw fa-plus fa-lg" />
										Create Schedule
									</Button>
								)}
							</OverlayTrigger>
						);
					}}
					onRowClick={(schedule) => editScheduleHandler(schedule)}
					columns={[
						DeleteDataGridColumn({
							onClick: (row) => removeScheduleHandler(row)
						}),
						DefaultDataGridColumn({
							header: 'Time-Zone',
							accessorKey: 'tz',
							size: 120
						}),
						DateDataGridColumn({
							header: 'From',
							accessorKey: 'frTs',
							size: 110,
							isHidden: true
						}),
						DateDataGridColumn({
							header: 'To',
							accessorKey: 'toTs',
							size: 105,
							isHidden: true
						}),
						DefaultDataGridColumn({
							header: 'From Seconds',
							accessorFn: (row) => moment(row.frSec).format('hh:mm a'),
							isHidden: true
						}),
						DefaultDataGridColumn({
							header: 'To Seconds',
							accessorFn: (row) => moment(row.toSec).format('hh:mm a'),
							isHidden: true
						}),
						BooleanDataGridColumn({
							header: 'SUN',
							accessorKey: 'isSun',
							size: 110
						}),
						BooleanDataGridColumn({
							header: 'MON',
							accessorKey: 'isMon',
							size: 110
						}),
						BooleanDataGridColumn({
							header: 'TUE',
							accessorKey: 'isTue',
							size: 110
						}),
						BooleanDataGridColumn({
							header: 'WED',
							accessorKey: 'isWed',
							size: 110
						}),
						BooleanDataGridColumn({
							header: 'THR',
							accessorKey: 'isThu',
							size: 110
						}),
						BooleanDataGridColumn({
							header: 'FRI',
							accessorKey: 'isFri',
							size: 110
						}),
						BooleanDataGridColumn({
							header: 'SAT',
							accessorKey: 'isSat',
							size: 110
						})
					]}
				/>
			</>
		);
	};
