/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ReportsIndexRequest = {
    properties: {
        user: {
            type: 'string',
        },
        label: {
            type: 'string',
        },
        isActive: {
            type: 'number',
        },
        isFavorite: {
            type: 'number',
        },
        productId: {
            properties: {
            },
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
            minimum: 1,
        },
    },
};