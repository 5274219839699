import { FC } from 'react';

interface AlertRulesProfileSchedulesCustomHeaderProps {
	scheduleQty: number;
}

export const AlertRulesProfileSchedulesCustomHeader: FC<
	AlertRulesProfileSchedulesCustomHeaderProps
> = ({ scheduleQty }) => {
	return <>Schedules{scheduleQty > 0 && <>&nbsp;({scheduleQty})</>}</>;
};
