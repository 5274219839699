/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianUserQueryUpdateRequest = {
    properties: {
        reportId: {
            type: 'Reports',
            isRequired: true,
        },
        queryParameters: {
            type: 'GuardianUserQueryParams',
            isRequired: true,
        },
        isDefaultView: {
            type: 'number',
            isRequired: true,
        },
        isEmail: {
            type: 'number',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
        },
        summary: {
            type: 'string',
        },
        scheduleId: {
            type: 'number',
        },
        queryType: {
            type: 'string',
            isRequired: true,
        },
        isFavorite: {
            type: 'number',
        },
        queryDescription: {
            type: 'string',
            isRequired: true,
        },
        isGlobal: {
            type: 'number',
        },
    },
};