import { endOfDay } from 'date-fns';
import { useState } from 'react';
import { FullSummaryIndexRequest, FullSummaryTimeDisplay } from 'RtModels';
import { FullSummaryReportRouter } from 'RtUi/app/rtSip/FullSummaryReport/FullSummaryReport.router';
import { SummaryReportSearchForm } from 'RtUi/app/rtSip/FullSummaryReport/lib/components/SummaryReportSearchForm';
import { FullSummaryReportGrid } from 'RtUi/app/rtSip/FullSummaryReport/lib/grids/FullSummaryReportGrid';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';

const utcOffset = new Date().getTimezoneOffset();
const useStartTs = new Date();
useStartTs.setMinutes(0, 0, 0);
useStartTs.setMinutes(useStartTs.getMinutes() + utcOffset);
useStartTs.setHours(useStartTs.getHours() - 3);

export const FullSummaryReportIndexContainer = () => {
	const [tabs, activeTab, setActiveTab] = useTabs(
		FullSummaryReportRouter.getProfileTabs()
	);
	const [resourceParams, setResourceParams] = useState<FullSummaryIndexRequest>(
		{
			startTs: useStartTs,
			endTs: endOfDay(new Date()),
			timeslot: FullSummaryTimeDisplay.None
		}
	);

	const submitHandler = ({
		startTs,
		endTs,
		...data
	}: FullSummaryIndexRequest) => {
		setResourceParams({
			startTs: new Date(
				Date.UTC(
					startTs.getFullYear(),
					startTs.getMonth(),
					startTs.getDate(),
					startTs.getHours(),
					startTs.getMinutes(),
					startTs.getSeconds(),
					0
				)
			),
			endTs: new Date(
				Date.UTC(
					endTs.getFullYear(),
					endTs.getMonth(),
					endTs.getDate(),
					endTs.getHours(),
					endTs.getMinutes(),
					endTs.getSeconds(),
					999
				)
			),
			...data
		});
		setActiveTab(tabs.Results.header);
	};

	return (
		<TabbedLayout
			router={FullSummaryReportRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search} alwaysRender>
				<SummaryReportSearchForm
					resourceParams={resourceParams}
					onSubmit={submitHandler}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<FullSummaryReportGrid
					resourceParams={resourceParams}
					pageName="rtSip_fullSummary"
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

FullSummaryReportRouter.setIndexRtUiFunctionalComponent(
	FullSummaryReportIndexContainer,
	{
		groupName: 'Network Reports',
		groupId: 'SipNetworkReports'
	}
);
