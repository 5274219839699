import { FileTypeRouter } from 'RtUi/app/rtVue/FileTypes/FileType.router';
import { FileTypeDataGrid } from 'RtUi/app/rtVue/FileTypes/lib/grids/FileTypeDataGrid';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';

export function FileTypeIndexContainer() {
	return (
		<StandardLayout router={FileTypeRouter}>
			<FileTypeDataGrid />
		</StandardLayout>
	);
}

FileTypeRouter.setIndexRtUiFunctionalComponent(FileTypeIndexContainer, {
	groupName: 'Configuration'
});
