import { RoutingGroupTemplateIndexResponse } from 'RtModels';
import {
	DataGridColumn,
	RowThemeColor
} from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

interface RecurringExportDataGridColumnConfiguration<
	RoutingGroupTemplateIndexResponse
> extends DataGridColumnConfiguration<RoutingGroupTemplateIndexResponse> {}

export const RoutingGroupTemplateActiveDataGridColumn = <
	T = RoutingGroupTemplateIndexResponse,
>({
	...config
}: RecurringExportDataGridColumnConfiguration<RoutingGroupTemplateIndexResponse>): DataGridColumn<RoutingGroupTemplateIndexResponse> => {
	const renderValue = (value: any, data: RoutingGroupTemplateIndexResponse) => {
		const isWorking = data.isWorking;
		const isAlternate = data.isAlternate;
		const isCurrent = data.isCurrent;

		let theme = RowThemeColor.LIGHT;
		let label = 'No';

		if (isCurrent) {
			theme = RowThemeColor.SUCCESS;
			label = 'Yes';
			if (isAlternate) {
				theme = RowThemeColor.DANGER;
				label = 'Yes / DR Mode';
			}
		}

		if (isWorking) {
			return null;
		}

		return (
			<section className="d-flex">
				<div
					role="alert"
					style={{ textAlign: 'center' }}
					className={`alert-sm w-100 mb-0 p-0 alert alert-${theme}`}
				>
					<span>{label}</span>
				</div>
			</section>
		);
	};

	return DefaultDataGridColumn({
		...config,
		getValue: ({ cell, row }) =>
			renderValue(
				cell.getValue<any>(),
				row.original as RoutingGroupTemplateIndexResponse
			)
	});
};
