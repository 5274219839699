import { CallSimulatorRouter } from 'RtUi/app/rtSip/CallSimulator/CallSimulator.router';
import { useState } from 'react';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { RateCenterIndexResponse, SubscriptionIndexResponse } from 'RtModels';
import { RateCenterSelect } from 'RtUi/app/rtSip/MarginSummaryReport/lib/controls/RateCenterSelect';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TwoFADisabledWarning } from 'RtUi/components/ui/WarningAlert/TwoFADisabledWarning';
import { CallSimulatorProfile } from 'RtUi/app/rtSip/CallSimulator/CallSimulator.profile';
import { CustomerTerminationSubscriptionSelect } from 'RtUi/app/rtSip/CallSimulator/lib/controls/CustomerTerminationSubscriptionSelect';
import { useUrlSearchParams } from 'RtUi/components/hooks/useUrlSearchParams';

export const CallSimulatorIndexContainer = () => {
	const { getParam } = useUrlSearchParams();
	const [subscriptionId, setSubscriptionId] = useState<number | undefined>(
		getParam('subscriptionId') ? Number(getParam('subscriptionId')) : undefined
	);
	const [subscription, setSubscription] = useState<SubscriptionIndexResponse>();
	const [rateCenterRated, setRateCenterRated] =
		useState<RateCenterIndexResponse>();
	const [rateCenterJurisdiction, setRateCenterJurisdiction] =
		useState<RateCenterIndexResponse>();
	const [ratedNumber, setRatedNumber] = useState<string>(
		getParam('ratedPn', '')
	);
	const [jurisdictionNumber, setJurisdictionNumber] = useState<string>(
		getParam('jurisPn')
	);
	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search' },
		Results: { header: 'Results', isDisabled: true }
	});

	return (
		<TabbedLayout
			router={CallSimulatorRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<TwoFADisabledWarning />
				<RtUiSearchForm
					onSubmit={(evt) => {
						evt.preventDefault();

						setActiveTab(tabs.Results.header);
					}}
				>
					<CustomerTerminationSubscriptionSelect
						required
						onChange={(newSub) => {
							setSubscription(newSub ?? undefined);
							setSubscriptionId(newSub?.subscriptionId);
						}}
						value={subscription}
						initialOptionId={Number(subscriptionId)}
					/>
					<div className="mt-3 mb-3">
						<RateCenterSelect
							label="To Rate Center"
							value={rateCenterRated}
							onChange={(newRc) => {
								setRateCenterRated(newRc);
								setRatedNumber(newRc?.phoneNumber);
							}}
						/>
						<InputFormControl
							required
							label="To Number"
							onChange={(rn) => {
								setRatedNumber(rn);
							}}
							value={ratedNumber}
						/>
					</div>
					<RateCenterSelect
						label="From Rate Center"
						value={rateCenterJurisdiction}
						onChange={(newRc) => {
							setRateCenterJurisdiction(newRc);
							setJurisdictionNumber(newRc?.phoneNumber);
						}}
					/>
					<InputFormControl
						label="From Number"
						onChange={(jn) => {
							setJurisdictionNumber(jn);
						}}
						value={jurisdictionNumber}
					/>
				</RtUiSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				{subscriptionId && (
					<CallSimulatorProfile
						subscriptionId={subscriptionId}
						ratedNumber={ratedNumber}
						jurisdictionNumber={jurisdictionNumber}
					/>
				)}
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

CallSimulatorRouter.setIndexRtUiFunctionalComponent(
	CallSimulatorIndexContainer,
	{
		orderPriority: 0
	}
);
