import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import {
	GlobalUserQueryIdentifiers,
	GuardianUserQueryIndexRequest,
	GuardianUserQueryIndexResponse
} from 'RtModels';
import Badge from 'react-bootstrap/esm/Badge';
import { capitalize, isEqual } from 'lodash-es';
import { GuardianUserQueryScheduleResource } from 'RtUi/app/rtVue/common/lib/resources/GuardianUserQueryResource';

interface IGuardianUserQuerySelectProps
	extends ISelectFormControlProps<GuardianUserQueryIndexResponse, false> {
	resourceParams?: GuardianUserQueryIndexRequest;
	initialQueryIdentifier?: GlobalUserQueryIdentifiers;
	onChange?: (
		value: GuardianUserQueryIndexResponse,
		disregardTimeRange?: boolean
	) => void;
}

export class GuardianUserQuerySelect extends SelectFormControl<
	GuardianUserQueryIndexResponse,
	false,
	IGuardianUserQuerySelectProps
> {
	public resourceClass = GuardianUserQueryScheduleResource;
	public state: ISelectFormControlState<GuardianUserQueryIndexResponse> = {
		formLabel: 'Saved Query',
		valueKey: 'guardianUserQueryId',
		labelKey: 'label'
	};

	public componentDidMount() {
		super.componentDidMount();

		this.setGetAllParams(this.props.resourceParams);

		this.loadOptions();
	}

	public componentDidUpdate(prevProps: IGuardianUserQuerySelectProps) {
		super.componentDidUpdate(prevProps);

		if (!isEqual(prevProps.resourceParams, this.props.resourceParams)) {
			this.setGetAllParams(this.props.resourceParams);
			this.reloadOptionsOnFocus();
		}
	}

	/**
	 * Check for queryIdentifier usage
	 * @override
	 */
	public checkForDefault() {
		const { initialQueryIdentifier } = this.props;

		if (typeof initialQueryIdentifier !== 'string') {
			return super.checkForDefault();
		}

		if (this.props.value || !this.props.onChange) {
			return;
		}

		const { options = [] } = this.state;

		for (const option of options) {
			if (option.queryIdentifier === initialQueryIdentifier) {
				return this.props.onChange(option, true);
			}
		}
	}

	public updateResourceParams() {}

	public optionRenderer = (record: GuardianUserQueryIndexResponse) => {
		return (
			<span>
				<small>
					<Badge className="me-2" text="dark" bg="light">
						{capitalize(record.queryType)}
					</Badge>
				</small>
				<span>{record.label}</span>
			</span>
		);
	};
}
