import { RtDashboardOldRouter } from 'RtUi/app/rtCommon/DashboardOld/DashboardOld.router';
import { DashboardOldIndexContainer } from 'RtUi/app/rtCommon/DashboardOld/DashboardOld.index';
import { RtUiMenuPriority, RtUiModule } from 'RtUi/app/@RtUi/RtUiDecorators';

@RtUiModule({
	route: {
		iconClass: '',
		name: 'Dashboard',
		singleMode: true,
		orderPriority: RtUiMenuPriority.RT_DASHBOARD
	},
	controllers: [DashboardOldIndexContainer],
	routers: [RtDashboardOldRouter]
})
export class RtCommonDashboardOldModule {}
