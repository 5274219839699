import { RoutingGroupTemplateIndexResponse } from 'RtModels';
import {
	DataGridColumn,
	RowThemeColor
} from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

interface RecurringExportDataGridColumnConfiguration<
	RoutingGroupTemplateIndexResponse
> extends DataGridColumnConfiguration<RoutingGroupTemplateIndexResponse> {}

export const RoutingGroupTemplateTypeDataGridColumn = <
	T = RoutingGroupTemplateIndexResponse,
>({
	...config
}: RecurringExportDataGridColumnConfiguration<RoutingGroupTemplateIndexResponse>): DataGridColumn<RoutingGroupTemplateIndexResponse> => {
	const renderValue = (value: any, data: RoutingGroupTemplateIndexResponse) => {
		const isWorking = data.isWorking;
		const isDefault = data.isDefault;
		const isAlternate = data.isAlternate;
		let theme = RowThemeColor.PRIMARY;
		let label = 'Working';

		if (isWorking) {
			theme = RowThemeColor.PRIMARY;
			label = 'Working';
		}

		if (isDefault) {
			theme = RowThemeColor.SECONDARY;
			label = 'Default';
		}

		if (isAlternate) {
			theme = RowThemeColor.LIGHT;
			label = 'Alternate';
		}

		return (
			<section className="d-flex">
				<div
					role="alert"
					style={{ textAlign: 'center' }}
					className={`alert-sm w-100 mb-0 p-0 alert alert-${theme}`}
				>
					<span>{label}</span>
				</div>
			</section>
		);
	};

	return DefaultDataGridColumn({
		...config,
		getValue: ({ cell, row }) =>
			renderValue(
				cell.getValue<any>(),
				row.original as RoutingGroupTemplateIndexResponse
			)
	});
};
