/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ReportsIndexResponse = {
    properties: {
        productId: {
            type: 'number',
            isRequired: true,
        },
        product: {
            type: 'string',
            isRequired: true,
        },
        source: {
            type: 'string',
            isRequired: true,
        },
        reportId: {
            type: 'number',
            isRequired: true,
        },
        reportType: {
            type: 'string',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        user: {
            type: 'string',
            isRequired: true,
        },
        description: {
            type: 'string',
            isRequired: true,
        },
        isFavorite: {
            type: 'number',
            isRequired: true,
        },
        scheduleId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        schedule: {
            properties: {
            },
            isRequired: true,
            isNullable: true,
        },
        userFavoriteId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        userFavoriteTypeId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        canDelete: {
            type: 'number',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
    },
};