import * as React from 'react';
import {
	Reports,
	AsrLiteIndexResponse,
	TimeRangePresets,
	TimeFilterDetailKeys
} from 'RtModels';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import {
	RtVueReportContainer,
	IRtVueContainerState
} from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { AsrLiteDataGrid } from 'RtUi/app/rtVue/AsrLiteView/lib/grids/AsrLiteDataGrid';
import { AsrLiteViewRouter } from 'RtUi/app/rtVue/AsrLiteView/AsrLiteView.router';
import { TimeFilterDetailLevelsHours } from 'RtUi/app/rtVue/common/lib/components/DateTimeFilterRange/utilities';

@AsrLiteViewRouter.getIndexRtUiController({
	groupName: 'Network Reports',
	groupId: 'VueNetworkReports',
	groupOrderPriority: 1
})
export class AsrLiteViewIndexContainer extends RtVueReportContainer<AsrLiteIndexResponse> {
	public state: IRtVueContainerState<AsrLiteIndexResponse> = {
		...this.getBaseState({
			defaultTimeRangePreset: TimeRangePresets.ThirtyDays
		}),
		dataSums: [] as any,
		timeRangePresetsKeys: [
			TimeRangePresets.Week,
			TimeRangePresets.ThirtyDays,
			TimeRangePresets.Custom
		],
		timeRangeDetailsLevels: TimeFilterDetailLevelsHours.filter(
			(time) => time.key === TimeFilterDetailKeys.Months
		),
		noCustomFocusOnInit: true
	};
	protected reportId = Reports.AsrLite;
	protected router = AsrLiteViewRouter;
	protected columnsToSkipInDiff: string[] = ['callDate', 'ingressReleaseCode'];
	protected idRegex = new RegExp(/^.*Id$/);

	protected async getResource(): Promise<HttpResource<AsrLiteIndexResponse>> {
		const vueHttp = new RtVueHttp();
		const response = await vueHttp.getLiteAsrView(
			this.state.timeRange,
			this.state.filters,
			this.state.compareToDate,
			this.state.dataSums,
			this.state.preset,
			this.state.partition,
			this.state.additionalColumns?.map((column) => column.value)
		);

		const asrResource = new ArrayResource<AsrLiteIndexResponse>(
			'callDate',
			response.data
		);
		asrResource.setTotalCount(response.totalCount);

		return asrResource;
	}

	protected renderReports(): React.ReactNode {
		return (
			<>
				<AsrLiteDataGrid
					isLoading={this.state.loadingReport}
					defaultSorting={this.state.defaultSorting}
					onSortingChange={this.onSortedChange}
					resource={this.state.resource}
					callDateFormat={this.getCallDateFormat()}
				/>
			</>
		);
	}
}
