import { RoutingGroupTemplateIndexResponse } from 'RtModels';
import {
	DataGridColumn,
	RowThemeColor
} from 'RtUi/components/data/DataGrid/types';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { RoutingGroupTemplateTypeDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RoutingGroupTemplateTypeDataGridColumn';
import { RoutingGroupTemplateActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RoutingGroupTemplateActiveDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getRoutingGroupTemplatesColumns = (): Array<
	DataGridColumn<RoutingGroupTemplateIndexResponse>
> => [
	RoutingGroupTemplateTypeDataGridColumn({
		accessorKey: 'isWorking',
		header: 'Type'
	}),
	RoutingGroupTemplateActiveDataGridColumn({
		accessorKey: 'isCurrent',
		header: 'Current'
	}),
	LabelDataGridColumn({
		accessorKey: 'templateName',
		header: 'Name'
	}),
	LabelDataGridColumn({
		accessorKey: 'label',
		header: 'Label'
	}),
	IntegerDataGridColumn({
		accessorKey: 'tfnQty',
		header: 'TFN Qty'
	}),
	BooleanDataGridColumn(
		{
			accessorKey: 'isActive',
			header: 'Active'
		},
		false,
		RowThemeColor.SUCCESS,
		RowThemeColor.DANGER
	),
	BooleanDataGridColumn(
		{
			accessorKey: 'hasErrors',
			header: 'Has Errors'
		},
		true
	),
	BooleanDataGridColumn(
		{
			accessorKey: 'isValidCpr',
			header: 'Has CPR'
		},
		false,
		RowThemeColor.SUCCESS,
		RowThemeColor.DANGER
	)
];
