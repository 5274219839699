/* cSpell:disable */
import * as React from 'react';
import { Reports, TimeRangePresets, BirdsEyeIndexResponse } from 'RtModels';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import {
	IRtVueContainerState,
	RtVueReportContainer
} from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { BirdEyeReportRouter } from 'RtUi/app/rtVue/BirdsEyeViewReport/BirdEyeReport.router';
import { BirdReportGrid } from 'RtUi/app/rtVue/BirdsEyeViewReport/grids/BirdReportGrid';
import moment from 'moment';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';

interface BirdEyeReportState
	extends IRtVueContainerState<BirdsEyeIndexResponse> {
	gridToSee: string;
	dataColumns: string[];
}

export class BirdEyeReport extends RtVueReportContainer<
	BirdsEyeIndexResponse,
	BirdEyeReportState
> {
	protected reportId = Reports.BirdsEyeView;
	protected router = BirdEyeReportRouter;

	public state = {
		...this.getBaseState({ defaultTimeRangePreset: TimeRangePresets.Day }),
		timeRangePresetsKeys: [
			TimeRangePresets.Week,
			TimeRangePresets.ThirtyDays,
			TimeRangePresets.Custom
		],
		noCustomFocusOnInit: true,
		hideDetailsOption: true,
		minDate: moment().subtract(30, 'days').tz('UTC').toDate(),
		gridToSee: 'customer',
		dataColumns: ['customer'] as any,
		hideFileStreamSelect: true
	};

	protected async getResource(): Promise<HttpResource<BirdsEyeIndexResponse>> {
		const {
			timeRange,
			filters,
			partition,
			preset,
			dataSums,
			dataColumns,
			havingFilter
		} = this.state;
		const vueHttp = new RtVueHttp();
		const birdEyeIndexes = await vueHttp.getBirdEyeReport(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns,
			havingFilter
		);
		const financeResource = new ArrayResource<BirdsEyeIndexResponse>(
			'accountId',
			birdEyeIndexes.data
		);
		financeResource.setTotalCount(birdEyeIndexes.totalCount);

		return financeResource;
	}

	public setNewGrid = (grid: string) => {
		this.setState({ gridToSee: grid, dataColumns: [grid] }, () => {
			this.updateReports();
		});
	};

	public onClickSubscriptionColumn = (row: BirdsEyeIndexResponse) => {
		if (this.state.gridToSee === 'customer') {
			window.open(
				`fourteenday?additionalColumns=vendorAccountId%2BvendorSubscriptionId&filter=~%28key~%27customersubscriptionid~operator~%27in~operands~%28~%28dataSource~%27subscriptions~value~%27${row.subscriptionId}%29%29%29`,
				'_blank'
			);
		} else {
			window.open(
				`fourteenday?additionalColumns=customerAccountId%2BcustomerSubscriptionId&filter=~%28key~%27vendorsubscriptionid~operator~%27in~operands~%28~%28dataSource~%27subscriptions~value~%27${row.subscriptionId}%29%29%29`,
				'_blank'
			);
		}
	};

	protected renderReports(): React.ReactNode {
		return (
			<>
				<RadioFormControl<string>
					label="Choose which grid to see"
					onChange={(value: string) => this.setNewGrid(value)}
					value={this.state.gridToSee}
					initialValue={'customer'}
					options={[
						{ value: 'customer', label: 'Customer' },
						{ value: 'vendor', label: 'Vendor' }
					]}
				/>

				<BirdReportGrid
					isLoading={this.state.loadingReport}
					gridType={this.state.gridToSee}
					onClickSubscriptionColumn={this.onClickSubscriptionColumn}
					defaultSorting={this.state.defaultSorting}
					onSortingChange={this.onSortedChange}
					resource={this.state.resource}
					timeRange={this.state.timeRange.timeObject}
				/>
			</>
		);
	}
}

BirdEyeReportRouter.setIndexRtUiFunctionalComponent(BirdEyeReport, {
	orderPriority: 1
});
