import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ButtonVariant } from 'react-bootstrap/esm/types';

interface ButtonDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	overlay?: string;
	icon?: string;
	text?: string;
	variant?: ButtonVariant;
	color?: string;
	onClick: (row: T) => void;
}

export const ButtonDataGridColumColumn = <T = any,>({
	overlay,
	variant = 'light',
	icon,
	text,
	onClick,
	color,
	...config
}: ButtonDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getButton = (row: T, props?: any) => (
		<Button
			{...props}
			variant={variant}
			size="sm"
			color={color}
			className="ml-1 p-0"
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();

				onClick(row);
			}}
		>
			{icon && <i style={{ color: `${color}` }} className={`fas ${icon}`} />}
			{text && <span>{text}</span>}
		</Button>
	);

	if (!overlay) {
		return DefaultDataGridColumn({
			...config,
			exportValue: (value: T[keyof T]) => text || `${value}`,
			getValue: ({ row }) => getButton(row.original as T)
		});
	}

	return DefaultDataGridColumn({
		...config,
		getValue: ({ row }) => (
			<OverlayTrigger
				placement="right"
				overlay={(props) => (
					<Tooltip id={`notesGridButton_${row.index}-tooltip`} {...props}>
						{overlay}
					</Tooltip>
				)}
			>
				{({ ref, ...triggerHandler }) =>
					getButton(row.original as T, { ref, ...triggerHandler })
				}
			</OverlayTrigger>
		)
	});
};
