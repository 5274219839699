import { isEqual } from 'lodash-es';
import { FC, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import {
	AccountIndexResponse,
	SipConnectionIndexRequest,
	SubscriptionIndexResponse
} from 'RtModels';
import { ConnectionGrid } from 'RtUi/app/rtSip/Connections/lib/grids/ConnectionGrid';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { useFormInitializer } from 'RtUi/components/rtx/form/hooks/useFormInitializer';
import { RtxSearchForm } from 'RtUi/components/rtx/form/RtxUiSearchForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { TwoFADisabledWarning } from 'RtUi/components/ui/WarningAlert/TwoFADisabledWarning';
import { ConnectionRouter } from './Connection.router';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { AccountSelect } from 'RtUi/app/AccountManagement/Accounts/lib/controls/AccountSelect';
import { RtxUiPageSizes } from 'RtUi/RtxUiConstants';

export const ConnectionIndexContainer: FC<
	React.PropsWithChildren<unknown>
> = () => {
	const [subscription, setSubscription] = useState<SubscriptionIndexResponse>();
	const [accounts, setAccounts] = useState<AccountIndexResponse>();
	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search' },
		Results: { header: 'Results', isDefault: true }
	});
	const [resourceParams, setResourceParams] =
		useState<SipConnectionIndexRequest>({
			isActive: 1,
			pageSize: RtxUiPageSizes.SoftAll
		});

	const formInitializer = useFormInitializer();
	const submitHandler = (data: SipConnectionIndexRequest) => {
		setResourceParams(data);
		setActiveTab(tabs.Results.header);
	};

	return (
		<TabbedLayout
			router={ConnectionRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<Row>
					<Col md={8}>
						<RtxSearchForm<SipConnectionIndexRequest>
							initializer={formInitializer}
							onSubmit={submitHandler}
							defaultValues={resourceParams}
							onLoadUrlParams={(urlParams) => {
								if (!isEqual(urlParams, resourceParams)) {
									submitHandler(urlParams);
								}
							}}
						>
							{({ control }) => (
								<>
									<Controller
										control={control}
										name="subscriptionId"
										render={({ field: { onChange, value } }) => (
											<SubscriptionSelect
												label="Subscription"
												value={subscription}
												isClearable
												onChange={(val) => {
													onChange(val?.subscriptionId);
													setSubscription(val);
												}}
												initialOptionId={value}
											/>
										)}
									/>
									<Controller
										control={control}
										name="accountId"
										render={({ field: { onChange, value } }) => (
											<AccountSelect
												label="Account"
												value={accounts}
												clearable
												onChange={(val) => {
													setAccounts(val);
													onChange(val?.accountId);
												}}
												initialOptionId={value ? String(value) : undefined}
											/>
										)}
									/>
									<Controller
										control={control}
										name="search"
										render={({ field: { onChange, value } }) => (
											<InputFormControl
												label="Search"
												onChange={onChange}
												value={value}
											/>
										)}
									/>
									<Controller
										control={control}
										name="isActive"
										render={({ field: { onChange, value } }) => (
											<IsActiveRadioFormControl
												label="Status"
												onChange={onChange}
												value={value}
											/>
										)}
									/>
								</>
							)}
						</RtxSearchForm>
					</Col>
				</Row>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<TwoFADisabledWarning />
				<ConnectionGrid
					router={ConnectionRouter}
					resourceParams={resourceParams}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

ConnectionRouter.setIndexRtUiFunctionalComponent(ConnectionIndexContainer, {
	orderPriority: 0
});
