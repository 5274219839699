/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum AlertTimeRange {
    FifteenMins = 1,
    OneHour = 2,
    TwentyFourHour = 3,
    OneDay = 4,
}