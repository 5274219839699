import { RtReportsRouter } from 'RtUi/app/rtCommon/Reports/Reports.router';
import { ReportsIndexContainer } from 'RtUi/app/rtCommon/Reports/Reports.index';
import { RtUiMenuPriority, RtUiModule } from 'RtUi/app/@RtUi/RtUiDecorators';

@RtUiModule({
	route: {
		iconClass: '',
		name: 'Reports',
		singleMode: true,
		orderPriority: RtUiMenuPriority.RT_DASHBOARD + 1
	},
	controllers: [ReportsIndexContainer],
	routers: [RtReportsRouter]
})
export class RtCommonReportsModule {}
