import { RatePlanIndexResponse } from 'RtModels';
import { useGetRatePlans } from 'RtUi/app/AccountManagement/RatePlan/lib/service';
import {
	ISelectFormControlInstanceProps,
	SelectFormControl
} from 'RtUi/components/ui/SelectFormControl';

interface IRatePlanSelectProps<
	IsMulti extends boolean,
	K extends keyof RatePlanIndexResponse
> extends ISelectFormControlInstanceProps<RatePlanIndexResponse, K, IsMulti> {
	valueKey?: K;
	placeholder?: string;
	isShared?: boolean;
	assignableToSubscriptionId?: number;
}

export const RatePlanSelect = <
	IsMulti extends boolean = false,
	K extends keyof RatePlanIndexResponse = 'ratePlanId'
>({
	label = 'Rate Plan',
	valueKey = 'ratePlanId' as any,
	isShared,
	assignableToSubscriptionId,
	...props
}: IRatePlanSelectProps<IsMulti, K>): JSX.Element => {
	const { data, isLoading } = useGetRatePlans({
		...(isShared !== undefined && { isShared: Number(isShared) }),
		isActive: 1,
		assignableToSubscriptionId
	});

	return (
		<SelectFormControl<RatePlanIndexResponse, K, IsMulti>
			label={label}
			valueKey={valueKey}
			isLoading={isLoading}
			labelKey="summary"
			options={data ?? []}
			{...props}
		/>
	);
};
