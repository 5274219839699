import { RtUiMenuPriority, RtUiModule } from 'RtUi/app/@RtUi/RtUiDecorators';
import { AccountManagementRouter } from 'RtUi/app/AccountManagement/AccountManagement.router';
import { AccountIndexContainer } from 'RtUi/app/AccountManagement/Accounts/Account.index';
import { AccountProfileContainer } from 'RtUi/app/AccountManagement/Accounts/Account.profile';
import { AccountRouter } from 'RtUi/app/AccountManagement/Accounts/Account.router';
import { RateManagementIndexContainer } from 'RtUi/app/AccountManagement/RateManagement/RateManagement.index';
import { RateManagementProfileContainer } from 'RtUi/app/AccountManagement/RateManagement/RateManagement.profile';
import { RateManagementRouter } from 'RtUi/app/AccountManagement/RateManagement/RateManagement.router';
import { RatePlanIndexContainer } from 'RtUi/app/AccountManagement/RatePlan/RatePlan.index';
import { RatePlanProfileContainer } from 'RtUi/app/AccountManagement/RatePlan/RatePlan.profile';
import { RatePlanRouter } from 'RtUi/app/AccountManagement/RatePlan/RatePlan.router';
import { SubscriptionIndexContainer } from 'RtUi/app/AccountManagement/Subscriptions/Subscription.index';
import { SubscriptionProfileContainer } from 'RtUi/app/AccountManagement/Subscriptions/Subscription.profile';
import { SubscriptionRouter } from 'RtUi/app/AccountManagement/Subscriptions/Subscription.router';

@RtUiModule({
	route: {
		path: `/${AccountManagementRouter.SectionKey}`,
		iconClass: 'fa-chart-bar',
		name: 'Account Management',
		defaultOpen: true,
		orderPriority: RtUiMenuPriority.RT_ACCOUNT_MANAGEMENT
	},
	controllers: [
		AccountIndexContainer,
		AccountProfileContainer,
		SubscriptionIndexContainer,
		SubscriptionProfileContainer,
		RateManagementIndexContainer,
		RateManagementProfileContainer,
		RatePlanIndexContainer,
		RatePlanProfileContainer
	],
	routers: [
		AccountRouter,
		SubscriptionRouter,
		RateManagementRouter,
		RatePlanRouter
	]
})
export class AccountManagementModule {}
