import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	RoutingGroupIndexRequest,
	RoutingGroupIndexResponse,
	RoutingGroupProfileRequest,
	RoutingGroupProfileResponse,
	RoutingGroupTemplateIndexRequest,
	RoutingGroupTemplateIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest,
	RequestResponse
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export const fetchRoutingGroups = async <
	IncludeFullResponse extends boolean = false
>(
	queryParams?: RoutingGroupIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<
	RequestResponse<RoutingGroupIndexResponse[], IncludeFullResponse>
> => {
	return handleGetRequest(Rt800ApiRoutes.RoutingGroups.Index, {
		queryParams,
		includeFullResponse
	});
};

export const useGetRoutingGroups = (queryParams?: RoutingGroupIndexRequest) => {
	return useQuery<FullResponse<RoutingGroupIndexResponse[]>, Error>(
		[`useGetRoutingGroups`],
		() => fetchRoutingGroups(queryParams, true),
		{ enabled: true }
	);
};

export const useGetRoutingGroupTemplates = (
	queryParams?: RoutingGroupTemplateIndexRequest
) => {
	return useQuery<RoutingGroupTemplateIndexResponse[]>(
		[`useGetRoutingTemplatesGroups`],
		() => fetchRoutingGroupTemplates(queryParams),
		{ enabled: true }
	);
};

export const fetchRoutingGroupTemplates = async (
	urlParams?: RoutingGroupTemplateIndexRequest
): Promise<RoutingGroupTemplateIndexResponse[]> => {
	return handleGetRequest<RoutingGroupTemplateIndexResponse[]>(
		Rt800ApiRoutes.RoutingGroupTemplates.Index,
		{
			urlParams
		}
	);
};

export const useGetRoutingGroup = (urlParams?: RoutingGroupProfileRequest) => {
	return useQuery<RoutingGroupProfileResponse, Error>(
		[`useGetRoutingGroup`, urlParams],
		() => fetchRoutingGroup(urlParams),
		{ enabled: true }
	);
};

export const fetchRoutingGroup = async (
	urlParams?: RoutingGroupProfileRequest
): Promise<RoutingGroupProfileResponse> => {
	return handleGetRequest<RoutingGroupProfileResponse>(
		Rt800ApiRoutes.RoutingGroups.Profile,
		{
			urlParams
		}
	);
};
