import {
	DataFilter,
	DataFilterOperator,
	DataSources,
	GuardianRerateControlProfileResponse,
	RateControlJobStatus,
	RateControlJobType,
	RateControlRerateType,
	TimeFilter,
	TimeRangePresets
} from 'RtModels';
import { RerateControlResource } from 'RtUi/app/rtVue/RerateControl/lib/resources/RerateControlResource';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { Component, MouseEvent, FormEvent } from 'react';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { FlatDateRangePicker } from 'RtUi/app/rtVue/common/lib/components/FlatDateRangePicker/FlatDateRangePicker';
import { GetTimeFilterDetailsForPresetHours } from 'RtUi/app/rtVue/common/lib/components/DateTimeFilterRange/utilities';
import { endOfYesterday, startOfYesterday } from 'date-fns';
import { CriteriaControl } from 'RtUi/app/rtVue/RerateControl/lib/controls/CriteriaControl';
import {
	Col,
	Row,
	Card,
	Button,
	Form,
	OverlayTrigger,
	Tooltip
} from 'react-bootstrap';
import { CheckboxFormControl } from 'RtUi/components/form/CheckboxFormControl';
import { RerateTypeNameMap } from 'RtUi/app/rtVue/RerateControl/lib/utils/Constants';
import { timestampToReadable } from 'RtUi/utils/maps';
import { Confirmation } from 'RtUi/components/form/Confirmation';

interface IRerateControlFormEditorProps {
	profile?: GuardianRerateControlProfileResponse;
	onSuccess?: (profile: GuardianRerateControlProfileResponse) => void;
}

interface IRerateControlFormEditorState {
	isSubmitting: boolean;
	displayMode: boolean;
	rerateId: number | null;
	jobType: RateControlJobType;
	jobStatus?: RateControlJobStatus;
	rerateType?: ISimpleSelectFormControlOption<RateControlRerateType>;
	summary: string;
	timeRange: TimeFilter;
	filters: DataFilter[];
	jobTypeOption?: ISimpleSelectFormControlOption<RateControlJobType>;
	jobStatusOptions?: ISimpleSelectFormControlOption<RateControlJobStatus>;
	criteriaFieldOption?: ISimpleSelectFormControlOption<string>;
	selectedCriteriaOperator?: ISimpleSelectFormControlOption<DataFilterOperator>;
	criteriaValue?: string;
	error?: any;
	rejectsCallsOnly?: boolean;
	recalculateOnly?: boolean;
}

export class RerateControlFormEditor extends Component<
	IRerateControlFormEditorProps,
	IRerateControlFormEditorState
> {
	private resource = new RerateControlResource();

	constructor(props: IRerateControlFormEditorProps) {
		super(props);

		this.state = this.getInitialState(props);
	}

	public render() {
		const createMode = !this.props.profile;
		const isWaiting =
			this.props.profile?.jobStatus === RateControlJobStatus.Waiting;
		const allowedToEdit = createMode || isWaiting;

		return (
			<Row>
				<Col xs={{ order: 2 }} lg={{ span: 8, order: 1 }}>
					<RtUiForm
						createMode={createMode}
						isSubmitting={this.state.isSubmitting}
						displayMode={this.state.displayMode}
						error={this.state.error}
						onChange={(displayMode) => this.setState({ displayMode })}
						onCancel={this.onCancel}
						onSubmit={this.onSubmit}
					>
						<Row>
							<Col>
								<InputFormControl
									label="Summary"
									required
									displayMode={this.state.displayMode}
									onChange={(summary) => this.setState({ summary })}
									value={this.state.summary}
								/>
							</Col>
							{/* <Col>
								<SimpleSelectFormControl<RateControlJobType>
									required
									clearable={false}
									label="Job Type"
									displayMode={this.state.displayMode || !allowedToEdit}
									initialOptionId={String(this.state.jobType)}
									value={this.state.jobTypeOption}
									onChange={(jobTypeOption) => {
										this.setState({
											jobTypeOption,
											jobType: jobTypeOption.value
										});
									}}
									options={[
										{
											value: RateControlJobType.Dispute,
											label: 'Dispute'
										},
										// {
										// 	value: RateControlJobType.Nightly,
										// 	label: 'Nightly'
										// },
										{
											value: RateControlJobType.Rerate,
											label: 'Rerate'
										}
									]}
								/>
							</Col> */}
						</Row>
						<Row>
							<Col>
								<Form.Label>Date range</Form.Label>
								<FlatDateRangePicker
									value={this.state.timeRange}
									hideDetailsOption
									timeRangePresetsKeys={[TimeRangePresets.Custom]}
									onChange={(timeRange: TimeFilter) => {
										this.setState({ timeRange });
									}}
									maxDate={endOfYesterday()}
									displayMode={this.state.displayMode || !allowedToEdit}
								/>
							</Col>
							<Col>
								<SimpleSelectFormControl<RateControlRerateType>
									clearable={false}
									label="Type"
									displayMode={this.state.displayMode || !allowedToEdit}
									options={Array.from(RerateTypeNameMap).map(
										([value, label]) => ({
											value,
											label
										})
									)}
									onChange={(rerateType) => this.setState({ rerateType })}
									value={this.state.rerateType}
									initialOptionId={
										this.props.profile?.rerateType ??
										RateControlRerateType.Rerate
									}
								/>
							</Col>
							{!createMode && (
								<Col>
									<SimpleSelectFormControl<RateControlJobStatus>
										clearable={false}
										label="Job Status"
										displayMode={this.state.displayMode || !isWaiting}
										initialOptionId={
											this.state.jobStatus
												? String(this.state.jobStatus)
												: undefined
										}
										value={this.state.jobStatusOptions}
										onChange={(jobStatusOptions) => {
											this.setState({
												jobStatusOptions,
												jobStatus: jobStatusOptions.value
											});
										}}
										options={[
											{
												value: RateControlJobStatus.Cancelled,
												label: 'Cancelled'
											},
											{
												value: RateControlJobStatus.Complete,
												label: 'Complete'
											},
											{
												value: RateControlJobStatus.Downloading,
												label: 'Downloading'
											},
											{
												value: RateControlJobStatus.Error,
												label: 'Error'
											},
											{
												value: RateControlJobStatus.Rating,
												label: 'Rating'
											},
											{
												value: RateControlJobStatus.Uploading,
												label: 'Uploading'
											},
											{
												value: RateControlJobStatus.Waiting,
												label: 'Waiting'
											}
										]}
									/>
								</Col>
							)}
						</Row>
						<Row>
							<Col>
								<OverlayTrigger
									overlay={(props) => (
										<Tooltip id={`recalculate-tooltip`} {...props}>
											This will not save the CDRs back to the database. It will
											just calculate the expected differences
										</Tooltip>
									)}
								>
									{({ ref, ...triggerHandler }) => (
										<div
											className="d-inline-block"
											ref={ref}
											{...triggerHandler}
										>
											<CheckboxFormControl
												id="recalculate_only"
												displayMode={this.state.displayMode}
												label="Recalculate only"
												onChange={(recalculateOnly) => {
													this.setState({ recalculateOnly });
												}}
												value={this.state.recalculateOnly}
											/>
										</div>
									)}
								</OverlayTrigger>
								<CheckboxFormControl
									id="reject_call_only"
									displayMode={this.state.displayMode}
									label="Reject calls only"
									onChange={(rejectsCallsOnly) => {
										this.setState({ rejectsCallsOnly });
									}}
									value={this.state.rejectsCallsOnly}
								/>
							</Col>
						</Row>
						<Card body className="border border-light form-group mt-3">
							<h5 className="mb-3">Criteria</h5>
							{this.state.filters.map((filter, i) => (
								<CriteriaControl
									onDelete={this.deleteFilter(i)}
									onChange={this.onFilterChange(i)}
									key={`rerate-criteria-${i}`}
									filter={filter}
									displayMode={this.state.displayMode || !allowedToEdit}
								/>
							))}
							{!this.state.displayMode && allowedToEdit && (
								<Form.Group className="d-flex justify-content-start mb-3">
									<Button
										variant="light"
										className="me-3"
										onClick={this.addFilter}
									>
										<i className="fas fa-fw fa-plus" />
										&nbsp;Add Criteria
									</Button>
								</Form.Group>
							)}
						</Card>
					</RtUiForm>
				</Col>
				<Col xs={{ order: 1 }} lg={{ span: 4, order: 2 }}>
					{this.props.profile && (
						<Card className="mb-3">
							<Card.Body className="py-1 pt-3">
								<h5>
									Last Updated:{' '}
									{timestampToReadable(this.props.profile.updatedTs)}
								</h5>
								<h5 className="mt-2">Initial stats</h5>
								<hr className="m-0 mb-1" />
								<div className="text-end">
									<Row>
										<Col>Call count: {this.props.profile.initialCallCount}</Col>{' '}
										<Col>Minutes: {this.props.profile.initialMinutes}</Col>
									</Row>
									<Row>
										<Col>Revenue: {this.props.profile.initialRevenue}</Col>
										<Col>Cost: {this.props.profile.initialCost}</Col>
									</Row>
								</div>
								<h5 className="mt-4">Completed stats</h5>
								<hr className="m-0 mb-1" />
								<div className="text-end">
									<Row>
										<Col>
											Call count:{' '}
											{this.props.profile.completedCallCount ?? 'N/A'}
										</Col>
										<Col>
											Minutes: {this.props.profile.completedMinutes ?? 'N/A'}
										</Col>
									</Row>
									<Row>
										<Col>
											Revenue: {this.props.profile.completedRevenue ?? 'N/A'}
										</Col>
										<Col>Cost: {this.props.profile.completedCost ?? 'N/A'}</Col>
									</Row>
								</div>
							</Card.Body>
						</Card>
					)}
				</Col>
				<Confirmation />
			</Row>
		);
	}

	private addFilter = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();

		this.setState((state) => ({
			filters: [
				...state.filters,
				{ key: '', operator: DataFilterOperator.In, operands: [] }
			]
		}));
	};

	private deleteFilter =
		(index: number) => (e: MouseEvent<HTMLButtonElement>) => {
			e.preventDefault();

			const filtersCopy = [...this.state.filters];
			filtersCopy.splice(index, 1);
			this.setState({ filters: filtersCopy });
		};

	private onFilterChange = (index: number) => (filter: DataFilter) => {
		const filtersCopy = [...this.state.filters];
		filtersCopy[index] = filter;

		this.setState({
			filters: filtersCopy
		});
	};

	private getInitialState(
		props: IRerateControlFormEditorProps
	): IRerateControlFormEditorState {
		return {
			isSubmitting: false,
			displayMode: Boolean(props.profile),
			rerateId: props.profile?.jobId ?? null,
			summary: props.profile?.summary ?? '',
			jobType: props.profile?.jobType ?? RateControlJobType.Rerate,
			timeRange: this.getTimeRange(props),
			filters: this.getFilters(props),
			jobStatus: props.profile?.jobStatus,
			rejectsCallsOnly: props.profile?.queryParameters?.filters?.some(
				(p) => p.key === 'revenue_reject_code'
			),
			recalculateOnly: props.profile?.queryParameters?.filters?.some(
				(p) => p.key === 'recalculateOnly'
			)
		};
	}

	private getFilters(props: IRerateControlFormEditorProps) {
		const queryParameters = props.profile?.queryParameters;

		if (!queryParameters) {
			return [];
		}

		const filteredFilters = queryParameters.filters.filter(
			(p) => p.key !== 'revenue_reject_code' && p.key !== 'ingress_connect'
		);

		return filteredFilters;
	}

	private getTimeRange(props: IRerateControlFormEditorProps) {
		const queryParameters = props.profile?.queryParameters;

		if (!queryParameters) {
			const defaultTimeRange = GetTimeFilterDetailsForPresetHours(
				TimeRangePresets.Day
			).pop();

			return {
				timeObject: {
					mode: TimeRangePresets.Custom,
					start: startOfYesterday(),
					end: endOfYesterday()
				},
				detailLevel: {
					key: defaultTimeRange!.key,
					value: defaultTimeRange!.value
				}
			};
		}

		return queryParameters.timeRange;
	}

	private onSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (
			this.state.timeRange.timeObject.start &&
			this.state.timeRange.timeObject.end
		) {
			let startTs: Date;
			let endTs: Date;
			if (typeof this.state.timeRange.timeObject.start === 'string') {
				startTs = new Date(this.state.timeRange.timeObject.start);
			} else {
				startTs = this.state.timeRange.timeObject.start;
			}
			if (typeof this.state.timeRange.timeObject.end === 'string') {
				endTs = new Date(this.state.timeRange.timeObject.end);
			} else {
				endTs = this.state.timeRange.timeObject.end;
			}

			const startMonth = startTs.getMonth();
			const endMonth = endTs.getMonth();
			if (endMonth !== startMonth) {
				this.setState({ error: 'Date range cannot span multiple months' });
				return;
			}
		}

		const confirmation = await Confirmation.createConfirm(
			`Please make sure that all data is filled in correctly before submitting.`
		);

		if (!confirmation) {
			return;
		}

		const { onSuccess = () => {} } = this.props;

		try {
			let profile: GuardianRerateControlProfileResponse;
			this.setState({ isSubmitting: true, error: undefined });

			const extraFilters = [];
			if (this.state.rejectsCallsOnly) {
				extraFilters.push({
					key: 'revenue_reject_code',
					operands: [{ value: '0', dataSource: DataSources.Text }],
					operator: DataFilterOperator.NotIn
				});
			}

			if (this.state.recalculateOnly) {
				extraFilters.push({
					key: 'recalculateOnly',
					operands: [{ value: 'true', dataSource: DataSources.Text }],
					operator: DataFilterOperator.In
				});
			}

			if (!this.props.profile) {
				profile = await this.resource.create({
					summary: this.state.summary,
					rerateType: this.state.rerateType!.value,
					queryParameters: {
						timeRange: this.state.timeRange,
						filters: [...extraFilters, ...this.state.filters]
					},
					jobType: this.state.jobType
				});
			} else {
				profile = await this.resource.update(this.props.profile.jobId, {
					summary: this.state.summary,
					jobType: this.state.jobType,
					jobStatus: this.state.jobStatus,
					rerateType: this.state.rerateType!.value,
					queryParameters: {
						timeRange: this.state.timeRange,
						filters: [...extraFilters, ...this.state.filters]
					}
				});
			}
			this.setState({ displayMode: true, isSubmitting: false }, () => {
				onSuccess(profile);
			});
		} catch (error) {
			this.setState({ isSubmitting: false, error });
		}
	};

	private onCancel = () => {
		this.setState(this.getInitialState(this.props));
	};
}
