/* eslint-disable @typescript-eslint/no-unsafe-call */
import { FileLogIndexRequest, FileStreamIndexResponse } from 'RtModels';
import { FileLogRouter } from 'RtUi/app/rtVue/FileLog/FileLog.router';
import { FileLogGrid } from 'RtUi/app/rtVue/FileLog/lib/grids/FileLogGrid';
import { FileStreamSelect } from 'RtUi/app/rtVue/FileStreams/lib/controls/FileStreamSelect';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

export function FileLogIndexContainer() {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search', isDefault: true },
		Results: { header: 'Results' }
	});

	const [createdTsStart, setCreatedTsStart] = useState(
		moment().subtract(60, 'days').utc()
	);
	const [createdTsEnd, setCreatedTsEnd] = useState(moment().utc());

	const [fileStreams, setFileStreams] = useState<FileStreamIndexResponse[]>();
	const [fileName, setFileName] = useState<string>();
	const [dateError, setDateError] = useState<any>(null);

	const resourceParams = useMemo(() => {
		const params: FileLogIndexRequest = {};

		if (createdTsStart) {
			params.createdTsStart = createdTsStart.toDate();
		}

		if (createdTsEnd) {
			params.createdTsEnd = createdTsEnd.toDate();
		}

		if (fileStreams) {
			params.fileStreamIds = fileStreams.map(
				(fileStream) => fileStream.fileStreamId
			);
		}

		if (fileName) {
			params.searchFileName = fileName;
		}

		return params;
	}, [createdTsStart, createdTsEnd, fileStreams, fileName]);

	const searchFileLogs = async (event: any) => {
		try {
			event.preventDefault();
			setActiveTab('Results');
		} catch (e) {}
	};

	return (
		<TabbedLayout
			router={FileLogRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtUiSearchForm
					wide
					onSubmit={(e) => searchFileLogs(e)}
					error={dateError}
					isSubmitDisabled={Boolean(dateError)}
				>
					<Row>
						<Col md={6}>
							<DatetimeFormControl
								label="Created Start Date (UTC)"
								showDateOnly={false}
								value={createdTsStart}
								onChangeWithIsNow={(createdTsStart) => {
									const startDate = createdTsStart?.utc();
									if (!startDate) {
										return;
									}

									if (createdTsEnd && startDate.isAfter(createdTsEnd)) {
										return setDateError(
											'Created Start Date cannot be later than Created End Date'
										);
									}

									setDateError(null);
									setCreatedTsStart(startDate);
								}}
							/>
							<DatetimeFormControl
								label="Created End Date (UTC)"
								showDateOnly={false}
								value={createdTsEnd}
								onChangeWithIsNow={(createdTsEnd) => {
									const endDate = createdTsEnd?.utc();

									if (!endDate) {
										return;
									}

									if (createdTsStart && createdTsStart.isAfter(endDate)) {
										return setDateError(
											'Created Start Date cannot be later than Created End Date'
										);
									}
									setDateError(null);
									setCreatedTsEnd(endDate);
								}}
							/>
							<FileStreamSelect<true>
								label="File Stream"
								value={fileStreams}
								onChange={setFileStreams}
								multi
							/>
							<InputFormControl
								label="File Name"
								value={fileName}
								onChange={setFileName}
							/>
						</Col>
					</Row>
				</RtUiSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<FileLogGrid resourceParams={resourceParams} />
			</TabbedLayoutTab>
		</TabbedLayout>
	);
}

FileLogRouter.setIndexRtUiFunctionalComponent(FileLogIndexContainer, {
	groupName: 'Configuration'
});
