import {
	ServiceNumberPortRequestIndexRequest,
	SubscriptionIndexResponse
} from 'RtModels';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { TollFreeNumbersTextArea } from 'RtUi/app/rt800/Numbers/lib/controls/TollFreeNumbersTextArea';
import { PortRequestRouter } from 'RtUi/app/rtVue/PortRequest/PortRequest.router';
import { PortRequestDataGrid } from 'RtUi/app/rtVue/PortRequest/lib/grid/PortRequestDataGrid';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { useReplaceWindowUrl } from 'RtUi/utils/hooks/useReplaceWindowUrl';
import isEmpty from 'lodash-es/isEmpty';
import { useState } from 'react';

export function PortRequestIndexContainer() {
	const [subscription, setSubscription] = useState<SubscriptionIndexResponse>();
	const [externalAccount, setExternalAccount] = useState<string>();
	const [serviceNumbers, setServiceNumbers] = useState<string[]>();
	const [resourceParams, setResourceParams] =
		useState<ServiceNumberPortRequestIndexRequest>({});

	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search' },
		Results: { header: 'Results', isDefault: true }
	});

	if (!isEmpty(serviceNumbers)) {
		resourceParams.serviceNumbers = serviceNumbers?.map((val) =>
			Number(val.replace(/-/g, ''))
		);
	}

	useReplaceWindowUrl(activeTab.header, resourceParams);

	const searchPortRequest = async (event: React.FormEvent<HTMLFormElement>) => {
		try {
			event.preventDefault();
			setResourceParams({
				subscriptionId: subscription && subscription.subscriptionId,
				externalAccountId: externalAccount
					? Number(externalAccount)
					: undefined,
				serviceNumbers: serviceNumbers?.map((val) =>
					Number(val.replace(/-/g, ''))
				)
			});

			setActiveTab('Results');
		} catch (e) {}
	};

	return (
		<TabbedLayout
			router={PortRequestRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtUiSearchForm
					onSubmit={(evt) => {
						searchPortRequest(evt);
					}}
				>
					<SubscriptionSelect
						value={subscription}
						onChange={(value) => {
							setSubscription(value);
						}}
					/>
					<InputFormControl
						label="Ext. Account"
						type="number"
						onChange={(value) => {
							setExternalAccount(value);
						}}
						value={externalAccount}
					/>
					<TollFreeNumbersTextArea
						label="Phone Number"
						allowForUpload
						value={serviceNumbers}
						onChange={(val) => {
							setServiceNumbers(val);
						}}
					/>
				</RtUiSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<PortRequestDataGrid resourceParams={resourceParams} />
			</TabbedLayoutTab>
		</TabbedLayout>
	);
}

PortRequestRouter.setIndexRtUiFunctionalComponent(PortRequestIndexContainer, {
	groupName: 'Configuration'
});
