import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ManagedRespOrgProfileResponse } from 'RtModels';
import { FlowRouter } from 'RtUi/app/rtAdmin/Flows/Flow.router';
import { ManagedEntitySelect } from 'RtUi/app/rtAdmin/ManagedEntities/lib/controls/ManagedEntitySelect';
import { ManagedRespOrgSelect } from 'RtUi/app/rtAdmin/ManagedRespOrgs/lib/controls/ManagedRespOrgSelect';
import { ManagedRespOrgGrid } from 'RtUi/app/rtAdmin/ManagedRespOrgs/lib/grids/ManagedRespOrgGrid';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { ManagedRespOrgEditor } from './lib/forms/ManagedRespOrgEditor';
import { ManagedRespOrgRouter } from './ManagedRespOrg.router';

export const ManagedRespOrgIndexContainer: FC = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		'Results': { header: 'Results', isDefault: true },
		'Search': { header: 'Search' },
		'Create Managed RespOrg': {
			header: 'Create Managed RespOrg',
			isAction: true
		}
	});

	const navigate = useNavigate();

	const [managedEntity, setManagedEntity] = useState<any>(null);
	const [managedRespOrg, setManagedRespOrg] = useState<any>(null);
	const [isActive, setIsActive] = useState<number>();
	const [isManaged, setIsManage] = useState<number>();
	const [isFailing, setIsFailing] = useState<number>();

	const goToProfile = (managedRespOrg: ManagedRespOrgProfileResponse) => {
		const managedRespOrgPath = ManagedRespOrgRouter.getProfileRoute(
			managedRespOrg.respOrgId
		);

		navigate(managedRespOrgPath);
	};

	const searchRespOrgs = async (event: React.FormEvent<HTMLFormElement>) => {
		try {
			event.preventDefault();
			setActiveTab('Results');
		} catch (e) {}
	};

	return (
		<TabbedLayout
			router={FlowRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtUiSearchForm onSubmit={(e) => searchRespOrgs(e)}>
					<ManagedEntitySelect
						onChange={setManagedEntity}
						value={managedEntity}
					/>

					<ManagedRespOrgSelect
						onChange={setManagedRespOrg}
						value={managedRespOrg}
					/>
					<IsActiveRadioFormControl
						label="Status"
						onChange={setIsActive as any}
						value={isActive as any}
					/>
					<RadioFormControl<number | undefined>
						label="Managed by RouteTrust"
						onChange={setIsManage as any}
						value={isManaged as any}
						options={[
							{ value: 1, label: 'Active' },
							{ value: 0, label: 'Inactive' },
							{ value: undefined, label: 'Both' }
						]}
					/>
					<RadioFormControl
						label="Is Failing"
						onChange={setIsFailing as any}
						value={isFailing as any}
						options={[
							{ value: 1, label: 'Yes' },
							{ value: 0, label: 'No' },
							{ value: undefined, label: 'Both' }
						]}
					/>
				</RtUiSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<>
					<ManagedRespOrgGrid
						resourceParams={{
							entityId: managedEntity?.entityId,
							respOrgId: managedRespOrg?.respOrgId,
							isActive,
							isManaged,
							isFailing
						}}
					/>
				</>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs['Create Managed RespOrg']}>
				<ManagedRespOrgEditor
					createMode
					onCreate={(profile) => goToProfile(profile)}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

ManagedRespOrgIndexContainer.displayName = 'ManagedRespOrgIndexContainer';

ManagedRespOrgRouter.setIndexRtUiFunctionalComponent(
	ManagedRespOrgIndexContainer
);
