import InfoIcon from '@mui/icons-material/Info';
import { Chip } from '@mui/material';
import { useContext } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { RoutingRuleContext } from 'RtUi/app/rtSip/RoutingRules/lib/forms/RoutingRuleEditor';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { TextAreaFormControl } from 'RtUi/components/form/TextAreaFormControl';

interface IRoutingRuleProfileEditorProps {
	displayMode?: boolean;
}

export const RoutingRuleProfileEditor = ({
	displayMode = false
}: IRoutingRuleProfileEditorProps): JSX.Element => {
	const { value, onChange } = useContext(RoutingRuleContext);

	return (
		<Card>
			<Card.Body>
				<Row>
					<Col className="d-flex gap-3">
						<Form.Label className="pt-2" style={{ width: '80px' }}>
							Summary
						</Form.Label>
						<InputFormControl
							label=""
							hideLabel
							controlGroupClassName="flex-grow-1"
							displayMode={displayMode}
							onChange={(label) => onChange('label', label)}
							value={value?.label ?? ''}
							required
						/>
					</Col>
				</Row>
				<Row>
					<Col className="d-flex gap-2">
						<Form.Label className="pt-2" style={{ width: '80px' }}>
							Active
						</Form.Label>
						<BooleanRadioFormControl
							label=""
							hideLabel
							displayMode={displayMode}
							onChange={(isActive) => onChange('isActive', isActive)}
							value={value?.isActive ?? 0}
							required
						/>
					</Col>
				</Row>
				<Row>
					<Col className="d-flex gap-2">
						<Form.Label className="pt-2" style={{ width: '80px' }}>
							Cascade
						</Form.Label>
						<BooleanRadioFormControl
							label=""
							hideLabel
							displayMode={displayMode}
							onChange={(isCascade) => {
								onChange('isCascade', isCascade);
								if (isCascade) {
									onChange('isLcrAppend', 1);
								}
							}}
							value={value?.isCascade ?? 0}
							required
						/>
						<Chip
							size="small"
							label="Continue processing additional Routing Rules. Only available with Block actions."
							variant="outlined"
							icon={<InfoIcon />}
						/>
					</Col>
				</Row>
				<Row>
					<Col className="d-flex gap-2">
						<Form.Label className="pt-2" style={{ width: '80px' }}>
							LCR Append
						</Form.Label>
						<BooleanRadioFormControl
							label=""
							hideLabel
							displayMode={displayMode}
							onChange={(isLcrAppend) => {
								onChange('isLcrAppend', isLcrAppend);
								if (value.isCascade) {
									onChange('isLcrAppend', 1);
								}
							}}
							value={value?.isLcrAppend ?? 0}
							required
						/>
						<Chip
							label="Add remaining routing options to the end of the routing list after this rules actions are processed"
							variant="outlined"
							size="small"
							icon={<InfoIcon />}
						/>
					</Col>
				</Row>
				<Row>
					<Col className="d-flex gap-3">
						<Form.Label className="pt-2" style={{ width: '80px' }}>
							Notes
						</Form.Label>
						<TextAreaFormControl
							label=""
							hideLabel
							hideFormGroup
							displayMode={displayMode}
							onChange={(notes) => onChange('notes', notes)}
							value={value?.notes ?? ''}
						/>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};
