import { FC, useState } from 'react';
import { ReportsIndexRequest, ReportsIndexResponse } from 'RtModels';
import { RtUiMenuPriority } from 'RtUi/app/@RtUi/RtUiDecorators';
import { ReportsGrid } from 'RtUi/app/rtCommon/Reports/lib/grids/ReportsGrid';
import { RtReportsRouter } from 'RtUi/app/rtCommon/Reports/Reports.router';
import { GuardianUserQueryScheduleEditForm } from 'RtUi/app/rtVue/common/lib/forms/GuardianUserQueryScheduleEditForm';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { useReplaceWindowUrl } from 'RtUi/utils/hooks/useReplaceWindowUrl';

export const ReportsIndexContainer: FC<
	React.PropsWithChildren<unknown>
> = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search' },
		Results: { header: 'Results', isDefault: true }
	});
	const [isActive, setIsActive] = useState<number | null>(1);
	const [label, setLabel] = useState<string>();
	const [user, setUser] = useState<string>();

	const [userQueryIndex, setUserQueryIndex] =
		useState<ReportsIndexResponse | null>(null);

	const [isChanged, setIsChanged] = useState(false);
	const resourceParams: ReportsIndexRequest = {};

	if (isActive !== null) {
		resourceParams.isActive = isActive;
	}

	if (label) {
		resourceParams.label = label;
	}

	if (user) {
		resourceParams.user = user;
	}

	const clickSchedule = async (row: ReportsIndexResponse) => {
		setUserQueryIndex(row);
	};

	useReplaceWindowUrl(activeTab.header, resourceParams);

	return (
		<TabbedLayout
			router={RtReportsRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtUiSearchForm
					onSubmit={(evt) => {
						evt.preventDefault();
						setActiveTab(tabs.Results.header);
					}}
				>
					<InputFormControl
						label="Report Label"
						onChange={(label) => setLabel(label)}
						value={label}
					/>
					<InputFormControl
						label="User Email"
						onChange={(user) => setUser(user)}
						value={user}
					/>
					<IsActiveRadioFormControl
						label="Report Status"
						activeTooltip="Is Report Active"
						onChange={setIsActive}
						value={isActive}
					/>
				</RtUiSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				{!userQueryIndex && (
					<>
						<ReportsGrid
							clickSchedule={clickSchedule}
							resourceParams={{ ...resourceParams, isFavorite: 1 }}
							isChanged={isChanged}
							setIsChanged={setIsChanged}
							hideHeader={true}
							hideFooter={true}
						/>
						<br />
						<br />
						<ReportsGrid
							clickSchedule={clickSchedule}
							resourceParams={{ ...resourceParams }}
							isChanged={isChanged}
							setIsChanged={setIsChanged}
						/>
					</>
				)}
				{userQueryIndex && (
					<GuardianUserQueryScheduleEditForm
						data={userQueryIndex}
						onCancel={() => setUserQueryIndex(null)}
					/>
				)}
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

RtReportsRouter.setIndexRtUiFunctionalComponent(ReportsIndexContainer, {
	description: 'View all reports.',
	orderPriority: RtUiMenuPriority.RT_DASHBOARD + 1
});
