//import * as React from 'react';
import { TimezoneIndexResponse } from 'RtModels';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { TimezoneResource } from '../resources/TimezoneResource';

interface ITimezoneSelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<TimezoneIndexResponse, IsMulti> {
	valueKey?: keyof TimezoneIndexResponse;
	isClearable?: boolean;
}
interface ITimezoneSelectState
	extends ISelectFormControlState<TimezoneIndexResponse> {
	isClearable: boolean;
}

export class TimezoneSelect<
	IsMulti extends boolean = false
> extends SelectFormControl<
	TimezoneIndexResponse,
	IsMulti,
	ITimezoneSelectProps<IsMulti>,
	ITimezoneSelectState
> {
	public resourceClass = TimezoneResource;
	public state: ITimezoneSelectState = {
		formLabel: 'Time Zone',
		valueKey: this.props.valueKey ?? 'timezoneId',
		labelKey: 'label',
		isClearable: this.props.isClearable === true
	};
}
