/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RatePlanDefaultCreateRequest = {
    properties: {
        startTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        endTs: {
            properties: {
            },
        },
        ratedRegionId: {
            properties: {
            },
            isRequired: true,
        },
        ratedIso: {
            type: 'string',
            isRequired: true,
        },
        ratedExt: {
            type: 'string',
        },
        jurisRegionId: {
            properties: {
            },
            isRequired: true,
        },
        jurisIso: {
            type: 'string',
        },
        jurisExt: {
            type: 'string',
        },
        altRatePlanId: {
            type: 'number',
        },
        inGraceDur: {
            type: 'number',
        },
        egGraceDur: {
            type: 'number',
        },
        conFee: {
            type: 'number',
        },
        interRate: {
            type: 'number',
        },
        intraRate: {
            type: 'number',
        },
        indetRate: {
            type: 'number',
        },
        localRate: {
            type: 'number',
        },
        durMin: {
            type: 'number',
        },
        durInc: {
            type: 'number',
        },
    },
};