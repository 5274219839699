/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtAdmApiRoutes } from 'RtExports/routes';
import {
	ManagedRespOrgIndexRequest,
	ManagedRespOrgIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchManagedRespOrgs = async (
	queryParams?: ManagedRespOrgIndexRequest
): Promise<FullResponse<ManagedRespOrgIndexResponse[]>> => {
	return handleGetRequest(RtAdmApiRoutes.ManagedRespOrgs.Index, {
		includeFullResponse: true,
		queryParams
	});
};

export const useGetManagedRespOrgs = (
	queryParams?: ManagedRespOrgIndexRequest
) => {
	return useQuery<FullResponse<ManagedRespOrgIndexResponse[]>, Error>(
		['getManagedRespOrgs', queryParams],
		() => {
			return fetchManagedRespOrgs(queryParams);
		}
	);
};
