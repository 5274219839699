import { Permissions } from 'RtExports/routes';
import { CallflowService, SubscriptionProfileResponse } from 'RtModels';
import { SubscriptionValidation } from 'RtUi/app/AccountManagement/Subscriptions/lib/components/SubscriptionValidation';
import { SubscriptionNanpEditForm } from 'RtUi/app/AccountManagement/Subscriptions/lib/forms/SubscriptionNanpEditForm';
import { SubscriptionBillingForm } from 'RtUi/app/AccountManagement/Subscriptions/lib/forms/SubscriptionBillingForm';
import { SubscriptionEditor } from 'RtUi/app/AccountManagement/Subscriptions/lib/forms/SubscriptionEditor';
import { SubscriptionRoutingForm } from 'RtUi/app/AccountManagement/Subscriptions/lib/forms/SubscriptionRoutingForm';
import { RatePlanRateGrid } from 'RtUi/app/AccountManagement/Subscriptions/lib/grids/RatePlanRateGrid';
import { SubscriptionQosDataGrid } from 'RtUi/app/AccountManagement/Subscriptions/lib/grids/SubscriptionQosGrid';
import { SubscriptionScheduleDataGrid } from 'RtUi/app/AccountManagement/Subscriptions/lib/grids/SubscriptionScheduleGrid';
import { TagDataGrid } from 'RtUi/app/AccountManagement/Subscriptions/lib/grids/TagDataGrid';
import { VueConnectionGrid } from 'RtUi/app/AccountManagement/Subscriptions/lib/grids/VueConnectionsGrid';
import { useGetSubscription } from 'RtUi/app/AccountManagement/Subscriptions/lib/services';
import { SubscriptionPeeringGrid } from 'RtUi/app/rtLcr/SubscriptionPeering/grids/SubscriptionPeeringGrid';
import { CdrExportGrid } from 'RtUi/app/rtSip/CdrExports/lib/grids/CdrExportGrid';
import { ConnectionGrid } from 'RtUi/app/rtSip/Connections/lib/grids/ConnectionGrid';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { UserActions } from 'RtUi/state/actions/user';
import { SubscriptionRouter } from './Subscription.router';
import { RateLoadDataGrid } from 'RtUi/app/AccountManagement/RateManagement/libs/grids/RateLoadGrid';

export const SubscriptionProfileContainer = () => {
	const id = useIdParam();
	const {
		data: sipSubscription,
		isLoading,
		refetch
	} = useGetSubscription({
		subscriptionId: Number(id)
	});

	const [tabs, activeTab, setActiveTab] = useTabs(
		SubscriptionRouter.getProfileTabs(sipSubscription)
	);

	if (isLoading) {
		return <Loading />;
	}

	if (!sipSubscription) {
		return <></>;
	}

	return (
		<TabbedLayout<SubscriptionProfileResponse>
			router={SubscriptionRouter}
			profile={sipSubscription}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
			appendToHeader={<SubscriptionValidation profile={sipSubscription} />}
		>
			<TabbedLayoutTab {...tabs.Profile}>
				<SubscriptionEditor
					editMode={sipSubscription}
					onUpdate={() => refetch()}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Billing}>
				<SubscriptionBillingForm
					subscriptionId={sipSubscription.subscriptionId}
					onSuccess={() => refetch()}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.RatesHistory}>
				<RateLoadDataGrid
					resourceParams={{ subscriptionId: sipSubscription.subscriptionId }}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Routing}>
				<SubscriptionRoutingForm
					subscriptionId={sipSubscription.subscriptionId}
					serviceId={sipSubscription.serviceId}
					onSuccess={() => refetch()}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Nanp}>
				<SubscriptionNanpEditForm
					subscriptionId={sipSubscription.subscriptionId}
					serviceId={sipSubscription.serviceId}
					onSuccess={() => refetch()}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Qos}>
				<SubscriptionQosDataGrid subscription={sipSubscription} />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Rates}>
				<RatePlanRateGrid
					ratePlanId={sipSubscription.ratePlanId ?? -1}
					onUpdate={() => refetch()}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Schedule}>
				<SubscriptionScheduleDataGrid
					subscriptionId={sipSubscription.subscriptionId}
					onUpdate={() => refetch()}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Tags}>
				<TagDataGrid
					requestParams={{ subscriptionId: sipSubscription.subscriptionId }}
					subscriptionId={sipSubscription.subscriptionId}
					isAssignment={[
						CallflowService.InboundVendorOrigination,
						CallflowService.InboundCustomerIdentification
					].includes(sipSubscription.serviceId)}
					pageName={'accountManagement_subscriptionTags'}
					onUpdate={() => refetch()}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Connections}>
				<ConnectionGrid
					resourceParams={{
						subscriptionId: sipSubscription.subscriptionId
					}}
					onUpdate={() => refetch()}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.CdrExports}>
				<CdrExportGrid
					resourceParams={{
						subscriptionIsIn: [sipSubscription.subscriptionId]
					}}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.NumberPeering}>
				<SubscriptionPeeringGrid
					resourceParams={{
						subscriptionId: sipSubscription.subscriptionId,
						isActive: 1
					}}
					onUpdate={() => refetch()}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab
				{...tabs.VueConnections}
				isHidden={!UserActions.has(Permissions.VueOperator)}
			>
				<VueConnectionGrid
					resourceParams={{
						subscriptionId: sipSubscription.subscriptionId
					}}
					subscriptionId={sipSubscription.subscriptionId}
					onUpdate={() => refetch()}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

SubscriptionRouter.setProfileRtUiFunctionalComponent(
	SubscriptionProfileContainer
);
