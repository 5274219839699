import { DidApplicationRouter } from 'RtUi/app/rtDid/rtDid.router';
import { RtUiMenuPriority, RtUiModule } from 'RtUi/app/@RtUi/RtUiDecorators';
import { DidResourceIndexContainer } from 'RtUi/app/rtDid/Resources/DidResource.index';
import { DidResourceProfileContainer } from 'RtUi/app/rtDid/Resources/DidResource.profile';
import { DidResourceRouter } from 'RtUi/app/rtDid/Resources/DidResource.router';
import { DidNumberRouter } from 'RtUi/app/rtDid/Numbers/DidNumber.router';
import { DidSearchContainer } from 'RtUi/app/rtDid/Search/DidSearch.index';
import { DidNumberIndexContainer } from 'RtUi/app/rtDid/Numbers/DidNumber.index';
import { DidNumberProfileContainer } from 'RtUi/app/rtDid/Numbers/DidNumber.profile';
import { DidDashboardRouter } from 'RtUi/app/rtDid/Dashboard/DidDashboard.router';
import { DidDashboardIndexContainer } from 'RtUi/app/rtDid/Dashboard/DidDashboard.index';
import { DidDrilldownIndexContainer } from 'RtUi/app/rtDid/Drilldown/DidDrilldown.index';
import { DidResourceConfigurationProfileContainer } from 'RtUi/app/rtDid/ResourceConfigurations/DidResourceConfigurations.profile';

@RtUiModule({
	route: {
		path: `/${DidApplicationRouter.SectionKey}`,
		iconClass: 'fa-phone-volume',
		name: 'RT/DID Local',
		orderPriority: RtUiMenuPriority.RT_DID
	},
	controllers: [
		DidDashboardIndexContainer,
		DidDrilldownIndexContainer,
		DidNumberIndexContainer,
		DidNumberProfileContainer,
		DidSearchContainer,
		DidResourceIndexContainer,
		DidResourceProfileContainer,
		DidResourceConfigurationProfileContainer
	],
	routers: [DidDashboardRouter, DidNumberRouter, DidResourceRouter]
})
export class RtDidModule {}
