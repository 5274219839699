import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { RatePlanIndexResponse, RatePlanProfileResponse } from 'RtModels';
import { RtCommonApiRoutes } from 'RtExports/routes';
import { AccountManagementRouter } from 'RtUi/app/AccountManagement/AccountManagement.router';

interface IRatePlanRouterTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	Defaults: TabbedLayoutTabProps;
	Rates: TabbedLayoutTabProps;
	RateLoadHistory: TabbedLayoutTabProps;
	Subscriptions: TabbedLayoutTabProps;
	UploadRates: TabbedLayoutTabProps;
	CreateRatePlanDefault: TabbedLayoutTabProps;
}

export const RatePlanContainerTabs: IRatePlanRouterTabs = {
	Profile: {
		header: 'Profile'
	},
	Defaults: {
		header: 'Defaults'
	},
	Rates: {
		header: 'Rates'
	},
	RateLoadHistory: {
		header: 'Rate Load History'
	},
	Subscriptions: {
		header: 'Subscriptions'
	},
	UploadRates: {
		header: 'Upload Rates',
		rtRoute: RtCommonApiRoutes.RateLoads.Create,
		isAction: true
	},
	CreateRatePlanDefault: {
		header: 'Create Rate Plan Default',
		rtRoute: RtCommonApiRoutes.RatePlanDefaults.Create,
		isAction: true
	}
};

export class RatePlanRouterClass extends AccountManagementRouter<
	RatePlanIndexResponse,
	RatePlanProfileResponse,
	IRatePlanRouterTabs
> {
	constructor() {
		super('Rate Plan', 'ratePlan', 'ratePlanId', RatePlanContainerTabs);

		this.setPermissionsFromApiRoute(RtCommonApiRoutes.RatePlan);
	}

	public getProfileLabel(profile: RatePlanProfileResponse): string {
		return String(profile.summary || profile.ratePlanId);
	}
}

export const RatePlanRouter = new RatePlanRouterClass();
