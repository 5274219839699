import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { ReportsIndexResponse, ScheduleRepeatIntervalType } from 'RtModels';
import { GuardianUserQueryScheduleResource } from 'RtUi/app/rtVue/common/lib/resources/GuardianUserQueryResource';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { RtxForm } from 'RtUi/components/rtx/form';
import { useFormInitializer } from 'RtUi/components/rtx/form/hooks/useFormInitializer';
import { RtxDateTimeInput } from 'RtUi/components/rtx/inputs/DateTime/RtxDateTimeInput';
import { RtxEmailInput } from 'RtUi/components/rtx/inputs/RtxEmailInput';
import { RtxSelectInput } from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';
import { RtError } from 'RtUi/utils/errors/RtError';
// import { useState } from 'react';

interface IGuardianUserQueryScheduleEditForm {
	data: ReportsIndexResponse;
	onCancel: () => void;
}

export const GuardianUserQueryScheduleEditForm = ({
	data,
	onCancel
}: IGuardianUserQueryScheduleEditForm) => {
	const initializer = useFormInitializer();
	const resource = new GuardianUserQueryScheduleResource();

	const [error, setError] = useState<RtError>();
	const [interval, setInterval] = useState<IIntervalOptions>();

	const deleteSchedule = async () => {
		const confirm = await Confirmation.createDelete(
			`the schedule for report: ${data.label}`
		);
		if (!confirm) {
			return;
		}

		const params = {
			isGlobal: data.source === 'Global' ? 1 : 0,
			guardianUserQueryId: data.reportId
		};

		try {
			await resource.deleteSchedule(params);
			onCancel();
		} catch (e: any) {
			setError(new RtError(e));
		}
	};

	const hasSchedule = (data: ReportsIndexResponse) => {
		return data.scheduleId !== null && data.scheduleId > 0;
	};

	const onSubmit = async (data: ReportsIndexResponse) => {
		//const accountResource = new AccountResource();
		setError(undefined);

		const repeatIntervalTypeId = intervalOptions.find(
			(option) => option.value === data.schedule.repeatIntervalTypeId
		);

		const scheduleData = {
			recipients: data.schedule.recipients,
			nextRunTime: data.schedule.nextRunTime,
			repeatIntervalTypeId: repeatIntervalTypeId?.key || 0
		};

		const params = {
			isGlobal: data.source === 'Global' ? 1 : 0,
			guardianUserQueryId: data.reportId
		};

		try {
			if (hasSchedule(data)) {
				await resource.updateSchedule(params, scheduleData);
			} else {
				if (data.userFavoriteId && data.userFavoriteTypeId) {
					await resource.createSchedule(params, scheduleData);
				}
			}
			onCancel();
		} catch (e: any) {
			setError(new RtError(e));
		}
	};

	interface IIntervalOptions {
		key: ScheduleRepeatIntervalType;
		value: string;
	}
	const intervalOptions: IIntervalOptions[] = [
		{
			key: ScheduleRepeatIntervalType.Day,
			value: 'Day'
		},
		{
			key: ScheduleRepeatIntervalType.Week,
			value: 'Week'
		},
		{
			key: ScheduleRepeatIntervalType.Month,
			value: 'Month'
		}
	];

	return (
		<>
			<RtxForm<ReportsIndexResponse>
				defaultValues={data}
				initializer={initializer}
				onSubmit={onSubmit}
				onCancel={onCancel}
				error={
					error && {
						name: 'root',
						error: {
							message: error.message
						}
					}
				}
			>
				{({ control }) => (
					<>
						<h6>
							{hasSchedule(data) && 'Editing '}
							{!hasSchedule(data) && 'Adding '}
							schedule for report: {data.label}
						</h6>
						<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
							<Button
								type="button"
								onClick={onCancel}
								style={{
									background: 'primary',
									border: 'none',
									cursor: 'pointer'
								}}
							>
								Return to reports
							</Button>
							&nbsp;&nbsp;
							{hasSchedule(data) && (
								<Button
									type="button"
									onClick={async () => {
										try {
											await deleteSchedule();
											onCancel();
										} catch (e: any) {
											setError(new RtError(e));
										}
									}}
									style={{
										background: 'red',
										border: 'none',
										cursor: 'pointer',
										color: 'white'
									}}
								>
									Delete Schedule
								</Button>
							)}
						</div>
						<hr />
						<Controller
							control={control}
							name="schedule.nextRunTime"
							render={({ field: { onChange, value } }) => (
								<RtxDateTimeInput
									required
									className="mb-3"
									label="Report Next Date/Time"
									ampm={false}
									value={value ? new Date(value) : undefined}
									onChange={onChange}
									timezone="UTC"
								/>
							)}
						/>
						<Controller
							control={control}
							name="schedule.repeatIntervalTypeId"
							render={({ field: { onChange, value } }) => (
								<RtxSelectInput<IIntervalOptions, 'key', false, false>
									label="Schedule Interval Type"
									labelKey="value"
									valueKey="key"
									value={interval}
									initialOptionId={value}
									onChange={(val) => {
										setInterval(val);
										onChange(val.value);
									}}
									options={intervalOptions}
									required={true}
								/>
							)}
						/>
						<br />
						<br />
						<Controller
							control={control}
							name="schedule.recipients"
							render={({ field: { onChange, value } }) => (
								<RtxEmailInput
									isMulti={true}
									label="Recipient Email Addresses"
									onChange={onChange}
									value={value}
									required={true}
								/>
							)}
						/>
						<br />
						<br />
					</>
				)}
			</RtxForm>
		</>
	);
};
