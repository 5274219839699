import { useCallback } from 'react';
import { RtxRegion } from 'RtUi/app/rtSip/rtSipConstants';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';

export interface RegionIndexResponse {
	regionId: string;
	label: string;
}

export const regionIndexResponse: RegionIndexResponse[] = [
	{
		regionId: RtxRegion.NanpUs48,
		label: 'US 48'
	},
	{
		regionId: RtxRegion.NanpCanada,
		label: 'Canada'
	},
	{
		regionId: RtxRegion.NanpUsAlaska,
		label: 'Alaska'
	},
	{
		regionId: RtxRegion.NanpUsHawaii,
		label: 'Hawaii'
	},
	{
		regionId: RtxRegion.NanpCaribbean,
		label: 'Caribbean'
	},
	{
		regionId: RtxRegion.NanpPacific,
		label: 'Pacific'
	}
];

export interface IRtxRegionSelectProps<
	K extends keyof RegionIndexResponse,
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		RegionIndexResponse,
		K,
		IsMulti,
		IsClearable
	> {
	valueKey?: K;
}

export const RtxRegionSelect = <
	K extends keyof RegionIndexResponse,
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	label = 'Region',
	valueKey,
	...props
}: IRtxRegionSelectProps<K, IsMulti, IsClearable>): JSX.Element => {
	const formatOptionLabel = useCallback((region: RegionIndexResponse) => {
		return <span>{region.label}</span>;
	}, []);

	const data = regionIndexResponse;

	return (
		<RtxSelectInput<RegionIndexResponse, K, IsMulti, IsClearable>
			label={label}
			valueKey={valueKey ?? ('regionId' as K)}
			labelKey="label"
			options={data}
			formatOptionLabel={formatOptionLabel}
			isLoading={false}
			{...props}
		/>
	);
};
