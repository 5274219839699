/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ConnectionIndexResponse = {
    properties: {
        connectionId: {
            type: 'number',
            isRequired: true,
        },
        gatewayAddress: {
            type: 'string',
            isRequired: true,
        },
        peerAddress: {
            type: 'string',
            isRequired: true,
        },
        fileStreamId: {
            type: 'number',
            isRequired: true,
        },
        fileStreamName: {
            type: 'string',
            isRequired: true,
        },
        fileStreamIsActive: {
            type: 'number',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        trunkGroupId: {
            type: 'string',
            isRequired: true,
        },
        trunkGroupLabel: {
            type: 'string',
            isRequired: true,
        },
        externalId: {
            type: 'string',
            isRequired: true,
        },
        switchId: {
            type: 'string',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        carrierId: {
            type: 'string',
            isRequired: true,
        },
        portLimit: {
            type: 'number',
            isRequired: true,
        },
        cpsLimit: {
            type: 'number',
            isRequired: true,
        },
        isImt: {
            type: 'number',
            isRequired: true,
        },
        isPeering: {
            type: 'number',
        },
        trunkType: {
            type: 'GuardianConnectionTrunkType',
            isNullable: true,
        },
        trafficType: {
            type: 'TrafficTypes',
            isNullable: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedBy: {
            type: 'string',
            isRequired: true,
        },
        switchName: {
            type: 'string',
            isRequired: true,
        },
        serviceTypeId: {
            type: 'number',
        },
        directionId: {
            type: 'number',
        },
        subscriptionId: {
            type: 'number',
        },
        startTs: {
            type: 'Date',
            format: 'date-time',
        },
        endTs: {
            type: 'Date',
            format: 'date-time',
        },
        isRatePlanActive: {
            type: 'number',
        },
        ratePlanCreatedTs: {
            type: 'Date',
            format: 'date-time',
        },
        ratePlanUpdatedTs: {
            type: 'Date',
            format: 'date-time',
        },
        ratePlanUpdatedBy: {
            type: 'number',
        },
        attestationOverride: {
            type: 'Attestation',
            isNullable: true,
        },
    },
};