import * as React from 'react';
import {
	Reports,
	RoboscoreIndexResponse,
	TimeRangePresets,
	TimeFilterDetailKeys,
	GlobalUserQueryIdentifiers
} from 'RtModels';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import {
	RtVueReportContainer,
	IRtVueContainerState
} from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { RoboscoreDataGrid } from 'RtUi/app/rtVue/Roboscore/lib/grids/RoboscoreDataGrid';
import { RoboscoreRouter } from 'RtUi/app/rtVue/Roboscore/Roboscore.router';
import { TimeFilterDetailLevelsHours } from 'RtUi/app/rtVue/common/lib/components/DateTimeFilterRange/utilities';
import { CdrSearchViewRouter } from 'RtUi/app/rtVue/CdrSearchView/CdrSearchView.router';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';

@RoboscoreRouter.getIndexRtUiController({
	groupName: 'Network Reports',
	groupId: 'VueNetworkReports',
	groupOrderPriority: 1
})
export class RoboscoreIndexContainer extends RtVueReportContainer<RoboscoreIndexResponse> {
	public state: IRtVueContainerState<RoboscoreIndexResponse> = {
		...this.getBaseState({ defaultTimeRangePreset: TimeRangePresets.Day }),
		dataSums: [] as any,
		timeRangePresetsKeys: [
			TimeRangePresets.Week,
			TimeRangePresets.ThirtyDays,
			TimeRangePresets.Custom
		],
		timeRangeDetailsLevels: TimeFilterDetailLevelsHours.filter(
			(time) => time.key === TimeFilterDetailKeys.Months
		),
		noCustomFocusOnInit: true,
		hideDetailsOption: true
	};
	protected reportId = Reports.Roboscore;
	protected router = RoboscoreRouter;
	protected columnsToSkipInDiff: string[] = ['callDate', 'ingressReleaseCode'];
	protected idRegex = new RegExp(/^.*Id$/);

	protected async getResource(): Promise<HttpResource<RoboscoreIndexResponse>> {
		const vueHttp = new RtVueHttp();
		const { data, totalCount } = await vueHttp.getRoboscore(
			this.state.timeRange,
			this.state.filters,
			this.state.compareToDate,
			this.state.dataSums,
			this.state.preset,
			this.state.partition,
			this.state.additionalColumns?.map((column) => column.value)
		);

		const roboscoreResource = new ArrayResource<RoboscoreIndexResponse>(
			'callDate',
			data
		);
		roboscoreResource.setTotalCount(totalCount);

		return roboscoreResource;
	}

	protected onDrilldown = (
		row: RoboscoreIndexResponse,
		key: keyof RoboscoreIndexResponse
	) => {
		let path: string | undefined;

		switch (key) {
			case 'roboscore':
				path = CdrSearchViewRouter.getDrillDownPath({
					ingressTrunkGroupId: row.ingressTrunkGroup,
					userQueryIdent: GlobalUserQueryIdentifiers.RoboScore
				});
				break;
		}
		if (path) {
			ApplicationContainer.openNewTab(path);
		}
	};

	protected renderReports(): React.ReactNode {
		return (
			<>
				<RoboscoreDataGrid
					isLoading={this.state.loadingReport}
					defaultSorting={this.state.defaultSorting}
					onSortingChange={this.onSortedChange}
					resource={this.state.resource}
					callDateFormat={this.getCallDateFormat()}
					onDrilldown={this.onDrilldown}
				/>
			</>
		);
	}
}
