import { AlertRulesRouter } from 'RtUi/app/rtGuardian/AlertRules/AlertRules.router';
import { AlertRulesEditor } from 'RtUi/app/rtGuardian/AlertRules/lib/forms/AlertRulesEditor';
import {
	GuardianAlertIndexResponse,
	GuardianAlertRuleIndexRequest,
	GuardianAlertTypeIndexResponse
} from 'RtModels';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { AlertTypeSelect } from 'RtUi/app/rtGuardian/AlertRules/lib/forms/AlertTypeSelect';
import { MultipleCheckboxFormControl } from 'RtUi/components/form/MultipleCheckboxFormControl';
import { useMemo, useState } from 'react';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { useNavigate } from 'react-router-dom';
import { useUrlSearchParams } from 'RtUi/components/hooks/useUrlSearchParams';
import { isNil, omitBy, pick } from 'lodash-es';
import { AlertRuleGrid } from 'RtUi/app/rtGuardian/AlertRules/lib/grids/AlertRuleGrid';

type DayOfWeek = Pick<
	GuardianAlertRuleIndexRequest,
	| 'isMonday'
	| 'isTuesday'
	| 'isWednesday'
	| 'isThursday'
	| 'isFriday'
	| 'isSaturday'
	| 'isSunday'
>;
type DayOfWeekKey = keyof DayOfWeek;

export const AlertRulesIndexContainer = (): JSX.Element => {
	const navigate = useNavigate();
	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search' },
		Results: { header: 'Results', isDefault: true },
		Create: { header: 'Create Alert Rule', isAction: true }
	});
	const { setUrlSearchParams, getParam } = useUrlSearchParams();

	const initialResourceParams = useMemo(() => {
		return omitBy(
			{
				isActive: Number(getParam('isActive', 1)),
				summary: getParam<string>('summary'),
				alertTypeId: getParam('alertTypeId'),
				isMonday: getParam('isMonday'),
				isTuesday: getParam('isTuesday'),
				isWednesday: getParam('isWednesday'),
				isThursday: getParam('isThursday'),
				isFriday: getParam('isFriday'),
				isSaturday: getParam('isSaturday'),
				isSunday: getParam('isSunday')
			},
			isNil
		);
	}, [getParam]);

	const [resourceParams, setResourceParams] = useState<
		Partial<GuardianAlertRuleIndexRequest>
	>(initialResourceParams);
	const [summary, setSummary] = useState<string>(
		initialResourceParams.summary as string
	);
	const [isActive, setIsActive] = useState<number>(
		initialResourceParams.isActive as number
	);
	const [alertType, setAlertType] = useState<GuardianAlertTypeIndexResponse>();

	const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState<DayOfWeekKey[]>(
		Object.keys(
			pick(initialResourceParams, [
				'isMonday',
				'isTuesday',
				'isWednesday',
				'isThursday',
				'isFriday',
				'isSaturday',
				'isSunday'
			])
		) as DayOfWeekKey[]
	);

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const daysOfWeek = selectedDaysOfWeek.reduce<Partial<DayOfWeek>>(
			(dest, dayKey) => {
				return { ...dest, [dayKey]: 1 };
			},
			{}
		);

		const params = omitBy(
			{
				isActive,
				summary,
				alertTypeId: alertType?.alertTypeId,
				...daysOfWeek
			},
			isNil
		);

		setResourceParams(params);
		setUrlSearchParams(params);
		setActiveTab(tabs.Results.header);
	};

	const goToAlertRule = (alertRule: GuardianAlertIndexResponse) => {
		const profilePath = AlertRulesRouter.getProfileRoute(alertRule);
		navigate(profilePath);
	};

	return (
		<TabbedLayout
			router={AlertRulesRouter}
			activeTab={activeTab.header}
			onTabChange={(activeTab) => setActiveTab(activeTab)}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtUiSearchForm onSubmit={(e) => onSubmit(e)}>
					<AlertTypeSelect
						clearable
						onChange={(selectedAlertType) => {
							setAlertType(selectedAlertType);
						}}
						value={alertType}
						initialOptionId={resourceParams.alertTypeId?.toString()}
					/>
					<InputFormControl
						label="Summary"
						onChange={(summary) => setSummary(summary)}
						value={summary}
					/>
					<MultipleCheckboxFormControl
						options={[
							{ value: 'isMonday', label: 'Mon' },
							{ value: 'isTuesday', label: 'Tue' },
							{ value: 'isWednesday', label: 'Wed' },
							{ value: 'isThursday', label: 'Thu' },
							{ value: 'isFriday', label: 'Fri' },
							{ value: 'isSaturday', label: 'Sat' },
							{ value: 'isSunday', label: 'Sun' }
						]}
						value={selectedDaysOfWeek.map((key) => key.toString())}
						label="Scheduled Run Time"
						onChange={(newSelectedDaysOfWeek) => {
							setSelectedDaysOfWeek(newSelectedDaysOfWeek as DayOfWeekKey[]);
						}}
					/>
					<IsActiveRadioFormControl
						hideBothOption
						onChange={(isActive) => setIsActive(isActive)}
						value={isActive}
					/>
				</RtUiSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<AlertRuleGrid resourceParams={resourceParams} />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Create}>
				<AlertRulesEditor
					createMode
					onCreate={(alertRule) => goToAlertRule(alertRule)}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

AlertRulesIndexContainer.displayName = 'AlertRulesIndexContainer';

AlertRulesRouter.setIndexRtUiFunctionalComponent(AlertRulesIndexContainer, {
	groupName: 'Alerts'
});
