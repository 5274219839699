import { useState } from 'react';
import {
	AccountIndexResponse,
	CallflowService,
	CountryIndexResponse,
	GatewayIndexResponse,
	RateCenterIndexResponse,
	RateCenterTypes,
	RoutePlanIndexResponse,
	RoutingRuleMatchType,
	SubscriptionIndexResponse,
	SwitchIndexResponse
} from 'RtModels';
import { AccountSelect } from 'RtUi/app/AccountManagement/Accounts/lib/controls/AccountSelect';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { SwitchesSelect } from 'RtUi/app/AccountManagement/Switches/lib/controls/SwitchesSelect';
import { GatewaySelect } from 'RtUi/app/rtSip/Connections/lib/forms/GatewaySelect';
import { RateCenterSelect } from 'RtUi/app/rtSip/MarginSummaryReport/lib/controls/RateCenterSelect';
import { RateCenterTypeSelect } from 'RtUi/app/rtSip/QosManagement/lib/controls/RateCenterTypeSelect';
import { RoutePlanSelect } from 'RtUi/app/rtSip/RoutePlans/lib/controls/RoutePlanSelect';
import { CountrySelect } from 'RtUi/app/user/lib/controls/CountrySelect';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtxTextInput } from 'RtUi/components/rtx/inputs/Text/RtxTextInput';

interface IRoutingRuleMatchPatternFormControlProps {
	matchType: RoutingRuleMatchType;
	value: string;
	hideLabel?: boolean;
	displayMode?: boolean;
	onChange: (value: string) => void;
	required?: boolean;
}

export const RoutingRuleMatchPatternFormControl = ({
	matchType,
	value,
	onChange,
	displayMode = false,
	hideLabel,
	required = false
}: IRoutingRuleMatchPatternFormControlProps): JSX.Element => {
	const [countryValue, setCountryValue] = useState<CountryIndexResponse>();
	const [rateCenterValue, setRateCenterValue] =
		useState<RateCenterIndexResponse>();
	const [rateCenterTypeValue, setRateCenterTypeValue] =
		useState<RateCenterTypes>();
	const [routePlanValue, setRoutePlanValue] =
		useState<RoutePlanIndexResponse>();
	const [subscriptionValue, setSubscriptionValue] =
		useState<SubscriptionIndexResponse>();
	const [accountValue, setAccountValue] = useState<AccountIndexResponse>();
	const [switchValue, setSwitchValue] = useState<SwitchIndexResponse>();
	const [gatewayValue, setGatewayValue] = useState<GatewayIndexResponse>();

	switch (matchType) {
		case RoutingRuleMatchType.ToRateCenterId:
		case RoutingRuleMatchType.FromRateCenterId: {
			return (
				<RateCenterSelect
					value={rateCenterValue}
					onChange={(val) => {
						setRateCenterValue(val);
						onChange(val.rateCenterId.toString());
					}}
					hideLabel={hideLabel}
					initialOptionId={value}
					appendDropdownToBody
					displayMode={displayMode}
					clearable={false}
				/>
			);
		}
		case RoutingRuleMatchType.ToRateCenterTypeId:
		case RoutingRuleMatchType.FromRateCenterTypeId: {
			return (
				<RateCenterTypeSelect
					value={rateCenterTypeValue}
					onChange={(val) => {
						setRateCenterTypeValue(val);
						onChange(val.toString());
					}}
					hideLabel={hideLabel}
					initialOptionId={value}
					appendDropdownToBody
					displayMode={displayMode}
					clearable={false}
				/>
			);
		}
		case RoutingRuleMatchType.ToIso:
		case RoutingRuleMatchType.FromIso: {
			return (
				<CountrySelect
					value={countryValue}
					hideLabel={hideLabel}
					onChange={(val) => {
						setCountryValue(val);
						onChange(val.iso3166Alpha3);
					}}
					initialOptionId={value}
					appendDropdownToBody
					displayMode={displayMode}
					isClearable={false}
				/>
			);
		}
		case RoutingRuleMatchType.InVendorAccountId:
		case RoutingRuleMatchType.InCustomerAccountId:
		case RoutingRuleMatchType.EgVendorAccountId:
		case RoutingRuleMatchType.EgCustomerAccountId: {
			return (
				<AccountSelect
					value={accountValue}
					hideLabel={hideLabel}
					onChange={(val) => {
						setAccountValue(val);
						onChange(val.accountId.toString());
					}}
					initialOptionId={value}
					appendDropdownToBody
					displayMode={displayMode}
					clearable={false}
				/>
			);
		}
		case RoutingRuleMatchType.InVendorSubscriptionId:
		case RoutingRuleMatchType.InCustomerSubscriptionId:
		case RoutingRuleMatchType.EgVendorSubscriptionId:
		case RoutingRuleMatchType.EgCustomerSubscriptionId: {
			return (
				<SubscriptionSelect
					hideLabel={hideLabel}
					value={subscriptionValue}
					onChange={(val) => {
						setSubscriptionValue(val);
						onChange(val.subscriptionId.toString());
					}}
					serviceIds={[CallflowService.OutboundVendorTermination]}
					initialOptionId={Number(value)}
					appendDropdownToBody
					displayMode={displayMode}
					isClearable={false}
				/>
			);
		}
		case RoutingRuleMatchType.InCustomerRoutePlanId:
		case RoutingRuleMatchType.EgCustomerRoutePlanId: {
			return (
				<RoutePlanSelect
					hideLabel={hideLabel}
					value={routePlanValue}
					onChange={(val) => {
						setRoutePlanValue(val);
						onChange(val.routePlanId.toString());
					}}
					initialOptionId={value}
					appendDropdownToBody
					displayMode={displayMode}
					clearable={false}
				/>
			);
		}
		case RoutingRuleMatchType.InSwitchId: {
			return (
				<SwitchesSelect
					hideLabel={hideLabel}
					value={switchValue}
					onChange={(val) => {
						setSwitchValue(val);
						onChange(val.switchId.toString());
					}}
					initialOptionId={value}
					appendDropdownToBody
					displayMode={displayMode}
					clearable={false}
				/>
			);
		}
		case RoutingRuleMatchType.InGatewayId: {
			return (
				<GatewaySelect
					hideLabel={hideLabel}
					value={gatewayValue}
					onChange={(val) => {
						setGatewayValue(val);
						onChange(val.partitionSipGatewayId.toString());
					}}
					initialOptionId={value}
					appendDropdownToBody
					displayMode={displayMode}
					clearable={false}
				/>
			);
		}
		case RoutingRuleMatchType.FromStd:
		case RoutingRuleMatchType.ToStd: {
			return (
				<RtxTextInput
					type="number"
					className="mb-3"
					label="Pattern"
					displayMode={displayMode}
					inputProps={{
						inputMode: 'numeric',
						pattern: '[0-9]*'
					}}
					value={value}
					onChange={onChange}
					helperText="This is a 'starts-with' match, enter only numbers"
					required={required}
					sx={{
						'& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
							{
								display: 'none'
							},
						'& input[type=number]': {
							MozAppearance: 'textfield'
						}
					}}
				/>
			);
			// return (
			// 	<InputFormControl
			// 		type='number'
			// 		hideLabel={hideLabel}
			// 		label="Pattern"
			// 		value={value}
			// 		placeholder="Enter a number"
			// 		required={required}
			// 		displayMode={displayMode}
			// 		customValidityError={customValidityError}
			// 		onChange={(evt) => onChangeStd(evt)}
			// 	/>
			// );
		}
		default: {
			return (
				<InputFormControl
					hideLabel={hideLabel}
					label="Pattern"
					value={value}
					required={required}
					displayMode={displayMode}
					onChange={onChange}
				/>
			);
		}
	}
};
