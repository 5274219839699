import { RtCommonApiRoutes, RtxApiRoutes } from 'RtExports/routes';
import {
	ReportsIndexRequest,
	ReportsIndexResponse,
	UserFavoriteCreateRequest,
	UserFavoriteDeleteUrlRequest,
	UserFavoriteIndexRequest,
	UserFavoriteIndexResponse
} from 'RtModels';
import {
	handleDeleteRequest,
	handleGetRequest,
	handlePostRequest,
	RequestResponse
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export const fetchReports = async <IncludeFullResponse extends boolean = false>(
	queryParams?: ReportsIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<RequestResponse<ReportsIndexResponse[], IncludeFullResponse>> => {
	return handleGetRequest(RtCommonApiRoutes.Reports.Index, {
		queryParams,
		includeFullResponse
	});
};

export const useGetReports = <IncludeFullResponse extends boolean = false>(
	queryParams?: ReportsIndexRequest,
	includeFullResponse?: IncludeFullResponse
) => {
	return useQuery<
		RequestResponse<ReportsIndexResponse[], IncludeFullResponse>,
		Error
	>(
		[`useGetEntity`, queryParams, includeFullResponse],
		() => fetchReports(queryParams, includeFullResponse),
		{
			enabled: true
		}
	);
};

const fetchUserFavorites = async (
	queryParams?: UserFavoriteIndexRequest
): Promise<UserFavoriteIndexResponse[]> => {
	return handleGetRequest<UserFavoriteIndexResponse[]>(
		RtxApiRoutes.MyProfileFavorites.Index,
		{
			queryParams
		}
	);
};

export const useGetUserFavorites = (
	queryParams?: UserFavoriteIndexRequest,
	enabled?: boolean
) => {
	return useQuery<UserFavoriteIndexResponse[], Error>(
		[`useGetUserFavorites`],
		() => fetchUserFavorites(queryParams),
		{ enabled }
	);
};

export const createUserReportFavorite = (body: UserFavoriteCreateRequest) => {
	return handlePostRequest<UserFavoriteIndexResponse[]>(
		RtxApiRoutes.MyProfileFavorites.Create,
		body
	);
};

export const deleteUserReportFavorite = (
	urlParams: UserFavoriteDeleteUrlRequest
) => {
	return handleDeleteRequest<UserFavoriteIndexResponse[]>(
		RtxApiRoutes.MyProfileFavorites.Delete,
		{
			urlParams
		}
	);
};
