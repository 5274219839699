import { sortBy } from 'lodash-es';
import { useContext, useEffect, useState } from 'react';
import {
	Button,
	Card,
	Col,
	Form,
	OverlayTrigger,
	Row,
	Tooltip
} from 'react-bootstrap';
import {
	RoutingRuleActionMode,
	RoutingRuleActionResponse,
	RoutingRulesActionModesIndexResponse
} from 'RtModels';
import { RoutingRulesActionModeSelect } from 'RtUi/app/rtSip/RoutingRules/lib/controls/RoutingRuleActionModeSelect';
import { RoutingRuleActionSubscriptionEditor } from 'RtUi/app/rtSip/RoutingRules/lib/forms/RoutingRuleActionSubscriptionsEditor';
import { RoutingRuleContext } from 'RtUi/app/rtSip/RoutingRules/lib/forms/RoutingRuleEditor';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';

enum MoveDirection {
	UP,
	DOWN
}

interface IRoutingRulesActionEditorProps {
	displayMode?: boolean;
	isCascade: boolean;
}

export const RoutingRulesActionEditor = ({
	displayMode = false,
	isCascade
}: IRoutingRulesActionEditorProps): JSX.Element => {
	const {
		value: { actions },
		onChange
	} = useContext(RoutingRuleContext);
	const [value, setValue] = useState<RoutingRuleActionResponse[]>(actions);
	const [actionSelect, setActionSelect] = useState<
		Map<number, RoutingRulesActionModesIndexResponse>
	>(new Map());

	useEffect(() => {
		setValue(sortBy(actions, 'priority'));
	}, [actions]);

	const onAddHandler = () => {
		setValue((currentState) => {
			const lastPriority = currentState[currentState.length - 1]?.priority ?? 0;

			const newSubscription = {
				weight: 1,
				subscriptionId: undefined
			};

			const newValue = [
				...currentState,
				{
					priority: lastPriority + 1,
					percentage: 100,
					routingRuleModeId: RoutingRuleActionMode.LcrAll,
					subscriptions: [newSubscription as any]
				}
			];

			onChange('actions', newValue);

			return newValue;
		});
	};

	const onRemoveHandler = (index: number) => {
		setValue((currentState) => {
			const newValue = [...currentState];

			newValue.splice(index, 1);
			onChange('actions', newValue);

			return newValue;
		});
	};

	const onChangeHandler = <K extends keyof RoutingRuleActionResponse>(
		index: number,
		key: K,
		value: RoutingRuleActionResponse[K]
	) => {
		setValue((currentState) => {
			const newValue = [...currentState];

			if (newValue[index][key] !== value) {
				newValue[index][key] = value;
				onChange('actions', newValue);
			}

			return newValue;
		});
	};

	const onMoveHandler = (index: number, direction: MoveDirection) => {
		setValue((currentState) => {
			const newValue = [...currentState];

			const switchIndex =
				direction === MoveDirection.UP ? index - 1 : index + 1;
			const newPriority = newValue[switchIndex].priority;
			const oldPriority = newValue[index].priority;

			newValue[index].priority = newPriority;
			newValue[switchIndex].priority = oldPriority;

			const sortedValue = sortBy(newValue, 'priority');
			onChange('actions', newValue);

			return sortedValue;
		});
	};

	const onChangeActionHandler = (
		index: number,
		action: RoutingRulesActionModesIndexResponse
	) => {
		setActionSelect((currentState) => {
			const newState = new Map(currentState);

			newState.set(index, action);
			onChangeHandler(index, 'routingRuleModeId', action.routingRuleModeId);

			return newState;
		});
	};

	return (
		<Card>
			<Card.Header>
				<div className="d-flex justify-content-end">
					<Button onClick={() => onAddHandler()} disabled={displayMode}>
						<i className="fas fa-fw fa-plus fa-lg" />
						&nbsp;Add Action
					</Button>
				</div>
			</Card.Header>
			<Card.Body>
				<Row>
					<Col>
						{!value.length && <p>No Routing Actions defined.</p>}
						{value.map((action, index) => {
							let className = 'pb-4';
							const isLast = index === value.length - 1;
							if (!isLast) {
								className = className + ' mb-4 border-bottom border-light';
							}

							return (
								<Row className={className} key={index}>
									<Col md={1} className="ps-4 pe-4">
										{index !== 0 && (
											<Row>
												<OverlayTrigger
													overlay={(props) => (
														<Tooltip id={`move-up-${index}`} {...props}>
															Move Up
														</Tooltip>
													)}
												>
													{({ ref, ...triggerHandler }) => (
														<Button
															ref={ref}
															{...triggerHandler}
															className="mb-3"
															variant="primary"
															disabled={displayMode}
															onClick={() =>
																onMoveHandler(index, MoveDirection.UP)
															}
														>
															<i className="fas fa-up-long" />
														</Button>
													)}
												</OverlayTrigger>
											</Row>
										)}
										{!isLast && (
											<Row>
												<OverlayTrigger
													overlay={(props) => (
														<Tooltip id={`move-down-${index}`} {...props}>
															Move Down
														</Tooltip>
													)}
												>
													{({ ref, ...triggerHandler }) => (
														<Button
															ref={ref}
															{...triggerHandler}
															className="mb-3"
															variant="primary"
															disabled={displayMode}
															onClick={() =>
																onMoveHandler(index, MoveDirection.DOWN)
															}
														>
															<i className="fas fa-fw fa-down-long" />
														</Button>
													)}
												</OverlayTrigger>
											</Row>
										)}
										<Row>
											<OverlayTrigger
												overlay={(props) => (
													<Tooltip id={`remove-${index}`} {...props}>
														Remove Action
													</Tooltip>
												)}
											>
												{({ ref, ...triggerHandler }) => (
													<Button
														ref={ref}
														{...triggerHandler}
														className="mb-3"
														variant="danger"
														disabled={displayMode}
														onClick={() => onRemoveHandler(index)}
													>
														<i className="fas fa-fw fa-trash-alt" />
													</Button>
												)}
											</OverlayTrigger>
										</Row>
									</Col>
									<Col>
										<Row>
											<Col md={4} className="d-flex gap-3">
												<Form.Label className="pt-2">%</Form.Label>
												<InputFormControl
													label=""
													hideLabel
													type="number"
													min={1}
													max={100}
													value={String(action.percentage)}
													onChange={(val) =>
														onChangeHandler(index, 'percentage', Number(val))
													}
													displayMode={displayMode}
												/>
											</Col>
											<Col md={8} className="d-flex gap-3">
												<Form.Label className="pt-2">Action Mode</Form.Label>
												<RoutingRulesActionModeSelect
													required
													autoHeight
													displayMode={displayMode}
													label="Action Mode"
													hideLabel
													controlGroupClassName="flex-grow-1"
													clearable={false}
													value={actionSelect.get(index)}
													onChange={(actionMode) =>
														onChangeActionHandler(index, actionMode)
													}
													filterOption={(option) => {
														return !isCascade || option.label === 'BLCK';
													}}
													optionRenderer={(option) => (
														<>
															{option.label} - {option.summary}
														</>
													)}
													initialOptionId={String(action.routingRuleModeId)}
													appendDropdownToBody
												/>
											</Col>
										</Row>
										<RoutingRuleActionSubscriptionEditor
											subscriptions={action.subscriptions}
											displayMode={displayMode}
											onChange={(subs) =>
												onChangeHandler(index, 'subscriptions', subs)
											}
										/>
									</Col>
								</Row>
							);
						})}
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};
