import { noop, unset } from 'lodash-es';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import {
	$RatePlanDefaultCreateRequest,
	CountryIndexResponse,
	RatePlanDefaultCreateRequest,
	RatePlanDefaultProfileResponse,
	RatePlanDefaultUpdateRequest,
	RatePlanIndexResponse
} from 'RtModels';
import { RatePlanDefaultResource } from 'RtUi/app/AccountManagement/RatePlan/RatePlanDefaults/resources/RatePlanDefaultResource';
import { RtxForm } from 'RtUi/components/rtx/form';
import { RtxDateTimeInput } from 'RtUi/components/rtx/inputs/DateTime/RtxDateTimeInput';
import { RtxPrecisionInput } from 'RtUi/components/rtx/inputs/Precision/RtxPrecisionInput';
import { RtxRateInput } from 'RtUi/components/rtx/inputs/RtxRateInput';
import { RtxCountrySelect } from 'RtUi/components/rtx/inputs/Select/instances/Country/RtxCountrySelect';
import { RtxRatePlanSelect } from 'RtUi/components/rtx/inputs/Select/instances/RatePlan/RtxRatePlanSelect';
import {
	RegionIndexResponse,
	RtxRegionSelect
} from 'RtUi/components/rtx/inputs/Select/instances/RtxRegionSelect';
import { RtxTextInput } from 'RtUi/components/rtx/inputs/Text/RtxTextInput';
import { RtError } from 'RtUi/utils/errors/RtError';

interface IRatePlanDefaultEditFormProps {
	ratePlanId: number;
	profile?: RatePlanDefaultProfileResponse;
	onSuccess?: (profile: RatePlanDefaultProfileResponse) => void;
	onCancel?: () => void;
}

type RatePlanDefaultEditFormValue =
	| RatePlanDefaultUpdateRequest
	| RatePlanDefaultCreateRequest;

export const RatePlanDefaultEditForm = ({
	ratePlanId,
	profile,
	onSuccess = noop
}: IRatePlanDefaultEditFormProps) => {
	const [displayMode, setDisplayMode] = useState<boolean>(
		profile?.ratePlanDefaultId !== undefined
	);
	const [ratedIso, setRatedIso] = useState<CountryIndexResponse>();
	const [jurisIso, setJurisIso] = useState<CountryIndexResponse>();

	const [ratedRegionId, setRatedRegionId] = useState<RegionIndexResponse>();
	const [jurisRegionId, setJurisRegionId] = useState<RegionIndexResponse>();
	const [altRatePlanId, setAltRatePlanId] = useState<RatePlanIndexResponse>();

	const [valuesRequired, setValuesRequired] = useState<boolean>(true);

	const [ratedExt, setRatedExt] = useState<string | null | undefined>(
		profile?.ratedExt
	);
	const [jurisExt, setJurisExt] = useState<string | null | undefined>(
		profile?.jurisExt
	);

	const [durMin, setDurMin] = useState<number | null | undefined>(
		profile?.durMin
	);
	const [durInc, setDurInc] = useState<number | null | undefined>(
		profile?.durInc
	);

	const [error, setError] = useState<RtError>();

	const setDuration = (iso: string | undefined) => {
		if (!iso) {
			return;
		}

		let val = 60;
		if (iso === 'USA' || iso === 'CAN' || iso === 'PRI') {
			val = 6;
		}
		if (!durMin) {
			setDurMin(val);
		}
		if (!durInc) {
			setDurInc(val);
		}
	};

	const onSubmit = async (data: RatePlanDefaultEditFormValue) => {
		const ratePlanDefaultResource = new RatePlanDefaultResource();
		setError(undefined);
		if (data.altRatePlanId !== undefined) {
			unset(data, 'conFee');
			unset(data, 'interRate');
			unset(data, 'intraRate');
			unset(data, 'indetRate');
			unset(data, 'durMin');
			unset(data, 'durInc');
			unset(data, 'localRate');
			unset(data, 'inGraceDur');
			unset(data, 'egGraceDur');
		}

		let newProfile: RatePlanDefaultProfileResponse;
		try {
			if (profile) {
				newProfile = await ratePlanDefaultResource.update(
					profile.ratePlanId,
					profile.ratePlanDefaultId,
					data as RatePlanDefaultUpdateRequest
				);
			} else {
				newProfile = await ratePlanDefaultResource.create(
					ratePlanId,
					data as RatePlanDefaultCreateRequest
				);
			}
			setDisplayMode(true);
			onSuccess(newProfile);
		} catch (error: any) {
			setError(new RtError(error));
		}
	};

	return (
		<Row>
			<Col md={12}>
				<RtxForm<RatePlanDefaultEditFormValue>
					displayMode={displayMode}
					error={
						error && {
							name: 'root',
							error: {
								message: error.message
							}
						}
					}
					onSubmit={onSubmit}
					createMode={profile === undefined}
					onEdit={() => setDisplayMode(false)}
					onCancel={() => setDisplayMode(true)}
					defaultValues={{
						...profile,
						ratedExt: profile?.ratedExt ?? undefined,
						jurisIso: profile?.jurisIso ?? undefined,
						jurisExt: profile?.jurisExt ?? undefined,
						altRatePlanId: profile?.altRatePlanId ?? undefined
					}}
				>
					{({ control }) => (
						<>
							<Row>
								<Col lg={7}>
									<Controller
										control={control}
										name="startTs"
										render={({ field: { value, onChange } }) => (
											<RtxDateTimeInput
												required={
													$RatePlanDefaultCreateRequest.properties.startTs
														.isRequired
												}
												className="mb-3"
												label="Start Time"
												displayMode={displayMode}
												value={value}
												onChange={onChange}
											/>
										)}
									/>
									<Controller
										control={control}
										name="endTs"
										render={({ field: { value, onChange } }) => (
											<RtxDateTimeInput
												required={false}
												className="mb-3"
												label="End Time"
												displayMode={displayMode}
												value={value}
												onChange={onChange}
												clearable={true}
											/>
										)}
									/>
								</Col>
							</Row>
							<> </>
							<Row>
								<Col md={6}>
									<Controller
										control={control}
										name="ratedRegionId"
										render={({ field: { value, onChange } }) => (
											<RtxRegionSelect
												label="Rated Region"
												value={ratedRegionId}
												required={false}
												onChange={(val) => {
													setRatedRegionId(val);
													onChange(val?.regionId);
												}}
												isClearable={true}
												initialOptionId={value ?? undefined}
												isDisabled={Boolean(
													(ratedIso && ratedIso.iso3166Alpha3) || ratedExt
												)}
											/>
										)}
									/>
									<br />
									<br />
									<Controller
										control={control}
										name="ratedIso"
										render={({ field: { value, onChange } }) => (
											<RtxCountrySelect
												label="Rated Country"
												value={ratedIso}
												required={false}
												onChange={(val) => {
													setRatedIso(val);
													onChange(val?.iso3166Alpha3);
													setDuration(val?.iso3166Alpha3);
												}}
												isClearable={true}
												initialOptionId={value ?? undefined}
												isDisabled={Boolean(
													ratedRegionId && ratedRegionId.regionId
												)}
											/>
										)}
									/>
									<br />
									<br />
									<Controller
										control={control}
										name="ratedExt"
										render={({ field: { value } }) => (
											<RtxTextInput
												label="Rated Area (Country Specific - NANP State)"
												className="mb-3"
												displayMode={displayMode}
												onChange={(value) => setRatedExt(value)}
												value={value ?? null}
												disabled={Boolean(
													ratedRegionId && ratedRegionId.regionId
												)}
											/>
										)}
									/>
									<br />
									<br />
									<Controller
										control={control}
										name="jurisRegionId"
										render={({ field: { value, onChange } }) => (
											<RtxRegionSelect
												label="Juris Region"
												value={jurisRegionId}
												required={false}
												onChange={(val) => {
													setJurisRegionId(val);
													onChange(val?.regionId);
												}}
												isClearable={true}
												initialOptionId={value ?? undefined}
												isDisabled={Boolean(
													(jurisIso && jurisIso.iso3166Alpha3) || jurisExt
												)}
											/>
										)}
									/>
									<br />
									<br />
									<Controller
										control={control}
										name="jurisIso"
										render={({ field: { value, onChange } }) => (
											<RtxCountrySelect
												label="Juris Country"
												value={jurisIso}
												onChange={(val) => {
													setJurisIso(val);
													onChange(val?.iso3166Alpha3);
												}}
												isClearable={true}
												initialOptionId={value ?? undefined}
												isDisabled={Boolean(
													jurisRegionId && jurisRegionId.regionId
												)}
											/>
										)}
									/>
									<br />
									<br />
									<Controller
										control={control}
										name="jurisExt"
										render={({ field: { value } }) => (
											<RtxTextInput
												label="Juris Area (Country Specific - NANP State)"
												className="mb-3"
												displayMode={displayMode}
												onChange={(value) => setJurisExt(value)}
												value={value}
												disabled={Boolean(
													jurisRegionId && jurisRegionId.regionId
												)}
											/>
										)}
									/>
									<br />
									<br />
									<Controller
										control={control}
										name="altRatePlanId"
										render={({ field: { value, onChange } }) => (
											<RtxRatePlanSelect
												label="Alt Rate Plan Id"
												className="mb-3"
												displayMode={displayMode}
												onChange={(
													altRatePlan: RatePlanIndexResponse | undefined
												) => {
													setAltRatePlanId(altRatePlan);
													onChange(altRatePlan?.ratePlanId);
													if (altRatePlan && altRatePlan.ratePlanId) {
														setValuesRequired(false);
													} else {
														setValuesRequired(true);
													}
												}}
												value={altRatePlanId}
												initialOptionId={value ?? undefined}
												isClearable={true}
											/>
										)}
									/>
								</Col>
								{valuesRequired && (
									<Col md={6}>
										<Controller
											control={control}
											name="conFee"
											render={({ field: { value, onChange } }) => (
												<RtxRateInput
													label="Connection Fee"
													displayMode={displayMode}
													required={valuesRequired}
													onChange={onChange}
													value={value}
												/>
											)}
										/>
										<Controller
											control={control}
											name="interRate"
											render={({ field: { value, onChange } }) => (
												<RtxRateInput
													label="InterState Rate"
													displayMode={displayMode}
													required={valuesRequired}
													onChange={onChange}
													value={value}
												/>
											)}
										/>
										<Controller
											control={control}
											name="intraRate"
											render={({ field: { value, onChange } }) => (
												<RtxRateInput
													label="IntraState Rate"
													displayMode={displayMode}
													required={valuesRequired}
													onChange={onChange}
													value={value}
												/>
											)}
										/>
										<Controller
											control={control}
											name="indetRate"
											render={({ field: { value, onChange } }) => (
												<RtxRateInput
													label="Indeterminate Rate"
													displayMode={displayMode}
													required={valuesRequired}
													onChange={onChange}
													value={value}
												/>
											)}
										/>
										<Controller
											control={control}
											name="localRate"
											render={({ field: { value, onChange } }) => (
												<RtxRateInput
													label="Local Rate"
													displayMode={displayMode}
													required={valuesRequired}
													onChange={onChange}
													value={value}
												/>
											)}
										/>
										<Controller
											control={control}
											name="durMin"
											render={({ field: { value, onChange } }) => (
												<RtxPrecisionInput
													min={1}
													max={60}
													label="Duration Minimum"
													className="mb-3"
													displayMode={displayMode}
													required={valuesRequired}
													onChange={onChange}
													value={value}
												/>
											)}
										/>
										<Controller
											control={control}
											name="durInc"
											render={({ field: { value, onChange } }) => (
												<RtxPrecisionInput
													min={1}
													max={60}
													label="Duration Increment"
													className="mb-3"
													displayMode={displayMode}
													required={valuesRequired}
													onChange={onChange}
													value={value}
												/>
											)}
										/>
										<Controller
											control={control}
											name="inGraceDur"
											render={({ field: { value, onChange } }) => (
												<RtxPrecisionInput
													label="Ingress Grace Duration"
													className="mb-3"
													displayMode={displayMode}
													required={valuesRequired}
													onChange={onChange}
													value={value}
												/>
											)}
										/>
										<Controller
											control={control}
											name="egGraceDur"
											render={({ field: { value, onChange } }) => (
												<RtxPrecisionInput
													label="Egress Grace Duration"
													className="mb-3"
													displayMode={displayMode}
													required={valuesRequired}
													onChange={onChange}
													value={value}
												/>
											)}
										/>
									</Col>
								)}
							</Row>
						</>
					)}
				</RtxForm>
			</Col>
		</Row>
	);
};
