import { RoutingGroupProfileResponse, RoutingTemplateTypes } from 'RtModels';
import { NumberGrid } from 'RtUi/app/rt800/Numbers/lib/grids/NumberGrid';
import { useGetRoutingGroup } from 'RtUi/app/rt800/RoutingGroups/lib/services';
import { TasksGrid } from 'RtUi/app/rt800/Tasks/lib/grids/TasksGrid';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { Loading } from 'RtUi/components/ui/Loading';
import {
	TabbedLayout,
	TabbedLayoutHelpContainer,
	TabbedLayoutTab
} from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { subYears } from 'date-fns';
// import { useNavigate } from 'react-router-dom';
import { RoutingGroupRouter } from './RoutingGroup.router';
import { RoutingGroupEditor } from './lib/forms/RoutingGroupEditor';
import { RoutingGroupTemplatesGrid } from 'RtUi/app/rt800/RoutingGroups/lib/grids/RoutingGroupTemplatesGrid';

export const RoutingGroupProfileContainer = () => {
	const [tabs, activeTab, setActiveTab] = useTabs(
		RoutingGroupRouter.getProfileTabs()
	);
	const routingGroupId = useIdParam();
	const {
		data: routingGroup,
		isFetching,
		refetch
	} = useGetRoutingGroup({ routingGroupId: Number(routingGroupId) });
	// const navigate = useNavigate();

	const workingTemplate = routingGroup?.templates.find(
		(t) => t.routingTemplateTypeId === RoutingTemplateTypes.Working
	);

	if (isFetching) {
		return <Loading />;
	}

	if (!routingGroup) {
		return <p>Not Found</p>;
	}

	const templateName = workingTemplate
		? workingTemplate.templateName
		: undefined;

	const resourceParams = { templateName };

	const onUpdateSuccess = () => {
		refetch();
		setActiveTab(tabs.Profile.header);
	};

	return (
		<TabbedLayout<RoutingGroupProfileResponse>
			router={RoutingGroupRouter}
			profile={routingGroup}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Routing}>
				<RoutingGroupTemplatesGrid
					resourceParams={{ routingGroupId: parseInt(routingGroupId, 10) }}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Profile}>
				<RoutingGroupEditor
					editMode={routingGroup}
					onUpdateSuccess={() => onUpdateSuccess()}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Numbers}>
				<NumberGrid resourceParams={resourceParams} />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.History}>
				<TasksGrid
					resourceParams={{
						referenceKey: templateName,
						scheduledAfterTs: subYears(new Date(), 3)
					}}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutHelpContainer tab={tabs.Profile.header}>
				<dl>
					<dt>Label</dt>
					<dd className="text-muted mb-2">
						The visible name of the Routing Group.
					</dd>
					<dt>RespOrg</dt>
					<dd className="text-muted mb-2">
						Restricts user access based on permissions.
					</dd>
					<dt>Working Routing Profile</dt>
					<dd className="text-muted mb-2">
						The Template holding numbers managed in this Routing Group.
					</dd>
					<dt>Default Routing Profile</dt>
					<dd className="text-muted mb-2">
						The preferred template routing profile.
					</dd>
					<dt>Alternate Templates</dt>
					<dd className="text-muted mb-2">
						User-defined routing options for activation in a disaster.
					</dd>
				</dl>
			</TabbedLayoutHelpContainer>
		</TabbedLayout>
	);
};

RoutingGroupRouter.setProfileRtUiFunctionalComponent(
	RoutingGroupProfileContainer
);
