import { ManagedEntityIndexResponse } from 'RtModels';
import { EntityIdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/EntityIdDataGridColumn';
import {
	DataGridColumn,
	RowThemeColor
} from 'RtUi/components/data/DataGrid/types';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { PillDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PillDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getManagedEntitiesColumns = (): Array<
	DataGridColumn<ManagedEntityIndexResponse>
> => [
	EntityIdDataGridColumn({
		accessorKey: 'entityId',
		header: 'Entity'
	}),
	LabelDataGridColumn({
		accessorKey: 'summary',
		header: 'Summary'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isManaged',
		header: 'Managed'
	}),
	PillDataGridColumn({
		accessorKey: 'apiStatus',
		header: 'API Status',
		themeValues: {
			Failing: RowThemeColor.DANGER,
			Working: RowThemeColor.SUCCESS
		},
		defaultTheme: RowThemeColor.WARNING
	}),
	PillDataGridColumn({
		accessorKey: 'sqlStatus',
		header: 'SQL Status',
		themeValues: {
			Failing: RowThemeColor.DANGER,
			Working: RowThemeColor.SUCCESS
		},
		defaultTheme: RowThemeColor.WARNING
	}),
	DefaultDataGridColumn({
		accessorKey: 'apiResourceLabel',
		header: 'API Resource',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'contactName',
		header: 'Contact Name'
	}),
	DefaultDataGridColumn({
		accessorKey: 'contactNumber',
		header: 'Contact Number'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'lastApiAttemptTs',
		header: 'Last API Attempt'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'lastApiSuccessTs',
		header: 'Last API Success'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'lastSqlAttemptTs',
		header: 'Last SQL Attempt'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'lastSqlSuccessTs',
		header: 'Last SQL Success'
	}),
	DefaultDataGridColumn({
		accessorKey: 'sqlSyncIntervalQty',
		header: 'SQL Sync Qty'
	}),
	DefaultDataGridColumn({
		accessorKey: 'sqlSyncIntervalType',
		header: 'SQL Sync Interval'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'sqlNextSyncTs',
		header: 'Sql Next Sync'
	})
];
