import { RoboscoringRouter } from 'RtUi/app/rtVue/Roboscoring/Roboscoring.router';
import { RoboscoringGrid } from 'RtUi/app/rtVue/Roboscoring/lib/grids/RoboscoringGrid';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';

export function RoboscoringIndexContainer() {
	return (
		<StandardLayout router={RoboscoringRouter}>
			<RoboscoringGrid />
		</StandardLayout>
	);
}

RoboscoringRouter.setIndexRtUiFunctionalComponent(RoboscoringIndexContainer, {
	groupName: 'Configuration'
});
