import { RtDashboardRouter } from 'RtUi/app/rtCommon/Dashboard/Dashboard.router';
import { DashboardIndexContainer } from 'RtUi/app/rtCommon/Dashboard/Dashboard.index';
import { RtUiMenuPriority, RtUiModule } from 'RtUi/app/@RtUi/RtUiDecorators';

@RtUiModule({
	route: {
		iconClass: '',
		name: 'Dashboard Details',
		singleMode: true,
		orderPriority: RtUiMenuPriority.RT_DASHBOARD
	},
	controllers: [DashboardIndexContainer],
	routers: [RtDashboardRouter]
})
export class RtCommonDashboardModule {}
