/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianUserQueryScheduleCreateRequest = {
    properties: {
        repeatIntervalTypeId: {
            type: 'ScheduleRepeatIntervalType',
            isRequired: true,
        },
        nextRunTime: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        recipients: {
            properties: {
            },
            isRequired: true,
            isNullable: true,
        },
    },
};