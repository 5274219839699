/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ReportsProfileResponse = {
    properties: {
        guardianUserQueryId: {
            type: 'number',
            isRequired: true,
        },
        userId: {
            type: 'number',
            isRequired: true,
        },
        globalGuardianUserQueryId: {
            type: 'number',
        },
        reportId: {
            type: 'Reports',
            isRequired: true,
        },
        queryParameters: {
            type: 'GuardianUserQueryParams',
            isRequired: true,
        },
        isDefaultView: {
            type: 'number',
            isRequired: true,
        },
        isEmail: {
            type: 'number',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        label: {
            type: 'string',
        },
        summary: {
            type: 'string',
        },
        scheduleId: {
            type: 'number',
        },
        queryType: {
            type: 'string',
            isRequired: true,
        },
        isFavorite: {
            type: 'number',
        },
        queryDescription: {
            type: 'string',
            isRequired: true,
        },
        schedule: {
            properties: {
            },
            isRequired: true,
            isNullable: true,
        },
    },
};