import { RtUiMenuPriority, RtUiModule } from 'RtUi/app/@RtUi/RtUiDecorators';
import { CallSimulatorContainer } from './CallSimulator/CallSimulator.index';
import { TemplateCprsRouter, TollFreeCprsRouter } from './Cprs/Cprs.router';
import { TemplateCprsProfileContainer } from './Cprs/TemplateCprs.profile';
import { TollFreeCprsProfileContainer } from './Cprs/TollFreeCprs.profile';
import { CprSearchIndexContainer } from './CprSearch/CprSearch.index';
import { EntityIndexContainer } from './Entities/Entity.index';
import { EntityProfileContainer } from './Entities/Entity.profile';
import { EntityRouter } from './Entities/Entity.router';
import { NumberGroupIndexContainer } from './NumberGroups/NumberGroup.index';
import { NumberGroupProfileContainer } from './NumberGroups/NumberGroup.profile';
import { NumberGroupRouter } from './NumberGroups/NumberGroup.router';
import { NumberIndexContainer } from './Numbers/Number.index';
import { NumberProfileContainer } from './Numbers/Number.profile';
import { NumberRouter } from './Numbers/Number.router';
import { RocRequestIndexContainer } from './RespOrgChange/RocRequest.index';
import { RocRequestProfileContainer } from './RespOrgChange/RocRequest.profile';
import { RocRequestRouter } from './RespOrgChange/RocRequest.router';
import { RespOrgIndexContainer } from './RespOrgs/RespOrg.index';
import { RespOrgProfileContainer } from './RespOrgs/RespOrg.profile';
import { RespOrgRouter } from './RespOrgs/RespOrg.router';
import { CloneRoutingIndexContainer } from './Routing/CloneRouting.index';
import { RoutingGroupIndexContainer } from './RoutingGroups/RoutingGroup.index';
import { RoutingGroupProfileContainer } from './RoutingGroups/RoutingGroup.profile';
import { RoutingGroupRouter } from './RoutingGroups/RoutingGroup.router';
import { Rt800Router } from './rt800.router';
import { SearchAndReserveIndexContainer } from './SearchAndReserve/SearchAndReserve.index';
import { TaskIndexContainer } from './Tasks/Task.index';
import { TaskProfileContainer } from './Tasks/Task.profile';
import { TaskRouter } from './Tasks/Task.router';
import { TemplateModule } from 'RtUi/app/rt800/Templates/Template.module';

@RtUiModule({
	route: {
		path: `/${Rt800Router.SectionKey}`,
		name: 'RT/800 Toll-Free',
		iconClass: 'fa-phone',
		orderPriority: RtUiMenuPriority.RT_800
	},
	imports: [TemplateModule],
	controllers: [
		CallSimulatorContainer,
		CloneRoutingIndexContainer,
		CprSearchIndexContainer,
		EntityIndexContainer,
		EntityProfileContainer,
		NumberGroupIndexContainer,
		NumberGroupProfileContainer,
		NumberIndexContainer,
		NumberProfileContainer,
		RocRequestIndexContainer,
		RocRequestProfileContainer,
		RespOrgIndexContainer,
		RespOrgProfileContainer,
		RoutingGroupIndexContainer,
		RoutingGroupProfileContainer,
		SearchAndReserveIndexContainer,
		TaskIndexContainer,
		TaskProfileContainer,
		TollFreeCprsProfileContainer,
		TemplateCprsProfileContainer
	],
	routers: [
		TemplateCprsRouter,
		TollFreeCprsRouter,
		EntityRouter,
		NumberGroupRouter,
		NumberRouter,
		RocRequestRouter,
		RespOrgRouter,
		RoutingGroupRouter,
		TaskRouter
	]
})
export class Rt800Module {}
