import {
	GuardianAlertScheduleIndexResponse,
	GuardianAlertScheduleProfileResponse,
	GuardianAlertScheduleCreateRequest,
	GuardianAlertScheduleUpdateRequest,
	GuardianAlertScheduleProfileUrlRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { RtVueApiRoutes } from 'RtExports/routes';
import moment from 'moment';

export class AlertScheduleResource extends HttpResource<
	GuardianAlertScheduleIndexResponse,
	GuardianAlertScheduleProfileResponse
> {
	constructor() {
		super(RtVueApiRoutes.GuardianAlertsSchedules);
	}

	public create(
		alertScheduleRuleCreateRequest: GuardianAlertScheduleCreateRequest
	) {
		const body = JSON.stringify(alertScheduleRuleCreateRequest);

		return this.fetchWithRoute<GuardianAlertScheduleProfileResponse>(
			RtVueApiRoutes.GuardianAlertsSchedules.Create,
			{
				body
			}
		);
	}

	public get(guardianAlertScheduleId: number) {
		const urlParams: GuardianAlertScheduleProfileUrlRequest = {
			guardianAlertScheduleId
		};
		return this.fetchWithRoute<GuardianAlertScheduleProfileResponse>(
			RtVueApiRoutes.GuardianAlertsSchedules.Profile,
			{
				urlParams
			}
		);
	}

	public update(
		guardianAlertScheduleId: number,
		alertRuleUpdateRequest: GuardianAlertScheduleUpdateRequest
	) {
		const urlParams: GuardianAlertScheduleProfileUrlRequest = {
			guardianAlertScheduleId
		};

		const body = JSON.stringify(alertRuleUpdateRequest);

		return this.fetchWithRoute<GuardianAlertScheduleProfileResponse>(
			RtVueApiRoutes.GuardianAlertsSchedules.Update,
			{
				body,
				urlParams
			}
		);
	}

	public delete(guardianAlertScheduleId: number) {
		const urlParams: GuardianAlertScheduleProfileUrlRequest = {
			guardianAlertScheduleId
		};

		return this.fetchWithRoute(RtVueApiRoutes.GuardianAlertsSchedules.Delete, {
			urlParams
		});
	}

	public createNewProfile(
		guardianAlertId: number
	): GuardianAlertScheduleCreateRequest {
		return {
			guardianAlertId: guardianAlertId,
			isSunday: 1,
			isMonday: 1,
			isTuesday: 1,
			isWednesday: 1,
			isThursday: 1,
			isFriday: 1,
			isSaturday: 1,
			isActive: 0,
			startTime: moment().startOf('day').format('HH:mm'),
			endTime: moment().endOf('day').format('HH:mm')
		};
	}
}
