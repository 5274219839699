import { FileProcessorType } from 'RtModels';
import { LrnLookupRouter } from 'RtUi/app/rtVue/LrnLookup/LrnLookup.router';
import { LrnLookupUpload } from 'RtUi/app/rtVue/LrnLookup/lib/forms/LrnLookupUpload';
import { LrnLookupGrid } from 'RtUi/app/rtVue/LrnLookup/lib/grids/LrnLookupGrid';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { FC } from 'react';

export const LrnLookupIndexContainer: FC<{}> = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Results: { header: 'Results', isDefault: true },
		Upload: { header: 'Upload', isAction: true }
	});

	return (
		<TabbedLayout
			router={LrnLookupRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab header={tabs.Results.header}>
				<LrnLookupGrid
					pageName="rateSheet-lrnLookup"
					router={LrnLookupRouter}
					resourceParams={{
						fileProcessTypeId: FileProcessorType.LrnLookup
					}}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Upload}>
				<LrnLookupUpload
					onCancel={() => {
						setActiveTab(tabs.Results.header);
					}}
					onSuccess={() => {
						setActiveTab(tabs.Results.header);
					}}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

LrnLookupIndexContainer.displayName = 'LrnLookupIndexContainer';

LrnLookupRouter.setIndexRtUiFunctionalComponent(LrnLookupIndexContainer, {
	groupName: 'Helpful Tools',
	groupId: 'helpfulTools'
});
