import * as React from 'react';
import { CpsIndexResponseData, Reports } from 'RtModels';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import { RtVueReportContainer } from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import { CpsViewRouter } from 'RtUi/app/rtVue/CpsView/CpsView.router';
import { CpsTotalsLineChart } from 'RtUi/app/rtVue/CpsView/lib/charts/CpsTotalLineChart';
import { CpsDataGrid } from 'RtUi/app/rtVue/CpsView/lib/grids/CpsDataGrid';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { CdrSearchViewRouter } from 'RtUi/app/rtVue/CdrSearchView/CdrSearchView.router';
import { RtVueReportUrlSearchParamsCreate } from 'RtUi/app/rtVue/common/lib/containers/lib/RtVueReportUrlSearchParams';

@CpsViewRouter.getIndexRtUiController({
	groupName: 'Network Reports',
	groupId: 'VueNetworkReports',
	groupOrderPriority: 1
})
export class CpsViewIndexContainer extends RtVueReportContainer<CpsIndexResponseData> {
	public state = this.getBaseState();

	protected reportId = Reports.Cps;
	protected router = CpsViewRouter;

	protected async getResource(): Promise<HttpResource<CpsIndexResponseData>> {
		const {
			timeRange,
			filters,
			partition,
			preset,
			dataSums,
			additionalColumns
		} = this.state;

		// Add direction column when trunk group is selected in order to drill down base on it
		if (
			additionalColumns.some((e) => e.value === 'trunkGroupId') &&
			!additionalColumns.some((a) => a.value === 'direction')
		) {
			additionalColumns.push({
				label: 'Direction',
				value: 'direction'
			});
		}

		const vueHttp = new RtVueHttp();
		const { data: data, totalCount } = await vueHttp.getCpsView(
			timeRange,
			filters,
			dataSums,
			preset,
			partition,
			additionalColumns?.map((column) => column.value)
		);
		const cpsResource = new ArrayResource<CpsIndexResponseData>(
			'callDate',
			data.data
		);

		cpsResource.setTotalCount(totalCount);

		return cpsResource;
	}

	protected onDrillDown = (row: CpsIndexResponseData) => {
		const searchParams: RtVueReportUrlSearchParamsCreate = {
			date: row.callDate
		};

		if (row.direction === 'ingress') {
			searchParams.ingressTrunkGroupId = row.trunkGroupId;
		} else if (row.direction === 'egress') {
			searchParams.egressTrunkGroupId = row.trunkGroupId;
		}

		const drillDownPath = CdrSearchViewRouter.getDrillDownPath(searchParams);

		this.goToPath(drillDownPath);
	};

	protected renderReports(): React.ReactNode {
		return (
			<>
				<CpsTotalsLineChart resource={this.state.resource} />
				<CpsDataGrid
					isLoading={this.state.loadingReport}
					defaultSorting={this.state.defaultSorting}
					onSortingChange={this.onSortedChange}
					resource={this.state.resource}
					callDateFormat={this.getCallDateFormat()}
					onDrillDown={this.onDrillDown}
				/>
			</>
		);
	}
}
