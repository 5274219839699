import { CdrRecurringExportIndexContainer } from 'RtUi/app/Administration/CdrRecurringExport/CdrRecurringExport.index';
import { CdrRecurringExportProfileContainer } from 'RtUi/app/Administration/CdrRecurringExport/CdrRecurringExport.profile';
import { CdrRecurringExportRouter } from 'RtUi/app/Administration/CdrRecurringExport/CdrRecurringExport.router';
import { RtUiMenuPriority, RtUiModule } from '../@RtUi/RtUiDecorators';
import { AdminUserProfileContainer } from './Users/AdminUser.profile';
import { AdminUserRouter } from './Users/AdminUser.router';
import { AdminUserIndexContainer } from './Users/AdminUser.index';
import { DistributionManagementProfileContainer } from 'RtUi/app/Administration/DistributionManagement/DistributionManagement.profile';
import { DistributionManagementRouter } from 'RtUi/app/Administration/DistributionManagement/DistributionManagement.router';
import { DistributionManagementIndexContainer } from 'RtUi/app/Administration/DistributionManagement/DistributionManagement.index';
import { CicLookupRouter } from 'RtUi/app/Administration/CicLookup/CicLookup.router';
import { CicLookupIndexContainer } from 'RtUi/app/Administration/CicLookup/CicLookup.index';
import { CdrOneTimeExportRouter } from 'RtUi/app/Administration/CdrOneTimeExport/CdrOneTimeExport.router';
import { CdrOneTimeExportIndexContainer } from 'RtUi/app/Administration/CdrOneTimeExport/CdrOneTimeExport.index';
import { SipAgentRouter } from 'RtUi/app/AccountManagement/SipAgents/SipAgent.router';
import { SipAgentConfigRouter } from 'RtUi/app/AccountManagement/SipAgents/SipAgentConfig.router';
import { SipAgentIndexContainer } from 'RtUi/app/AccountManagement/SipAgents/SipAgent.index';
import { SipAgentProfileContainer } from 'RtUi/app/AccountManagement/SipAgents/SipAgent.profile';
import { SipAgentConfigProfileContainer } from 'RtUi/app/AccountManagement/SipAgents/SipAgentConfig.profile';
import { SwitchRouter } from 'RtUi/app/AccountManagement/Switches/Switch.router';
import { SwitchIndexContainer } from 'RtUi/app/AccountManagement/Switches/Switch.index';
import { SwitchProfileContainer } from 'RtUi/app/AccountManagement/Switches/Switch.profile';

@RtUiModule({
	route: {
		path: '',
		iconClass: 'fa-user-shield',
		name: 'Administration',
		orderPriority: RtUiMenuPriority.RT_BOTTOM
	},
	controllers: [
		CdrRecurringExportIndexContainer,
		CdrRecurringExportProfileContainer,
		DistributionManagementIndexContainer,
		DistributionManagementProfileContainer,
		AdminUserProfileContainer,
		AdminUserIndexContainer,
		CicLookupIndexContainer,
		CdrOneTimeExportIndexContainer,
		SipAgentIndexContainer,
		SipAgentProfileContainer,
		SipAgentConfigProfileContainer,
		SwitchProfileContainer,
		SwitchIndexContainer
	],
	routers: [
		CdrRecurringExportRouter,
		DistributionManagementRouter,
		AdminUserRouter,
		CicLookupRouter,
		CdrOneTimeExportRouter,
		SipAgentRouter,
		SipAgentConfigRouter,
		SwitchRouter
	]
})
export class AdministrationModule {}
