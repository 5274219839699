import {
	RtxPrecisionInput,
	RtxPrecisionInputProps
} from 'RtUi/components/rtx/inputs/Precision/RtxPrecisionInput';
type RtxPRateInputProps = Omit<RtxPrecisionInputProps, ''> & {
	onChange?: (value: number | null) => void;
	allowCurrency?: boolean;
	displayMode?: boolean;
	label: string;
};
export const RtxRateInput = ({
	disabled,
	label,
	className,
	value,
	onChange = () => {},
	displayMode,
	...props
}: RtxPRateInputProps) => {
	const useClassName = className || 'mb-3 inline-form';

	return (
		<RtxPrecisionInput
			precision={6}
			label={label}
			disabled={disabled}
			className={useClassName}
			displayMode={displayMode}
			required={props.required}
			onChange={onChange}
			value={value}
		/>
	);
};
