/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianAlertScheduleUpdateRequest = {
    properties: {
        guardianAlertId: {
            type: 'number',
        },
        isSunday: {
            type: 'number',
        },
        isMonday: {
            type: 'number',
        },
        isTuesday: {
            type: 'number',
        },
        isWednesday: {
            type: 'number',
        },
        isThursday: {
            type: 'number',
        },
        isFriday: {
            type: 'number',
        },
        isSaturday: {
            type: 'number',
        },
        isActive: {
            type: 'number',
        },
        startTime: {
            type: 'string',
            isNullable: true,
        },
        endTime: {
            type: 'string',
            isNullable: true,
        },
    },
};