import { Tooltip } from '@mui/material';
import * as React from 'react';
import { Button, Dropdown, Form, InputGroup } from 'react-bootstrap';
import { Permissions } from 'RtExports/routes';
import {
	DataFilter,
	DataPreset,
	GuardianUserQueryIndexResponse,
	GuardianUserQueryParams,
	PartitionIndexResponse,
	ReportAggregates,
	Reports,
	TimeFilter,
	UserPageConfigurationParametersSortingOrder
} from 'RtModels';
import { RtVueReportUrlSearchParams } from 'RtUi/app/rtVue/common/lib/containers/lib/RtVueReportUrlSearchParams';
import { GuardianUserQuerySelect } from 'RtUi/app/rtVue/common/lib/controls/GuardianUserQuerySelect';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import { CheckboxFormControl } from 'RtUi/components/form/CheckboxFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { ISimpleSelectFormControlOption } from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { TextAreaFormControl } from 'RtUi/components/form/TextAreaFormControl';
import { RadioButtons } from 'RtUi/components/ui/RadioButtons';
import { UserActions } from 'RtUi/state/actions/user';
import { generateUniqueReactKey } from 'RtUi/utils/http/resources/utils';

interface IVueSavedQuerySelectProps {
	reportId: Reports;
	filters?: DataFilter[];
	timeRange?: TimeFilter;
	preset?: DataPreset;
	dataSums?: ReportAggregates[];
	userQuery?: GuardianUserQueryIndexResponse;
	additionalColumns?: Array<ISimpleSelectFormControlOption<string>>;
	defaultSorting?: UserPageConfigurationParametersSortingOrder[];
	partition?: PartitionIndexResponse | undefined;
	onChange: (
		newUserQuery: GuardianUserQueryIndexResponse | undefined,
		mergeMode: boolean
	) => void;
	onSave: (newUserQuery: GuardianUserQueryIndexResponse) => void;
}

interface IVueSavedQuerySelectState {
	querySelectKey: string;
	queryLabel: string;
	isSavedQueryDropdownOpen: boolean;
	isQueryEditorOpen: boolean;
	queryType: string;
	isFavorite: boolean;
	queryDescription: string;
	overwriteQueryId: number;
}

export class VueSavedQuerySelect extends React.Component<
	IVueSavedQuerySelectProps,
	IVueSavedQuerySelectState
> {
	public state: IVueSavedQuerySelectState = {
		querySelectKey: generateUniqueReactKey(),
		queryLabel: '',
		isSavedQueryDropdownOpen: false,
		isQueryEditorOpen: false,
		queryType: 'user',
		isFavorite: false,
		queryDescription: '',
		overwriteQueryId: 0
	};

	public getCurrentQueryParameters() {
		const presets = this.props.preset
			? [this.props.preset.reportPreset]
			: undefined;

		const queryParameters: GuardianUserQueryParams = {
			timeRange: this.props.timeRange as any,
			dataSums: this.props.dataSums,
			filters: this.props.filters,
			dataColumns: this.props.additionalColumns?.map((column) => column.value),
			defaultSorted: this.props.defaultSorting,
			presets
		};

		return queryParameters;
	}

	public async saveCurrentQuery(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		const vueHttp = new RtVueHttp();
		const queryParameters = this.getCurrentQueryParameters();

		if (this.props.partition) {
			queryParameters.customerTestingOverride = this.props.partition.prefix;
		}

		const newQueryProfile = await vueHttp.createGuardianUserQueryProfile({
			reportId: this.props.reportId,
			queryParameters: queryParameters,
			isDefaultView: 0,
			isEmail: 0,
			isActive: 1,
			label: this.state.queryLabel,
			overwriteQueryId: this.state.overwriteQueryId,
			queryType: this.state.queryType,
			isFavorite: Number(this.state.isFavorite),
			queryDescription: this.state.queryDescription
		});

		this.toggleIsSavedQueryDropdownOpen(false);

		this.setState({ querySelectKey: generateUniqueReactKey() });

		this.props.onSave(newQueryProfile);
	}

	public toggleIsSavedQueryDropdownOpen(
		isSavedQueryDropdownOpen = !this.state.isSavedQueryDropdownOpen
	) {
		this.setState({ isSavedQueryDropdownOpen, queryLabel: '' });
	}

	public onEditorUpdate(newUserQuery: GuardianUserQueryIndexResponse) {
		this.setState({ querySelectKey: generateUniqueReactKey() });

		this.props.onSave(newUserQuery);
	}

	public onEditorDelete() {
		this.setState({ querySelectKey: generateUniqueReactKey() });

		this.props.onChange(undefined, false);
	}

	public buildQueryTypeOptions(): any {
		const isRtxAdmin = UserActions.has(Permissions.RtxAdministrator);
		const queryOptions = [
			{ value: 'shared', label: 'Shared' },
			{ value: 'user', label: 'User' }
		];
		if (isRtxAdmin) {
			queryOptions.push({ value: 'global', label: 'Global' });
		}
		return queryOptions as unknown as any;
	}

	public render() {
		const urlSearchParams = new RtVueReportUrlSearchParams(window.location);
		const userQueryIdent = urlSearchParams.getUserQueryIdent();

		return (
			<section className="d-flex justify-content-start align-items-center">
				<GuardianUserQuerySelect
					key={this.state.querySelectKey}
					containerClassName="flex-fill me-1"
					useControlGroup={false}
					useInitialOptionIdOnUpdate={false}
					resourceParams={{
						reportId: this.props.reportId
					}}
					initialQueryIdentifier={userQueryIdent ?? undefined}
					onChange={(userQuery, mergeMode = true) =>
						this.props.onChange(userQuery, mergeMode)
					}
					value={this.props.userQuery}
				/>
				{!this.props.userQuery && (
					<Dropdown
						align="end"
						show={this.state.isSavedQueryDropdownOpen}
						onToggle={() => this.toggleIsSavedQueryDropdownOpen()}
					>
						<Dropdown.Toggle bsPrefix="m-0" variant="button">
							<Tooltip title="Save Current Query" arrow placement="top">
								<Button size="sm">
									<i className="fas fa-fw fa-save" />
								</Button>
							</Tooltip>
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Form
								className="p-4"
								style={{ minWidth: 350 }}
								onSubmit={(evt) => this.saveCurrentQuery(evt)}
							>
								Overwrite Saved Query
								<GuardianUserQuerySelect
									key={this.state.querySelectKey}
									containerClassName="flex-fill me-1"
									useControlGroup={false}
									useInitialOptionIdOnUpdate={false}
									resourceParams={{
										reportId: this.props.reportId
									}}
									filterOption={(option) => option.data.isGlobal !== 1}
									initialQueryIdentifier={userQueryIdent ?? undefined}
									value={this.props.userQuery}
									onChange={(selectedItem) =>
										this.setState({
											overwriteQueryId: selectedItem?.guardianUserQueryId ?? 0
										})
									}
								/>
								<br />
								<Form.Group className="mb-3">
									<InputGroup style={{ marginBottom: '5px' }}>
										<InputFormControl
											label="Query Label"
											required
											useControlGroup={false}
											autoFocus
											placeholder="Query Label"
											onChange={(queryLabel) => this.setState({ queryLabel })}
											value={this.state.queryLabel}
										/>
									</InputGroup>
									<TextAreaFormControl
										label="Description"
										value={this.state.queryDescription}
										onChange={(queryDescription) =>
											this.setState({ queryDescription })
										}
									/>
								</Form.Group>
								<RadioButtons
									onChange={(queryType) => this.setState({ queryType })}
									value={this.state.queryType}
									options={this.buildQueryTypeOptions()}
								/>
								<CheckboxFormControl
									label="Favorite"
									styles={{
										marginTop: '5px',
										padding: '3px',
										marginLeft: '30px'
									}}
									value={this.state.isFavorite}
									onChange={(value) => this.setState({ isFavorite: value })}
								/>
								<Button style={{ float: 'right' }} type="submit">
									Save
								</Button>
							</Form>
						</Dropdown.Menu>
					</Dropdown>
				)}
				<Tooltip title="Reset Data" arrow placement="top">
					<Button
						size="sm"
						disabled={!this.props.userQuery}
						onClick={() => this.props.onChange(undefined, false)}
					>
						<i className="fas fa-fw fa-refresh" />
					</Button>
				</Tooltip>
			</section>
		);
	}
}
