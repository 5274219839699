/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum ScheduleType {
    CdrExport = 1,
    CdrConversion = 2,
    TaskCompletionReport = 3,
    RespOrgChangeReport = 4,
    TaskCompletionReportSingle = 5,
    RtVueReportRunner = 6,
    CsvExportReport = 7,
    RouteDepth = 8,
    RouteDepthWide = 9,
}