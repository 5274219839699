import {
	Star,
	StarBorder,
	Delete,
	OpenInNew,
	CalendarMonthOutlined
} from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useEffect } from 'react';
import { RtCommonApiRoutes, RtxApiRoutes } from 'RtExports/routes';
import {
	ProductId,
	ReportsIndexRequest,
	ReportsIndexResponse,
	ReportsProfileResponse,
	UserFavoriteCreateRequest,
	UserFavoriteIndexResponse
} from 'RtModels';
import { getReportsColumns } from 'RtUi/app/rtCommon/Reports/lib/entities';
import { useGetReports } from 'RtUi/app/rtCommon/Reports/lib/service';
import {
	ExistingReports,
	ReportBaseUrls
} from 'RtUi/app/rtVue/lib/constants/Constants';
import DataGrid from 'RtUi/components/data/DataGrid';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';

interface IReportsGridProps {
	resourceParams: ReportsIndexRequest;
	isChanged: boolean;
	setIsChanged: (isChanged: boolean) => void;
	hideHeader?: true;
	hideFooter?: true;
	clickSchedule?: (row: ReportsIndexResponse) => void;
}

export const ReportsGrid = ({
	resourceParams,
	isChanged,
	setIsChanged,
	hideHeader,
	hideFooter,
	clickSchedule
}: IReportsGridProps) => {
	const { data, isLoading, refetch } = useGetReports(resourceParams, true);

	const postfix = resourceParams.isFavorite ? 'favorites' : '';
	const enableTopToolbar = !hideHeader;
	const enableBottomToolbar = !hideFooter;

	const hasSchedule = (row: ReportsIndexResponse) => {
		return row.scheduleId !== null && row.scheduleId > 0;
	};

	const handleRedirectToReportPage = async (row: ReportsIndexResponse) => {
		const reportProfile =
			await HttpRequest.fetchWithRoute<ReportsProfileResponse>(
				RtCommonApiRoutes.Reports.Profile,
				{
					urlParams: {
						reportId: row.reportId,
						productId: row.productId as ProductId,
						source: row.source
					}
				}
			);

		if (!reportProfile) {
			alert('Report Profile not found');
		}

		localStorage.setItem('applyUserQueries', JSON.stringify(reportProfile));
		const baseUrl =
			ReportBaseUrls[reportProfile.reportId as ExistingReports].getIndexRoute();

		window.open(baseUrl, '_blank');
	};

	useEffect(() => {
		if (isChanged) {
			setIsChanged(false);
			refetch();
		}
	}, [isChanged, setIsChanged, refetch]);

	const deleteFile = async (row: ReportsIndexResponse) => {
		const confirm = await Confirmation.createDelete(`Report: '${row.label}'`);

		if (!confirm) {
			return;
		}
		await deleteReport(row);
		setIsChanged(true);
	};

	const toggleFavorite = async (row: ReportsIndexResponse) => {
		if (row.isFavorite) {
			await HttpRequest.fetchWithRoute<UserFavoriteIndexResponse>(
				RtxApiRoutes.MyProfileFavorites.Delete,
				{
					urlParams: {
						userFavoriteId: row.userFavoriteId
					}
				}
			);
		} else {
			if (row.userFavoriteTypeId) {
				const body: UserFavoriteCreateRequest = {
					userFavoriteTypeId: row.userFavoriteTypeId,
					favoriteId: `${row.reportId}`,
					isFavorite: 1
				};
				await HttpRequest.fetchWithRoute<UserFavoriteIndexResponse>(
					RtxApiRoutes.MyProfileFavorites.Create,
					{
						body: JSON.stringify(body)
					}
				);
			}
		}
		setIsChanged(true);
	};

	const deleteReport = async (row: ReportsIndexResponse) => {
		await HttpRequest.fetchWithRoute<ReportsIndexResponse>(
			RtCommonApiRoutes.Reports.Delete,
			{
				urlParams: {
					reportId: row.reportId,
					productId: row.productId as ProductId,
					source: row.source
				}
			}
		);
	};

	const columns = getReportsColumns();

	const inlineActions = (row: ReportsIndexResponse) => (
		<Box sx={{ display: 'flex', justifyContent: 'left' }}>
			<Tooltip title="Open Report in new window" arrow placement="right">
				<IconButton
					type="button"
					color="primary"
					size="small"
					onClick={(e) => {
						handleRedirectToReportPage(row);
						e.stopPropagation();
					}}
					sx={{
						padding: 0
					}}
				>
					<OpenInNew />
				</IconButton>
			</Tooltip>
			{Boolean(row.isFavorite) && (
				<Tooltip title="Remove Favorite" arrow placement="right">
					<IconButton
						type="button"
						color="success"
						size="small"
						disabled={!row.isFavorite}
						onClick={(e) => {
							toggleFavorite(row);
							e.stopPropagation();
							setIsChanged(true);
						}}
						sx={{
							padding: 0
						}}
					>
						<Star />
					</IconButton>
				</Tooltip>
			)}
			{Boolean(!row.isFavorite) && (
				<Tooltip title="Add Favorite" arrow placement="right">
					<IconButton
						type="button"
						color="success"
						size="small"
						disabled={row.isFavorite === 1}
						onClick={(e) => {
							toggleFavorite(row);
							e.stopPropagation();
							setIsChanged(true);
						}}
						sx={{
							padding: 0
						}}
					>
						<StarBorder />
					</IconButton>
				</Tooltip>
			)}
			{hasSchedule(row) && (
				<Tooltip title="Edit Report Schedule" arrow placement="right">
					<IconButton
						type="button"
						color="primary"
						size="small"
						onClick={(e) => {
							if (clickSchedule) {
								clickSchedule(row);
								e.stopPropagation();
								setIsChanged(true);
							}
						}}
						sx={{
							padding: 0,
							marginLeft: '5px'
						}}
					>
						<CalendarMonthOutlined />
					</IconButton>
				</Tooltip>
			)}
			{!hasSchedule(row) && (
				<Tooltip title="Add Report Schedule" arrow placement="right">
					<IconButton
						type="button"
						size="small"
						onClick={(e) => {
							if (clickSchedule) {
								clickSchedule(row);
								e.stopPropagation();
								setIsChanged(true);
							}
						}}
						sx={{
							padding: 0,
							marginLeft: '5px',
							color: 'lightgrey'
						}}
					>
						<CalendarMonthOutlined />
					</IconButton>
				</Tooltip>
			)}
			{Boolean(row.canDelete) && (
				<Tooltip title="Delete Saved Query" arrow placement="right">
					<IconButton
						type="button"
						color="error"
						size="small"
						onClick={(e) => {
							deleteFile(row);
							e.stopPropagation();
							setIsChanged(true);
						}}
						sx={{
							padding: 0,
							marginLeft: '5px'
						}}
					>
						<Delete />
					</IconButton>
				</Tooltip>
			)}
		</Box>
	);

	return (
		<DataGrid<ReportsIndexResponse>
			data={data?.data}
			columns={columns}
			loading={isLoading}
			inlineActions={inlineActions}
			positionActionsColumn="first"
			pageName={`rtCommon_reports_${postfix}`}
			enableTopToolbar={enableTopToolbar}
			enableBottomToolbar={enableBottomToolbar}
			// onClickRefetch={refetch}
		/>
	);
};
